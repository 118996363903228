<div [formGroup]="group" class="mb-2">
  <div *ngIf="label" class="d-flex align-items-baseline">
    <label class="input-label-content mb-2" *ngIf="label" [htmlFor]="'input-' + fieldName"
      >{{ label }} {{ optional && showOptionalHint ? ('common.optionalWithinParentheses_hint' | cxTranslate) : '' }}</label
    >
  </div>
  <div
    class="form-control p-0 d-flex align-items-center justify-content-between reactive-form-textarea-holder input-type-effects-textarea"
    [class.is-valid]="showSuccessState && !isInvalid() && group.controls[fieldName].dirty"
    [class.is-invalid]="isInvalid()"
  >
    <textarea
      [id]="'input-' + fieldName"
      class="border-0 w-100 h-100 p-3"
      [class.is-invalid]="isInvalid()"
      [formControlName]="fieldName"
      [placeholder]="placeholder"
      [maxLength]="maxLength"
      rows="4"
      [attr.aria-describedby]="description ? 'description-' + fieldName : null"
    ></textarea>
  </div>
  <small [id]="'description-' + fieldName" *ngIf="description" class="form-text text-muted">
    {{ description }}
  </small>
  <div *ngIf="!hideErrorMessages" [id]="'errors-' + fieldName" class="invalid-feedback">
    {{ errors() | inputError }}
  </div>
  <span *ngIf="fieldValueLength" class="max-length"
    >{{ 'common.maxCharsCount_hint' | cxTranslate }} :
    <span [ngClass]="{ 'text-danger': fieldValueLength >= maxLength }">{{ fieldValueLength }}</span> ({{ maxLength }})</span
  >
</div>
