/* Generated by `yarn generate:occ-models` */
/* DO NOT EDIT MANUALLY. CHANGES WILL BE OVERWRITTEN. */

export interface A4SampleColor {
  code?: string;
  description?: string;
}

export interface A4SampleColorList {
  colors?: A4SampleColor[];
}

/** The accounting code, consisting of its code and description */
export interface AccountingCode {
  code?: string;
  description?: string;
  reinvoicing?: boolean;
}

/** Mapping list of cart entry number and accounting code */
export interface AccountingCodeEntryMappingList {
  mappings?: AccountingCodeEntryMapping[];
}

export interface AccountingCodeEntryMapping {
  accountingCode?: string;
  /** @format int32 */
  entryNumber?: number;
}

export interface AccountingCodeList {
  accountingCodes?: AccountingCode[];
}

export interface AccountingCodePage {
  /** Result list */
  results?: AccountingCode[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

export interface AccountingCodeUserInfo {
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  articlesLastChanged?: string;
  /** @format int32 */
  nrOfArticleLinks?: number;
}

/** Address object */
export interface Address {
  /** Unique id value of the address which is optional while creating new address. While performing other address operations this value is the key */
  id?: string;
  /**
   * Title of the address person
   * @example "Mr."
   */
  title?: string;
  /**
   * Code of the title
   * @example "MR"
   */
  titleCode: string;
  /**
   * First name of the address person
   * @example "John"
   */
  firstName: string;
  /**
   * Last name of the address person
   * @example "Doe"
   */
  lastName: string;
  /**
   * Company Name
   * @example "Global Corp."
   */
  companyName?: string;
  /**
   * First line of the address
   * @example "123 Main Street"
   */
  line1: string;
  /**
   * Second line of the address
   * @example "Garden Level"
   */
  line2?: string;
  /**
   * Town, field required
   * @example "New York City"
   */
  town: string;
  /** Response body fields which will be returned while fetching the list of country's regions. */
  region?: Region;
  /**
   * District name
   * @example "8th"
   */
  district?: string;
  /**
   * Postal code of the address
   * @example "10001"
   */
  postalCode: string;
  /**
   * Phone number
   * @example "+1 (123) 456-7890"
   */
  phone?: string;
  /**
   * Cellphone number
   * @example "+1 (123) 456-7890"
   */
  cellphone?: string;
  /**
   * Email address
   * @example "johndoe@domain.com"
   */
  email?: string;
  /** Response body fields which will be returned while fetching the list of countries. The DTO is in XML or .json format */
  country?: Country;
  /** Boolean flag if address is for shipping */
  shippingAddress?: boolean;
  /** Boolean flag if address is default */
  defaultAddress?: boolean;
  /** Boolean flag if address is visible in the Address Book */
  visibleInAddressBook?: boolean;
  /** Boolean flag if address is formatted */
  formattedAddress?: string;
}

/** Representation of an Address list */
export interface AddressList {
  /** List of addresses */
  addresses?: Address[];
}

/** Representation of an Address Validation */
export interface AddressValidation {
  /** List of errors */
  errors?: ErrorList;
  /** Decision */
  decision?: string;
  /** List of suggested addresses */
  suggestedAddresses?: Address[];
}

export interface AltHrefLang {
  url?: string;
  hreflang?: string;
}

export interface AlternativeArticles {
  alternativeTo?: string;
  articles?: ArticleRef[];
  attributes?: ArticleListAttribute;
  /** @format int32 */
  alternativesCount?: number;
}

export interface AlternativeProductRef {
  productRef?: string;
  replacement?: boolean;
  alternative?: boolean;
  recommendation?: boolean;
}

export interface AlternativeProducts {
  alternativeTo?: string;
  products?: AlternativeProductRef[];
  /** @format int32 */
  alternativesCount?: number;
}

/** Enhanced Ecommerce representation of a commerceItem. @see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce This representation is according to Google's EE data model. It is not complete - add more fields as required. */
export interface AnalyticsArticle {
  /** This is the *product* id */
  id?: string;
  article?: string;
  /** @format int64 */
  quantity?: number;
  list?: string;
  /**
   * Using the name 'myPrice' rather than 'price', but still allows us to still prepare for populating it everywhere.
   * @format double
   */
  myPrice?: number;
}

export interface AnalyticsMetadata {
  trackers?: AnalyticsTracker[];
  googleTagManagers?: GoogleTagManager[];
  umamiConfigurations?: UmamiConfiguration[];
  apptusConfigurations?: ApptusFrontendConfiguration[];
  siteName?: string;
  userCustomerId?: string;
}

export interface AnalyticsTracker {
  trackerId?: string;
  trackerName?: string;
  type?: string;
  cookieDomain?: string;
  trackPageviewsOnly?: boolean;
}

export interface ApptusFrontendConfiguration {
  code?: string;
  market?: string;
  clusterId?: string;
  customerKey?: string;
}

export interface AreaOfUse {
  /** Code of the category */
  code?: string;
  /** Name of the category */
  name?: string;
  /** URL of the category */
  url?: string;
  /** Representation of an Image */
  image?: Image;
  /** Representation of an Image */
  banner?: Image;
  description?: string;
  /** @format int32 */
  level?: number;
  subCategories?: Category[];
  subCategoryCodes?: string[];
  superCategoryCode?: string;
  headline?: string;
  longDescription?: string;
  seo?: SeoMeta;
  notPriceFileEnabled?: boolean;
  readyToPublish?: boolean;
  /** @format int32 */
  productsCount?: number;
  /** @format int32 */
  unavailableProductsCount?: number;
  productCodes?: string[];
  /** @uniqueItems true */
  excludedArticleCodes?: string[];
  /** @format int32 */
  position?: number;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  modifiedtime?: string;
}

export interface AreaOfUseList {
  areasOfUse?: AreaOfUse[];
}

export interface Article {
  /** 'code' is distinct from 'articleNumber' where code is a unique identifier of a particular article, while there can be more than one articleNumber for any given code (e.g. customer-specific article numbers, volvo etc), we have also customer material numbers related to pre-printed label configurations stored on articlePrePrintedConfiguration property */
  code?: string;
  url?: string;
  productCode?: string;
  categoryCode?: string;
  articleNumber?: string;
  oldArticleNumber?: string;
  articlePrePrintedConfiguration?: string[];
  articlePrePrintedConfigurationWithCountry?: string[];
  displayName?: string;
  articleNameDisplayMode?: string;
  brandAndIdentifier?: string;
  eshopArticleText?: string;
  specialMessage?: SpecialMessage;
  articleGroup?: string;
  articleType?: string;
  productType?: string;
  /** @format int32 */
  deliveryQuantity?: number;
  deliveryUnit?: string;
  /** Representation of an Image */
  datasheet?: Image;
  safetyDatasheetUrl?: string;
  /** Representation of an Image */
  articleGroupImage?: Image;
  /** Representation of an Image */
  articleGroupThumbnail?: Image;
  /** Representation of an Image */
  image?: Image;
  /** Representation of an Image */
  thumbnail?: Image;
  procuratorEcoData?: ProcuratorEnvironmentalChoice;
  classificationAttributes?: Record<string, ClassificationAttribute>;
  attributes?: Attribute[];
  inlineAttributes?: PresentationAttribute[];
  hideEcommerceUnit?: boolean;
  unit?: Unit;
  units?: Unit[];
  /** @format int32 */
  minimumOrderQuantity?: number;
  cuttable?: boolean;
  reamable?: boolean;
  prePrintable?: boolean;
  breakable?: boolean;
  showBreakableInformation?: boolean;
  tendered?: boolean;
  contracted?: boolean;
  nonReturnable?: boolean;
  packaging?: ArticlePackagingEnum;
  articleStatus?: string;
  itemCategoryGroup?: string;
  currentPrice?: number;
  currentPriceData?: PriceResponse;
  availability?: string;
  stockStatus?: string;
  outgoing?: boolean;
  outgoingOnSale?: boolean;
  displayNewArticleOverlay?: boolean;
  intermerchanting?: boolean;
  eligibleForPalletFlags?: boolean;
  localDeletion?: boolean;
  productName?: string;
  alternativesPlaceholder?: boolean;
  invisible?: boolean;
  originalSampleAvailable?: boolean;
  freeStock?: boolean;
  invoicedStock?: boolean;
  consignmentStock?: boolean;
  unspsc?: string;
  eclass?: string;
  displayBrandAndIdentifier?: boolean;
  eshopTooltip?: string;
  displayEshopTooltip?: boolean;
  displayOldArticleNumber?: boolean;
  envelopeData?: ArticleEnvelope;
  vendorMaterialNumber?: string;
  hideLinkToProductPage?: boolean;
  brandRef?: string;
  priceCampaign?: boolean;
  alternatives?: ProductRelation[];
  replaces?: ProductRelation[];
  replacedBy?: ProductRelation[];
  productReference?: PyProductReference;
  kitArticle?: KitMasterArticle;
  articleLevelCertificates?: ProductCertificateGroup;
  extraInfo?: ArticleExtraInfo;
  /** @uniqueItems true */
  accountingCodes?: AccountingCode[];
  ean?: string[];
  relatedProducts?: ProductRelation[];
  areasOfUse?: string[];
  environmental_junk?: ArticleEnvironment;
  junk?: ArticleJunk;
  searchScoreData?: SearchScoreExplanation;
  articleExtendedDelivery?: boolean;
  salesBlocked?: boolean;
}

export interface ArticleEnvelope {
  windowInfoCode?: string;
  windowInfoDescription?: string;
  windowPositionCode?: string;
  windowPositionDescription?: string;
  /** @format double */
  measurementA?: number;
  measurementAUnit?: string;
  /** @format double */
  measurementB?: number;
  measurementBUnit?: string;
  /** @format double */
  measurementC?: number;
  measurementCUnit?: string;
  /** @format double */
  measurementD?: number;
  measurementDUnit?: string;
  imageUrl?: string;
  envelopeWidth?: string;
  envelopeLength?: string;
}

export interface ArticleEnvironment {
  below100PercentRecycling?: boolean;
  /** @format int32 */
  below100PercentRecyclingPercentage?: number;
  blueAngel?: boolean;
  carbonNeutral?: boolean;
  din6738?: boolean;
  emas?: boolean;
  euEcoLabel?: boolean;
  fscCertified?: boolean;
  fscCode?: string;
  fscPefc?: boolean;
  fscPefcNoPrint?: boolean;
  goodEnvironmentalChoice?: boolean;
  hundredPercentRecycling?: boolean;
  iso14001?: boolean;
  iso9706?: boolean;
  nen2728?: boolean;
  nordicSwan?: boolean;
  pefcCertified?: boolean;
  pcf?: boolean;
  swedishAsthmaAndAllergyAssociation?: boolean;
  tcf?: boolean;
}

export interface ArticleExtraInfo {
  productCertifications?: ProductCertification;
  washingInstructions?: ProductCertification;
  printingTechnologies?: ProductCertification;
  materialComposition?: ProductCertification;
}

export interface ArticleJunk {
  distributionChannel?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  localArticleStatusValidFrom?: string;
  itemCategoryGroupOverride?: string;
  deliveringWarehouse?: Warehouse;
  cashDiscount?: boolean;
  priceLevel1?: string;
  priceLevel2?: string;
  priceLevel3?: string;
  brokenPackageSurchargeQuantity?: number;
  brokenPackageSurchargeUnit?: string;
  internalInformation?: string;
  productManager?: string;
  consumerSymbol?: boolean;
  roleSymbol?: boolean;
  sheetSymbol?: boolean;
  asLongAsOnStock?: boolean;
  partialQuantitySymbol?: boolean;
  fourSidedCut?: boolean;
  chemicalAssortment?: boolean;
  noParcelServiceDelivery?: boolean;
  medicalAssortment?: boolean;
  optimizedPostage?: boolean;
  packageDispatchOnly?: boolean;
}

export interface ArticleLine {
  articleNumber?: string;
  displayNumber?: string;
  /** @format int64 */
  quantity?: number;
  unitCode?: string;
  lineNote?: string;
  statisticsCode?: string;
}

export interface ArticleList {
  articles?: Article[];
}

export interface ArticleListAttribute {
  allAttributes?: Attribute[];
  filterAttributes?: Attribute[];
}

export interface ArticlePriceStock {
  weight?: number;
  weightUnit?: Unit;
  weightAmount?: number;
  weightAmountUnit?: Unit;
  comparativePrices?: ComparativePriceEntry[];
  warehouses?: WarehouseArticle[];
}

export interface ArticleRef {
  code?: string;
  /** 'code' is distinct from 'articleNumber' where code is a unique identifier of a particular article, while there can be more than one articleNumber for any given code (e.g. customer-specific article numbers, volvo etc) */
  articleNumber?: string;
  unit?: Unit;
  /**
   * Available accounting codes for this article
   * @uniqueItems true
   */
  accountingCodes?: AccountingCode[];
}

export interface ArticleRefList {
  articleRefs?: ArticleRef[];
}

export interface ArticleRefs {
  articleRefs?: string[];
}

export interface ArticleStockInfo {
  articleRef?: string;
  unitRef?: string;
  /** @format int32 */
  quantity?: number;
  availability?: string;
  stockInfos?: StockInfo[];
}

export interface ArticleStockInfoList {
  values?: ArticleStockInfo[];
}

export interface ArticlesSearchPage {
  /** Result list */
  results?: Article[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

export interface AssignedShipTo {
  soldTo?: SoldTo;
  /** Custom ship-to */
  shipTo?: ShipTo;
}

export interface AssignedShipToPage {
  /** Result list */
  results?: AssignedShipTo[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

export interface Assortment {
  hasAssortment?: boolean;
  grammages?: string[];
  dimensions?: string[];
  values?: Record<string, string[]>;
}

export interface AtgScheduleItem {
  alternativeMaterial?: string;
  alternativeMaterial2?: string;
  alternativeMaterial3?: string;
  alternativeMaterial4?: string;
  alternativePlant?: string;
  alternativePlant2?: string;
  alternativePlant3?: string;
  alternativePlant4?: string;
  alternativeQuantity?: number;
  alternativeQuantity2?: number;
  alternativeQuantity3?: number;
  alternativeQuantity4?: number;
  alternativeUnit?: string;
  alternativeUnit2?: string;
  alternativeUnit3?: string;
  alternativeUnit4?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  dateOfDelivery?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  dateOfFullDelivery?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  dateOfDeliveryAdjustedForGoodsReceivingDays?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  dateOfFullDeliveryAdjustedForGoodsReceivingDays?: string;
  errorText?: string;
  itemNumber?: number;
  scheduleLine?: number;
  scheduleLineType?: string;
  material?: string;
  confirmedQuantity?: number;
  unit?: string;
}

export interface Attribute {
  name?: string;
  code?: string;
  filterAttribute?: boolean;
  columnAttribute?: boolean;
  alwaysShowFilter?: boolean;
}

export interface AttributeRow {
  attribute?: TechnicalAttribute;
  values?: string[];
  /** @format int32 */
  sort?: number;
}

export interface AttributeTable {
  productCode?: string;
  header?: AttributeRow;
  attributes?: AttributeRow[];
  showTabLink?: boolean;
}

/** Auto resolve change data */
export interface AutoResolveChange {
  originalSchedule?: AtgScheduleItem;
  changeType?: AutoResolveChangeChangeTypeEnum;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  fullDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  confirmedDate?: string;
  confirmedQuantity?: number;
  confirmedUnit?: string;
  requestedQuantity?: number;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  sapEarliestDate?: string;
}

export interface B2BPaymentTypeData {
  code?: string;
  displayName?: string;
}

/** Representation of order list item for a permission */
export interface B2BPermissionOrderListItem {
  /** SAP order number */
  orderNumber?: string;
  /** Hybris order code */
  webReference?: string;
  /** Representation of a Price */
  applicableAmountFromOrder?: Price;
  /** Representation of a Price */
  totalPrice?: Price;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  orderDate?: string;
  /** Simple customer data representation with basic information */
  customer?: SimpleCustomer;
  /** Status of Order */
  status?: string;
  /** Status display */
  statusDisplay?: string;
}

export interface B2BPermissionOrdersPage {
  /** Result list */
  results?: B2BPermissionOrderListItem[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

/** Representation of permission current usage */
export interface B2BPermissionUsage {
  /** Order Rule object. */
  permission?: Permission;
  /**
   * Amount used for all orders in current period
   * @example 100
   */
  amountUsed?: number;
  /**
   * Evaluation period start date
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  evaluationDateFrom?: string;
  /**
   * Evaluation period end date
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  evaluationDateTo?: string;
  evaluationSoldTo?: SoldTo;
  /** Evaluation shipTos - sub set of current soldTo's shipTos, available if permission depends on shipTos */
  evaluationShipTos?: ShipTo[];
}

/** Representation of an permission page */
export interface B2BPermissionsPage {
  /** Result list */
  results?: Permission[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

/** Representation of object selection data */
export interface B2BSelectionData {
  /**
   * The ID of the selected object
   * @example "Retail_2K"
   */
  id?: string;
  /**
   * If the this object was selected
   * @example true
   */
  selected?: boolean;
  /**
   * If this object is active
   * @example false
   */
  active?: boolean;
  /** approval roles */
  approvalRoles?: string[];
  /** display roles */
  displayRoles?: string[];
  /** The normalized code */
  normalizedCode?: string;
}

/** Organizational Unit object. */
export interface B2BUnit {
  /**
   * Boolean flag of whether Organizational Unit is active
   * @example true
   */
  active?: boolean;
  /**
   * Identifier of the organizational unit
   * @example "Pronto"
   */
  uid: string;
  /**
   * Name of the organizational unit
   * @example "Pronto"
   */
  name?: string;
  /** Description of the organizational unit */
  description?: string;
  /** Organizational Unit object. */
  parent?: B2BUnit;
  /** Approvers of the organizational unit node */
  approvers?: Customer[];
  /** Managers of the organizational unit node */
  managers?: Customer[];
  /** Administrators of the organizational unit node */
  administrators?: Customer[];
  /** Customers of the organizational unit node */
  customers?: Customer[];
  /** List of approver groups */
  approverGroups?: B2BUserGroup[];
  /** List of b2b user groups */
  userGroups?: B2BUserGroup[];
}

/** Representation of an organizational unit node */
export interface B2BUnitNode {
  /**
   * Identifier of the organizational unit represented by the node
   * @example "Pronto_Retail"
   */
  id: string;
  /**
   * Name of the organizational unit represented by the node
   * @example "Pronto Retail"
   */
  name?: string;
  /**
   * Unique identifier of organizational unit node's parent unit
   * @example "Pronto"
   */
  parent?: string;
  /**
   * Boolean flag of whether organizational unit represented by the node is active
   * @example true
   */
  active?: boolean;
  /** Child nodes of the organizational unit node */
  children?: B2BUnitNode[];
}

/** Representation of an organizational unit node list */
export interface B2BUnitNodeList {
  /** List of Unit Nodes */
  unitNodes: B2BUnitNode[];
}

/** Organizational unit user group object. */
export interface B2BUserGroup {
  /** Unique user identifier */
  uid?: string;
  /** Name of the user */
  name?: string;
  /** List of members */
  members?: Principal[];
  /** List of subgroups */
  subGroups?: UserGroup[];
  /**
   * Number of members
   * @format int32
   */
  membersCount?: number;
  /** Representation of a Principal webservice DTO used for defining User data types */
  unit?: Principal;
  /** Permissions of the user group */
  permissions?: Permission[];
  /** List of approval roles */
  approvalRoles?: string[];
}

/** Representation of a user group page used in organizational units */
export interface B2BUserGroupPage {
  /** Result list */
  results?: B2BUserGroup[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

/** Representation of badge entry - used for frontend notifications */
export interface Badge {
  /** Badge Type */
  type?: BadgeTypeEnum;
  /**
   * Count of events related to the current badge type
   * @format int32
   */
  count?: number;
}

/** Representation of a Base Option */
export interface BaseOption {
  /** Variant type of base option */
  variantType?: string;
  /** List of all variant options */
  options?: VariantOption[];
  /** Representation of a Variant Option */
  selected?: VariantOption;
}

/** Representation of a Base Site */
export interface BaseSite {
  /** Unique identifier of Basesite */
  uid?: string;
  /** Name of Basesite */
  name?: string;
  /** List of Basestores */
  stores?: BaseStore[];
  /** Theme of Basesite */
  theme?: string;
  /** Representation of a Language */
  defaultLanguage?: Language;
  /** Locale data for Basesite */
  locale?: string;
  /** Channel */
  channel?: string;
  /** List of url encoding attributes */
  urlEncodingAttributes?: string[];
  /** List of url patterns */
  urlPatterns?: string[];
  /** Default preview catalog id */
  defaultPreviewCatalogId?: string;
  /** Default preview category code */
  defaultPreviewCategoryCode?: string;
  /** Default preview product code */
  defaultPreviewProductCode?: string;
  /** Indicates whether customer data isolation is enabled for this site. If true, customer can get site information after registration, For example registerd username is name@sap.com, returned uid will be name@sap.com|baseSiteUid */
  isolated?: boolean;
  /** Configuration information of captcha */
  captchaConfig?: CaptchaConfig;
  /** Site prefix */
  sitePrefix?: string;
  /** Base URL */
  baseUrl?: string;
  /** CDN base URL */
  cdnBaseUrl?: string;
  dateFormat?: string;
  dateTimeFormat?: string;
}

/** Representation of a Base Site List */
export interface BaseSiteList {
  /** List of basesites */
  baseSites?: BaseSite[];
}

/** Representation of a Base Store */
export interface BaseStore {
  /** Base store name */
  name?: string;
  /** Flag defining is external tax is enabled */
  externalTaxEnabled?: boolean;
  /** Payment provider */
  paymentProvider?: string;
  /** Create return process code */
  createReturnProcessCode?: string;
  /**
   * Maximum radius for searching point of service
   * @format double
   */
  maxRadiusForPosSearch?: number;
  /** Submit order process code */
  submitOrderProcessCode?: string;
  /** List of currencies */
  currencies?: Currency[];
  /** Representation of a Currency */
  defaultCurrency?: Currency;
  /** Representation of a Point of service */
  defaultDeliveryOrigin?: PointOfService;
  /** Representation of a Language */
  defaultLanguage?: Language;
  /** List of delivery countries */
  deliveryCountries?: Country[];
  /** Representation of a Delivery mode list */
  deliveryModes?: DeliveryModeList;
  /** List of languages */
  languages?: Language[];
  /** List of points of service */
  pointsOfService?: PointOfService[];
  /** Flag specifying whether the express checkout option is enabled */
  expressCheckoutEnabled?: boolean;
  countryCode?: string;
}

/** Representation of a Breadcrumb */
export interface Breadcrumb {
  /** Code of the facet */
  facetCode?: string;
  /** Name of the facet */
  facetName?: string;
  /** Value code of the facet */
  facetValueCode?: string;
  /** Value name of the facet */
  facetValueName?: string;
  /** Representation of a Search State */
  removeQuery?: SearchState;
  /** Representation of a Search State */
  truncateQuery?: SearchState;
}

export interface BuyerData {
  /** Representation of a Principal webservice DTO used for defining User data types */
  buyer?: Principal;
  permissions?: Permission[];
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  originalOrderDate?: string;
}

export interface CMSFormField {
  uid?: string;
  name?: string;
  label?: string;
  type?: CmsFormFieldTypeEnum;
  /** @format int64 */
  number?: number;
  optional?: boolean;
  value?: string;
  subject?: boolean;
  form?: string;
}

export interface CMSFormFieldList {
  fields?: CMSFormField[];
}

export interface CMSLinkComponents {
  linkName?: string;
  url?: string;
  external?: boolean;
  /** If this links to an internal content page, this will be populated */
  contentPageLabel?: string;
}

export interface CMSLinkComponentsForProduct {
  productRef?: PyProductRef;
  linkComponents?: CMSLinkComponentsList;
}

export interface CMSLinkComponentsForProductList {
  list?: CMSLinkComponentsForProduct[];
}

export interface CMSLinkComponentsList {
  cmsLinkComponents?: CMSLinkComponents[];
}

export interface CMSPageList {
  page?: CMSPage[];
}

export interface CMSPage {
  uid?: string;
  uuid?: string;
  title?: string;
  template?: string;
  typeCode?: string;
  name?: string;
  description?: string;
  robotTag?: string;
  defaultPage?: boolean;
  contentSlots?: ContentSlotList;
  catalogVersionUuid?: string;
  otherProperties?: Record<string, object>;
}

/** Representation of a cancellation request entry input for an order */
export interface CancellationRequestEntryInput {
  /**
   * Order entry number of the cancelled product
   * @format int32
   * @example 1
   */
  orderEntryNumber: number;
  /**
   * Quantity of the product which belongs to the order entry and is requested to be cancelled
   * @format int64
   * @example 5
   */
  quantity: number;
}

/** Cancellation request input list for the current order. */
export interface CancellationRequestEntryInputList {
  /** Cancellation request entry inputs which contain information about the order entries which are requested to be cancelled */
  cancellationRequestEntryInputs: CancellationRequestEntryInput[];
}

/** Configuration information of captcha */
export interface CaptchaConfig {
  /**
   * Indicates if the captcha is enabled or not
   * @example true
   */
  enabled?: boolean;
  /**
   * The public key used in captcha validation
   * @example "6LdeF6tgAAAAAE_T55TB0nmg--qmbnkwqC4LPQbg"
   */
  publicKey?: string;
}

/** Representation of a Card Type */
export interface CardType {
  /** Card type code */
  code?: string;
  /** Card type name */
  name?: string;
}

/** Representation of a Card Type List */
export interface CardTypeList {
  /** List of card types */
  cardTypes?: CardType[];
}

/** Representation of a Cart */
export interface Cart {
  /** Code number of order */
  code?: string;
  /** Flag stating iv value is net-value */
  net?: boolean;
  /** Representation of a Price */
  totalPriceWithTax?: Price;
  /** Representation of a Price */
  totalPrice?: Price;
  /** Representation of a Price */
  totalTax?: Price;
  /** Representation of a Price */
  subTotal?: Price;
  /** Representation of a Price */
  deliveryCost?: Price;
  /** List of order entries */
  entries?: OrderEntry[];
  /** List of entry groups */
  entryGroups?: EntryGroup[];
  /** @format int32 */
  totalItems?: number;
  /** Representation of a Delivery mode */
  deliveryMode?: DeliveryMode;
  /** Address object */
  deliveryAddress?: Address;
  /** Payment details object */
  paymentInfo?: PaymentDetails;
  /** List of applied order promotions */
  appliedOrderPromotions?: PromotionResult[];
  /** List of applied product promotions */
  appliedProductPromotions?: PromotionResult[];
  /** Representation of a Price */
  productDiscounts?: Price;
  /** Representation of a Price */
  orderDiscounts?: Price;
  /** Representation of a Price */
  totalDiscounts?: Price;
  /** Site */
  site?: string;
  /** Store */
  store?: string;
  /** Guest user id identifier */
  guid?: string;
  /** Flag showing if order is calculated */
  calculated?: boolean;
  /** List of applied vouchers */
  appliedVouchers?: Voucher[];
  /** Representation of a Principal webservice DTO used for defining User data types */
  user?: Principal;
  /** List of pickup order entry group */
  pickupOrderGroups?: PickupOrderEntryGroup[];
  /** List of delivery order entries group */
  deliveryOrderGroups?: DeliveryOrderEntryGroup[];
  /**
   * Quantity of pickup items
   * @format int64
   */
  pickupItemsQuantity?: number;
  /**
   * Quantity of delivery items
   * @format int64
   */
  deliveryItemsQuantity?: number;
  /** Customer requested date for order retrieval */
  requestedRetrievalAt?: string;
  conditions?: SapOrderCondition[];
  pyOrderParameters?: PyOrderParameters;
  /** @format double */
  weight?: number;
  weightUnit?: string;
  /** @format double */
  rowSum?: number;
  /** @format double */
  feeSum?: number;
  /** @format double */
  rowSumIncludingFees?: number;
  /** Representation of a Price */
  orderEntryFeeSum?: Price;
  /** Representation of a Price */
  orderEntrySum?: Price;
  /** Representation of a Price */
  orderEntrySumIncludingFees?: Price;
  orderType?: CartOrderTypeEnum;
  messages?: Message[];
  permissionResults?: PermissionResult[];
  /**
   * Total unit count
   * @format int32
   */
  totalUnitCount?: number;
  /** List of potential order promotions for cart */
  potentialOrderPromotions?: PromotionResult[];
  /** List of potential product promotions for cart */
  potentialProductPromotions?: PromotionResult[];
  /** Name of the cart */
  name?: string;
  /** Description of the cart */
  description?: string;
  /**
   * Date of cart expiration time
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  expirationTime?: string;
  /**
   * Date of saving cart
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  saveTime?: string;
  /** Representation of a Principal webservice DTO used for defining User data types */
  savedBy?: Principal;
  /** Earliest possible retrieval date available for order */
  earliestRetrievalAt?: string;
  status?: string;
  cartType?: string;
  removedItems?: ArticleRef[];
  invisibleItems?: ArticleRef[];
  paymentType?: B2BPaymentTypeData;
  purchaseOrderNumber?: string;
  cartOwnerDetails?: CartOwnerDetails;
  hasDiscontinued?: boolean;
  approvalOrderRef?: string;
}

/** Representation of a Cart list */
export interface CartList {
  /** List of carts */
  carts?: Cart[];
}

/** Representation of a Cart modification */
export interface CartModification {
  /** Status code of cart modification */
  statusCode?: string;
  /**
   * Quantity added with cart modification
   * @format int64
   */
  quantityAdded?: number;
  /**
   * Final quantity after cart modification
   * @format int64
   */
  quantity?: number;
  /**
   * Request body parameter that contains details such as the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name)
   *
   * The DTO is in XML or .json format.
   */
  entry?: OrderEntry;
  /** Delivery mode changed */
  deliveryModeChanged?: boolean;
  /** Status message */
  statusMessage?: string;
  cartCode?: string;
  fullPalletUpgrade?: FullPalletUpgrade;
}

/** Representation of a Cart modification list */
export interface CartModificationList {
  /** List of cart modifications */
  cartModifications?: CartModification[];
}

export interface CartOwnerDetails {
  name?: string;
  email?: string;
  phone?: string;
}

export interface CartTemplate {
  /** Code number of order */
  code?: string;
  /** Flag stating iv value is net-value */
  net?: boolean;
  /** Representation of a Price */
  totalPriceWithTax?: Price;
  /** Representation of a Price */
  totalPrice?: Price;
  /** Representation of a Price */
  totalTax?: Price;
  /** Representation of a Price */
  subTotal?: Price;
  /** Representation of a Price */
  deliveryCost?: Price;
  /** List of order entries */
  entries?: OrderEntry[];
  /** List of entry groups */
  entryGroups?: EntryGroup[];
  /** @format int32 */
  totalItems?: number;
  /** Representation of a Delivery mode */
  deliveryMode?: DeliveryMode;
  /** Address object */
  deliveryAddress?: Address;
  /** Payment details object */
  paymentInfo?: PaymentDetails;
  /** List of applied order promotions */
  appliedOrderPromotions?: PromotionResult[];
  /** List of applied product promotions */
  appliedProductPromotions?: PromotionResult[];
  /** Representation of a Price */
  productDiscounts?: Price;
  /** Representation of a Price */
  orderDiscounts?: Price;
  /** Representation of a Price */
  totalDiscounts?: Price;
  /** Site */
  site?: string;
  /** Store */
  store?: string;
  /** Guest user id identifier */
  guid?: string;
  /** Flag showing if order is calculated */
  calculated?: boolean;
  /** List of applied vouchers */
  appliedVouchers?: Voucher[];
  /** Representation of a Principal webservice DTO used for defining User data types */
  user?: Principal;
  /** List of pickup order entry group */
  pickupOrderGroups?: PickupOrderEntryGroup[];
  /** List of delivery order entries group */
  deliveryOrderGroups?: DeliveryOrderEntryGroup[];
  /**
   * Quantity of pickup items
   * @format int64
   */
  pickupItemsQuantity?: number;
  /**
   * Quantity of delivery items
   * @format int64
   */
  deliveryItemsQuantity?: number;
  /** Customer requested date for order retrieval */
  requestedRetrievalAt?: string;
  conditions?: SapOrderCondition[];
  pyOrderParameters?: PyOrderParameters;
  /** @format double */
  weight?: number;
  weightUnit?: string;
  /** @format double */
  rowSum?: number;
  /** @format double */
  feeSum?: number;
  /** @format double */
  rowSumIncludingFees?: number;
  /** Representation of a Price */
  orderEntryFeeSum?: Price;
  /** Representation of a Price */
  orderEntrySum?: Price;
  /** Representation of a Price */
  orderEntrySumIncludingFees?: Price;
  orderType?: CartTemplateOrderTypeEnum;
  messages?: Message[];
  permissionResults?: PermissionResult[];
  /**
   * Total unit count
   * @format int32
   */
  totalUnitCount?: number;
  /** List of potential order promotions for cart */
  potentialOrderPromotions?: PromotionResult[];
  /** List of potential product promotions for cart */
  potentialProductPromotions?: PromotionResult[];
  /** Name of the cart */
  name?: string;
  /** Description of the cart */
  description?: string;
  /**
   * Date of cart expiration time
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  expirationTime?: string;
  /**
   * Date of saving cart
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  saveTime?: string;
  /** Representation of a Principal webservice DTO used for defining User data types */
  savedBy?: Principal;
  /** Earliest possible retrieval date available for order */
  earliestRetrievalAt?: string;
  status?: string;
  cartType?: string;
  removedItems?: ArticleRef[];
  invisibleItems?: ArticleRef[];
  paymentType?: B2BPaymentTypeData;
  purchaseOrderNumber?: string;
  cartOwnerDetails?: CartOwnerDetails;
  hasDiscontinued?: boolean;
  approvalOrderRef?: string;
  reader?: boolean;
  writer?: boolean;
  owner?: boolean;
  readers?: Principal[];
  writers?: Principal[];
  accessType?: CartTemplateAccessTypeEnum;
  /** Simple customer data representation with basic information */
  contactPerson?: SimpleCustomer;
  /** @format int32 */
  numberOfConnectedPrincipals?: number;
}

/** Result list */
export interface CartTemplateSummary {
  name?: string;
  code?: string;
  description?: string;
  /** @format int32 */
  numberOfArticles?: number;
  images?: Image[];
  reader?: boolean;
  writer?: boolean;
  owner?: boolean;
  accessType?: CartTemplateSummaryAccessTypeEnum;
  /** Simple customer data representation with basic information */
  contactPerson?: SimpleCustomer;
  /** @format int32 */
  numberOfConnectedPrincipals?: number;
  /** @format int32 */
  numberOfUnavailableArticles?: number;
}

export interface CartTemplateSummaryPage {
  /** Result list */
  results?: CartTemplateSummary[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

/** Representation of a Catalog */
export interface Catalog {
  /** Identifier of abstract catalog item */
  id?: string;
  /**
   * Date of last modification
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  lastModified?: string;
  /** Name of abstract catalog item */
  name?: string;
  /** Url address of abstract catalog item */
  url?: string;
  /** List of versions of catalog */
  catalogVersions?: CatalogVersion[];
}

export interface CatalogAddonGroup {
  id?: string;
  title?: string;
  description?: string;
  superGroupId?: string;
  /** Representation of an Image */
  image?: Image;
  /** Representation of an Image */
  thumbnail?: Image;
  nodes?: CatalogAddonNode[];
  children?: CatalogAddonGroup[];
  articleRefs?: string[];
}

export interface CatalogAddonNode {
  id?: string;
  title?: string;
  description?: string;
  superGroupId?: string;
  /** Representation of an Image */
  image?: Image;
  /** Representation of an Image */
  thumbnail?: Image;
  /** @format int32 */
  articleCount?: number;
  articleRefs?: string[];
  /** @format int32 */
  order?: number;
}

export interface CatalogAddonsGroupList {
  groups?: CatalogAddonGroup[];
}

/** Representation of a Catalog List */
export interface CatalogList {
  /** List of catalog items */
  catalogs?: Catalog[];
}

/** Representation of a Catalog Version */
export interface CatalogVersion {
  /** Identifier of abstract catalog item */
  id?: string;
  /**
   * Date of last modification
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  lastModified?: string;
  /** Name of abstract catalog item */
  name?: string;
  /** Url address of abstract catalog item */
  url?: string;
  /** List of category hierarchies */
  categories?: CategoryHierarchy[];
}

/** Representation of a Category */
export interface Category {
  /** Code of the category */
  code?: string;
  /** Name of the category */
  name?: string;
  /** URL of the category */
  url?: string;
  /** Representation of an Image */
  image?: Image;
  /** Representation of an Image */
  banner?: Image;
  description?: string;
  /** @format int32 */
  level?: number;
  subCategories?: Category[];
  subCategoryCodes?: string[];
  superCategoryCode?: string;
  headline?: string;
  longDescription?: string;
  seo?: SeoMeta;
  notPriceFileEnabled?: boolean;
}

/** Representation of a Category Hierarchy */
export interface CategoryHierarchy {
  /** Identifier of abstract catalog item */
  id?: string;
  /**
   * Date of last modification
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  lastModified?: string;
  /** Name of abstract catalog item */
  name?: string;
  /** Url address of abstract catalog item */
  url?: string;
  /** List of subcategory hierarchies */
  subcategories?: CategoryHierarchy[];
  products?: Product[];
}

export interface CategoryList {
  categories?: Category[];
}

export interface Certification {
  key?: string;
  shortName?: string;
  name?: string;
  description?: string;
  featured?: boolean;
  /** Representation of an Image */
  icon?: Image;
}

export interface Checkout {
  /** Representation of a Cart */
  cart?: Cart;
  entityType?: string;
  hasOrderLineErrors?: boolean;
  debugCheckout?: boolean;
  mixedPrePrintingCart?: boolean;
  oci?: OciCheckout;
  punchOut?: PunchOutPayload;
  shipping?: Shipping;
  orderNoteData?: OrderNote;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  latestSapEarliestDate?: string;
}

/** Representation of a Classification */
export interface Classification {
  /** Code of the classification */
  code?: string;
  /** Name of the classification */
  name?: string;
  /** List of features for given classification */
  features?: Feature[];
}

export interface ClassificationAttribute {
  /** Code of the feature */
  code?: string;
  /** Name of the feature */
  name?: string;
  /** Description of the feature */
  description?: string;
  /** Type of the feature */
  type?: string;
  /** Range number of the feature */
  range?: boolean;
  /** Flag defining it feature is comparable */
  comparable?: boolean;
  /** Representation of a Feature Unit */
  featureUnit?: FeatureUnit;
  /** List of feature values */
  featureValues?: FeatureValue[];
  rawValue?: string;
  value?: string;
  unitString?: string;
  hasMultipleValues?: boolean;
}

export interface ClassificationAttributeData {
  code?: string;
  name?: string;
}

/** A collection value of <T> where T is a simple type. Such as String, Boolean, Integer etc */
export interface CollectionValue {
  value: string[];
}

/**
 * Comment object
 * @example "Rejected because monthly budget was exceeded"
 */
export interface Comment {
  code?: string;
  internal?: boolean;
  text?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  creationDate?: string;
  /** Principal data representation with user roles */
  author?: PrincipalWithRoles;
  fromCustomer?: boolean;
  authorEmail?: string;
  cloned?: boolean;
}

export interface CommentList {
  comments?: Comment[];
}

/** Result list */
export interface CompanySignUp {
  address?: PyAddress;
  companyName?: string;
  /** A Bisnode unique ID for businesses OR A unique nine-digit identifier for businesses (Data Universal Numbering System) Used by Dun and Bradstreet Decided by companySearchServiceType */
  companyId?: string;
  companySearchServiceType?: string;
  /**
   * A unique nine-digit identifier for businesses (Data Universal Numbering System) Used by Dun and Bradstreet
   * @deprecated
   */
  duns?: string;
  /**
   * A Bisnode unique ID for businesses
   * @deprecated
   */
  bisnodeId?: string;
  /** Organizational Unit object. */
  designatedRootUnit?: B2BUnit;
  soldToId?: string;
  /** @deprecated */
  candidateSoldTos?: string[];
  organizationNumber?: string;
  taxCode?: string;
  /** A flag to be included in email to customer support Indicates if we have found possible soldTos for the onboarding user but could not specify exactly one */
  'candidateSoldToExists '?: boolean;
}

export interface CompanySignUpPage {
  /** Result list */
  results?: CompanySignUp[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
  /** Indicates that there is exactly one sold to verified to exist for the given email address or sold to id */
  hasUniqueIdentifiedCustomer?: boolean;
  /** Indicates that there is exactly one designated root unit verified to exist for the onboarding user */
  hasUniqueIdentifiedRoot?: boolean;
  /** Indicates that there is already an account being processed with the given email */
  accountBeingProcessed?: boolean;
  /** Indicates that for given email address there is a contact person with connected soldTo, without existing user */
  contactPersonOnly?: boolean;
}

export interface ComparativePriceEntry {
  label?: string;
  formattedValue?: string;
  currencySymbol?: string;
  /** @format double */
  rawValue?: number;
}

/** List of component identifiers */
export interface ComponentIDList {
  idList?: string[];
}

export interface ComponentList {
  component?: Component[];
}

export interface Component {
  uid?: string;
  uuid?: string;
  typeCode?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  modifiedtime?: string;
  name?: string;
  otherProperties?: Record<string, object>;
}

/** Representation of a Configuration Info */
export interface ConfigurationInfo {
  /** Type of configuration info */
  configuratorType?: string;
  /** Status of configuration info */
  status?: string;
  /** Label of configuration info */
  configurationLabel?: string;
  /** Value of configuration info */
  configurationValue?: string;
  /** The identifier, if populated */
  id?: string;
  /** The extended identifier, if populated */
  extendedIdentifier?: string;
  /** The qualifier, if populated */
  qualifier?: string;
  /** Configuration values, to use only for configuration type TEXT */
  configurationTextValues?: string[];
}

/** Representation of a Consent */
export interface Consent {
  /** Code of consent */
  code?: string;
  /**
   * Date of consenting
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  consentGivenDate?: string;
  /**
   * Consent withdrawn date
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  consentWithdrawnDate?: string;
}

/** Representation of a Consent Template */
export interface ConsentTemplate {
  /** Consent template identifier */
  id?: string;
  /** Consent template name */
  name?: string;
  /** Consent template description */
  description?: string;
  /**
   * Consent template version
   * @format int32
   */
  version?: number;
  /** Representation of a Consent */
  currentConsent?: Consent;
  /** Consent template changes description */
  changesDescription?: string;
}

/** Representation of a Consent Template List */
export interface ConsentTemplateList {
  /** List of consent templates */
  consentTemplates?: ConsentTemplate[];
}

/** Representation of a Consignment */
export interface Consignment {
  /** Consignment code */
  code?: string;
  /** Consignment tracking identifier */
  trackingID?: string;
  /** Consignment status */
  status?: string;
  /** Consignment status display */
  statusDisplay?: string;
  /**
   * Consignment status date
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  statusDate?: string;
  /** List of consignment entries */
  entries?: ConsignmentEntry[];
  /** Address object */
  shippingAddress?: Address;
  /** Representation of a Point of service */
  deliveryPointOfService?: PointOfService;
}

export interface ConsignmentArticleStock {
  warehouses?: ConsignmentArticleWarehouseStock[];
}

export interface ConsignmentArticleWarehouseStock {
  code?: string;
  warehouse?: Warehouse;
  stock?: WarehouseStock;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  lastFillupDate?: string;
  lastFillupStock?: WarehouseStock;
}

/** Representation of a Consignment Entry */
export interface ConsignmentEntry {
  /**
   * Request body parameter that contains details such as the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name)
   *
   * The DTO is in XML or .json format.
   */
  orderEntry?: OrderEntry;
  /**
   * Quantity value of Consignment entry
   * @format int64
   */
  quantity?: number;
  /**
   * Shipped quantity
   * @format int64
   */
  shippedQuantity?: number;
}

export interface ContentPage {
  label?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  publishedDate?: string;
}

export interface ContentPageList {
  values?: ContentPage[];
}

export interface ContentSlotList {
  contentSlot?: ContentSlot[];
}

export interface ContentSlot {
  slotId?: string;
  slotUuid?: string;
  position?: string;
  name?: string;
  slotShared?: boolean;
  slotStatus?: string;
  components?: ComponentList;
  otherProperties?: Record<string, object>;
}

/** A ContentTag */
export interface ContentTag {
  uid?: string;
  displayName?: string;
  displayInUI?: boolean;
}

export interface ContentTagList {
  values?: ContentTag[];
}

/** Response body fields which will be returned while fetching the list of countries. The DTO is in XML or .json format */
export interface Country {
  /**
   * Country code in iso format
   * @example "US"
   */
  isocode?: string;
  /**
   * Name of the country
   * @example "United States"
   */
  name?: string;
  countryPhoneCode?: string;
  countryFlagSprite?: string;
  companySearchSupported?: boolean;
}

/** List of countries */
export interface CountryList {
  /** This is the list of Country fields that should be returned in the response body */
  countries?: Country[];
}

export interface CountryPostalCode {
  postalCode?: string;
  countryIsoCode?: string;
}

/** Order card configuration */
export interface CreateOrUpdateOrderCardConfiguration {
  /**
   * Product code
   * @example "N-A541"
   */
  product?: string;
  /** Article code */
  article?: string;
  /**
   * SAP localized unit
   * @example "FL"
   */
  unit?: string;
  /**
   * Quantity for the specified article and unit
   * @format int32
   */
  quantity?: number;
  /**
   * Name marking texts
   * @example "Jan Banan"
   */
  nameMarkingTexts?: string[];
}

/** Credential settings */
export interface CredentialsSettings {
  email?: string;
  userName?: string;
  password?: string;
  newPassword?: string;
  confirmPassword?: string;
}

/** Representation of a Currency */
export interface Currency {
  /** Code of the currency in iso format */
  isocode?: string;
  /** Name of the currency */
  name?: string;
  /** Boolean flag whether currency is active */
  active?: boolean;
  /** Symbol of the currency */
  symbol?: string;
}

/** Representation of a Currency List */
export interface CurrencyList {
  /** List of currencies */
  currencies?: Currency[];
}

/** Customer */
export interface Customer {
  /** Unique user identifier */
  uid?: string;
  /** Name of the user */
  name?: string;
  pk?: string;
  customerType?: string;
  addressData?: PyAddress;
  /** Custom ship-to */
  companyAddress?: ShipTo;
  extraEmails?: string;
  invoiceEmail?: string;
  organizationNumber?: string;
  /** @format int32 */
  itemsPerPage?: number;
  contactPersonId?: string;
  marketingApproval?: boolean;
  requiringConfirmationEmail?: boolean;
  requiringProfileUpdateEmail?: boolean;
  requiringPriceFileReadyEmail?: boolean;
  approvalEmailDeliverySetting?: string;
  requestingPayByInvoice?: boolean;
  requiringOrderApprovalStatusEmail?: boolean;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  lastLoginDate?: string;
  paperManager?: boolean;
  loginDisabled?: boolean;
  passwordUpdateRequired?: boolean;
  hasAddons?: boolean;
  hasCustomerAssortment?: boolean;
  hasTendered?: boolean;
  hasUniqueArticles?: boolean;
  demoAccount?: boolean;
  allowCustomerPricingGroupInPurchaseStatistics?: boolean;
  activeSession?: boolean;
  oneTimeCustomer?: boolean;
  b2BCommerceCustomer?: boolean;
  invoiceCustomerProspect?: boolean;
  titleCode?: string;
  title?: string;
  additionalInfo?: string;
  firstName?: string;
  lastName?: string;
  language?: LanguageData;
  displayUid?: string;
  customerId?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  deactivationDate?: string;
  approvalVisibilityLimitationType?: string;
  approvalVisibilityLimitationValues?: string[];
  status?: CustomerStatus;
  normalizedUid?: string;
  /** Representation of a Principal webservice DTO used for defining User data types */
  unit?: Principal;
  email?: string;
  phone?: string;
  countryPhoneCode?: string;
  contactNumber?: string;
  active?: boolean;
  selected?: boolean;
  roles?: string[];
  displayRoles?: string[];
  /** Representation of a Principal webservice DTO used for defining User data types */
  userRole?: Principal;
  permissionGroups?: Principal[];
  approvers?: Principal[];
  approverGroups?: Principal[];
  permissions?: PermissionType[];
  activeSoldToUid?: string;
  assignedSoldTos?: SoldTo[];
  assignedShipTos?: AssignedShipTo[];
}

export interface CustomerPage {
  /** Result list */
  results?: Customer[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

export interface CustomerStatus {
  /** Code of the Customer Status */
  code: string;
  /** Name of the Permission Type */
  name?: string;
}

export interface CustomerStatusList {
  customerStatuses?: CustomerStatus[];
}

export interface CustomerUUID {
  /** Principal UUID */
  customerId?: string;
}

export interface CustomerUploadedMedia {
  downloadUrl?: string;
  alternativeUrl?: string;
  filename?: string;
  /** Representation of an Image */
  thumbnail?: Image;
  qualifier?: string;
}

export interface Cutting {
  description?: string;
  /** @format int32 */
  length?: number;
  /** @format int32 */
  width?: number;
  /** @format int64 */
  resultingQuantity?: number;
  resultingUnit?: Unit;
  /** @format int32 */
  numberOfCuts?: number;
  /** @format int32 */
  numberOfDaughters?: number;
  grainDirection?: string;
  dustFree?: boolean;
  perpendicular?: boolean;
  template?: string;
  packaging?: string;
  /** Representation of a Price */
  cuttingPrice?: Price;
  /** Representation of a Price */
  packagingPrice?: Price;
}

/** Cutting request */
export interface CuttingRequest {
  /** @format int32 */
  daughterWidth: number;
  /** @format int32 */
  daughterLength: number;
  dustFree: boolean;
  perpendicular: boolean;
  daughterGrainDirection: string;
  /** @format int32 */
  numberOfDaughters: number;
  /** @format int32 */
  numberOfCuts: number;
  templateCode: string;
  packagingCode: string;
}

/** Cutting template */
export interface CuttingTemplate {
  code?: string;
  /** @format int32 */
  numberOfCuts?: number;
  /** @format int32 */
  numberOfDaughters?: number;
  /** @format int32 */
  paperUsagePercent?: number;
}

/** Cutting template request */
export interface CuttingTemplateRequest {
  /** @format int32 */
  daughterWidth: number;
  /** @format int32 */
  daughterLength: number;
  dustFree: boolean;
  perpendicular: boolean;
}

export interface DataImport {
  /** Data import identifier */
  code?: string;
  /** Import status */
  status?: DataImportStatusEnum;
  /**
   * Number of rows that have an error occured during import
   * @format int32
   */
  errorsTotal?: number;
  /**
   * Number of rows successfully imported
   * @format int32
   */
  successTotal?: number;
}

export interface DateInterval {
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  dateFrom?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  dateTo?: string;
}

export interface DeliveryDateInfo {
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  nextDeliveryDate?: string;
  sameDayDeliveryAvailable?: boolean;
  sameDayDeliveryCurrency?: string;
  /** @format double */
  sameDayDeliveryFee?: number;
}

/** Representation of a Delivery mode */
export interface DeliveryMode {
  /**
   * Code of the delivery mode
   * @example "premium-gross"
   */
  code?: string;
  /**
   * Name of the delivery mode
   * @example "Premium Delivery"
   */
  name?: string;
  /**
   * Description of the delivery mode
   * @example "1-2 business days"
   */
  description?: string;
  /** Representation of a Price */
  deliveryCost?: Price;
}

/** Representation of a Delivery mode list */
export interface DeliveryModeList {
  /** List of delivery modes */
  deliveryModes?: DeliveryMode[];
}

/** Representation of a Delivery Order Entry Group */
export interface DeliveryOrderEntryGroup {
  /** Representation of a Price */
  totalPriceWithTax?: Price;
  /** List of order entries */
  entries?: OrderEntry[];
  /**
   * Quantity of order entries in a group
   * @format int64
   */
  quantity?: number;
  /** Address object */
  deliveryAddress?: Address;
  /**
   * Delivery date for order entry group
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  deliveryDate?: string;
}

/** Result list */
export interface EcConformityProductDocument {
  code?: string;
  title?: string;
  type?: string;
  types?: string[];
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  authoritativeDate?: string;
  downloadUrl?: string;
  entities?: ProductRefs;
  integrationCode?: string;
  languageString?: string;
  certificateString?: string;
  brand?: string;
}

export interface EcConformityProductDocumentsPage {
  /** Result list */
  results?: EcConformityProductDocument[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

export interface EntryFlag {
  allowCutting?: boolean;
  allowReaming?: boolean;
  allowPalletFlags?: boolean;
}

/** Representation of an Entry Group */
export interface EntryGroup {
  /** List of order entries */
  entries?: OrderEntry[];
  /** List of child entry groups */
  entryGroups?: EntryGroup[];
  /**
   * Identifier of the entry group
   * @format int32
   * @example 1
   */
  entryGroupNumber?: number;
  /**
   * Label for the entry group
   * @example "Photo On The Go Package"
   */
  label?: string;
  /**
   * Indicates if the entry group is in an error state
   * @example true
   */
  erroneous?: boolean;
  /**
   * Indicates type of the group, possible values are STANDALONE, CONFIGURABLEBUNDLE or any customer implemented type for any new provider
   * @example "STANDALONE"
   */
  type?: string;
}

/** Error message */
export interface Error {
  /** Type of the error e.g. 'LowStockError'. */
  type?: string;
  /** Additional classification specific for each error type e.g. 'noStock'. */
  reason?: string;
  /** Descriptive, human readable error message. */
  message?: string;
  /** Type of the object related to the error e.g. 'entry'. */
  subjectType?: string;
  /** Identifier of the related object e.g. '1'. */
  subject?: string;
  /** Error code */
  errorCode?: string;
  /** @example "English" */
  language?: string;
  /**
   * @format int32
   * @example 1
   */
  position?: number;
  exceptionMessage?: string;
  additionalInfo?: Record<string, object>;
}

export interface ExplodedOrderLine {
  articleNumber?: string;
  shortText?: string;
  /** @format int64 */
  highLevelItem?: number;
  unit?: UnitData;
  /** @format int64 */
  quantity?: number;
  /** Representation of a Price */
  price?: Price;
  itemPosition?: string;
}

/** Representation of a Facet */
export interface Facet {
  /** Name of the facet */
  name?: string;
  /**
   * Priority value of the facet
   * @format int32
   */
  priority?: number;
  /** Flag stating if facet is category facet */
  category?: boolean;
  /** Flag stating if facet is multiSelect */
  multiSelect?: boolean;
  /** Flag stating if facet is visible */
  visible?: boolean;
  /** List of top facet values */
  topValues?: FacetValue[];
  /** List of all facet values */
  values?: FacetValue[];
  /** Code of the facet */
  code?: string;
}

/** Representation of a Facet Value */
export interface FacetValue {
  /** Name of the facet value */
  name?: string;
  /**
   * Count of the facet value
   * @format int64
   */
  count?: number;
  /** Representation of a Search State */
  query?: SearchState;
  /** Flag stating if facet value is selected */
  selected?: boolean;
  /** Code of the facet value */
  code?: string;
}

/** Used to represent an article which failed to be added to the cart. */
export interface FailedAddToCartArticle {
  articleNumber?: string;
  /** @format int64 */
  quantity?: number;
  errorMessage?: string;
}

/** Representation of a Feature */
export interface Feature {
  /** Code of the feature */
  code?: string;
  /** Name of the feature */
  name?: string;
  /** Description of the feature */
  description?: string;
  /** Type of the feature */
  type?: string;
  /** Range number of the feature */
  range?: boolean;
  /** Flag defining it feature is comparable */
  comparable?: boolean;
  /** Representation of a Feature Unit */
  featureUnit?: FeatureUnit;
  /** List of feature values */
  featureValues?: FeatureValue[];
}

/** Representation of a Feature Unit */
export interface FeatureUnit {
  /** Symbol of the feature unit */
  symbol?: string;
  /** Name of the feature unit */
  name?: string;
  /** Type of the feature unit */
  unitType?: string;
}

/** Representation of a Feature Value */
export interface FeatureValue {
  /** Value of the feature */
  value?: string;
}

export interface Feed {
  /** Operational Message Wrapper with status defining if the message exist */
  operationalMessage?: OperationalMessageWrapper;
  /** List of polling Principal Configurations */
  configurations?: PrincipalConfiguration[];
  /** List of Badges for customer notifications */
  badges?: Badge[];
  /**
   * Last area of use sync start time for rbu so we can pass it back to the front end to determine status.
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  lastAreaOfUseSyncStartTime?: string;
  /**
   * Last area of use sync end time for rbu so we can pass it back to the front end to determine status.
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  lastAreaOfUseSyncEndTime?: string;
  /** Flag determining if area of use sync should be run after deleting areas to update index. Front end show message based on that. */
  areaDeletionSyncRequired?: boolean;
  /** Flag determining if there exists ongoing auto order price file for current soldTo */
  hasOngoingSoldToPriceFileRequest?: boolean;
  feedRequestListDTO?: FeedRequestListDTO;
}

export interface FeedRequestListDTO {
  approvalOrders?: FeedRequest;
  unavailableArticlesCheck?: FeedRequest;
  unavailableProductsCheck?: FeedRequest;
  areaOfUse?: FeedRequest;
  usermanagement?: FeedRequest;
  operationalMessage?: FeedRequest;
  configurationUpdates?: FeedRequest;
}

export interface FeedRequest {
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  timeStamp?: string;
}

export interface FullPalletUpgrade {
  /** @format int64 */
  remainingQuantity?: number;
  /** @format int64 */
  remainingQuantityInCustomerSelectedUnit?: number;
  /** @format int64 */
  fullPalletQuantity?: number;
  upgradable?: boolean;
}

/** Representation of a Future Stock */
export interface FutureStock {
  /** Representation of a Stock */
  stock?: Stock;
  /**
   * Date of future stock
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  date?: string;
  /**
   * Date of future stock expressed in text value
   * @example "31/12/2056"
   */
  formattedDate?: string;
}

/** Representation of a GeoPoint */
export interface GeoPoint {
  /**
   * Geopoint latitude
   * @format double
   */
  latitude?: number;
  /**
   * Geopoint longitude
   * @format double
   */
  longitude?: number;
}

export interface GoogleTagManager {
  containerId?: string;
  serverContainerUrl?: string;
}

/** Response parameters */
export interface GpWebPayResponseParams {
  ordernumber?: string;
  prcode?: string;
  srcode?: string;
  resulttext?: string;
  accode?: string;
  digest?: string;
  digest1?: string;
  daytocapture?: string;
  token?: string;
  operation?: string;
  merordernum?: string;
  md?: string;
  userparam1?: string;
  addinfo?: string;
  expiry?: string;
  acsres?: string;
  panpattern?: string;
  tokenregstatus?: string;
}

/** Representation of an Image */
export interface Image {
  /** Type of the image, can be PRIMARY or GALLERY */
  imageType?: ImageImageTypeEnum;
  /** Format of the image, can be zoom, product, thumbnail, store, cartIcon, etc. */
  format?: string;
  /** URL address of the image */
  url?: string;
  /** Tooltip content which is visible while image mouse hovering */
  altText?: string;
  /**
   * Index of the image while displayed in gallery
   * @format int32
   */
  galleryIndex?: number;
  /** @format int32 */
  width?: number;
}

/** Basic information about customer for impersonation customer search */
export interface ImpersonationCustomer {
  uid?: string;
  name?: string;
  email?: string;
  userRole?: string;
  soldToId?: string;
  soldToName?: string;
  multipleSoldTos?: boolean;
  cpg?: string;
}

export interface ImpersonationCustomerPage {
  /** Result list */
  results?: ImpersonationCustomer[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

export interface InvoiceHistoryPage {
  /** Result list */
  results?: InvoiceHistoryRow[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
  /** Representation of a Price */
  invoiceListSumTotal?: Price;
}

/** Result list */
export interface InvoiceHistoryRow {
  code?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  invoiceDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  paymentDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  expirationDate?: string;
  /** Representation of a Price */
  invoiceValue?: Price;
  /** Representation of a Price */
  invoiceValueWithTax?: Price;
  printed?: boolean;
  paymentStatus?: string;
  paymentStatusText?: string;
}

export interface KitMasterArticle {
  kitIdentifier?: string;
  /** @format int32 */
  quantity?: number;
  unitCode?: string;
  unit?: Unit;
  children?: KitMemberArticle[];
}

export interface KitMemberArticle {
  article?: ArticleRef;
  /** @format int32 */
  quantity?: number;
  unitCode?: string;
  unit?: Unit;
}

/** Representation of a Language */
export interface Language {
  /** iso code of the language */
  isocode?: string;
  /** name of the language */
  name?: string;
  /** name the language in native form */
  nativeName?: string;
  /** true/false indicator when the language is active */
  active?: boolean;
}

export interface LanguageData {
  isocode?: string;
  name?: string;
  nativeName?: string;
  active?: boolean;
  required?: boolean;
}

/** Lists all available languages (all languages used for a particular store). If the list of languages for a base store is empty, a list of all languages available in the system will be returned */
export interface LanguageList {
  /** This is the list of Language fields that should be returned in the response body */
  languages?: Language[];
}

export interface LocalArticleWarehouseStatus {
  code?: string;
  warehouse?: Warehouse;
  offersBreakable?: boolean;
  offersCutting?: boolean;
}

/** Log object */
export interface Log {
  /** Log level */
  level?: string;
  /** Log timestamp */
  timestamp?: string;
  /** File name where the log event occurred */
  fileName?: string;
  /** Line number where the log event occurred */
  lineNumber?: string;
  /** Log message */
  message?: string;
  /** Additional messages */
  additional?: string[];
  /** Route where the log event occurred */
  route?: string;
}

/** List of users to assign to customer group. */
export interface MemberList {
  /** List of member */
  members?: Principal[];
}

export interface Message {
  tag?: string;
  text?: string;
  identifier?: string;
  type?: string;
  code?: string;
  unit?: string;
  /** @format int64 */
  quantity?: number;
}

export interface MessageList {
  messages?: Message[];
}

export interface MultipleArticlesAddedResponse {
  message?: string;
  success?: boolean;
  /** @format int32 */
  itemsAdded?: number;
  analyticsData?: AnalyticsArticle[];
  failedArticlesData?: FailedAddToCartArticle[];
  code?: string;
  containsRefiningRule?: boolean;
}

export interface NewFreeStock {
  articleNumber?: string;
  description?: string;
  supplierNumber?: string;
  /** @format int32 */
  quantityPerFullPallet?: number;
  breakable?: boolean;
  minimumPickingUnit?: string;
  /** @format int32 */
  length?: number;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
  /** @format int32 */
  weight?: number;
}

export interface NewsList {
  news?: News[];
}

export interface News {
  id?: string;
  title?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  fromDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  toDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  publicationDate?: string;
  activeNews?: boolean;
  description?: string;
  label?: string;
  /** Representation of an Image */
  featuredImage?: Image;
}

export interface OciCheckout {
  oci?: boolean;
  ociError?: boolean;
  articles?: PunchOutPayload[];
  hookUrl?: string;
  returnTarget?: string;
  buyBtnLabel?: string;
  cancelLabel?: string;
  charset?: string;
}

/** Representation of a Omni Search Page, containing articles, products, categories, services and content matches along with facets, breadcrumbs, sorts, the current search state and spelling suggestions. */
export interface OmniSearchPage {
  /** Free text search */
  freeTextSearch?: string;
  /** Code of category currently 'active' */
  categoryCode?: string;
  /** Redirect url address keyword */
  keywordRedirectUrl?: string;
  /** Representation of a Spell Checker Suggestion */
  spellingSuggestion?: SpellingSuggestion;
  /** List of sorts */
  sorts?: Sort[];
  /** Representation of a search results pagination */
  pagination?: DeprecatedPagination;
  /** Representation of a Search State */
  currentQuery?: SearchState;
  /** List of breadcrumbs info */
  breadcrumbs?: Breadcrumb[];
  /** List of facets */
  facets?: Facet[];
  /** List of categories matching the search */
  categoryRefs?: string[];
  /** List of categories matching the search */
  areaOfUseRefs?: string[];
  /** List of products matching the search */
  productRefs?: string[];
  /** New list of products matching the search */
  productResultRefs?: SolrResultEntityRef[];
  /** List of articles matching the search */
  articleRefs?: string[];
  /** New list of articles matching the search */
  articleResultRefs?: SolrResultEntityRef[];
  /**
   * The total number of matched article results across all pages
   * @format int64
   */
  totalArticleResults?: number;
}

export interface OnlineChatConfig {
  code?: string;
  theme?: string;
  color?: string;
  font?: string;
  delay?: string;
  topicId?: string;
  baseUrl?: string;
  title?: string;
}

/** Representation of an Opening schedule */
export interface OpeningSchedule {
  /** Name of the opening schedule */
  name?: string;
  /** Code of the opening schedule */
  code?: string;
  /** List of weekday opening days */
  weekDayOpeningList?: WeekdayOpeningDay[];
  /** List of special opening days */
  specialDayOpeningList?: SpecialOpeningDay[];
}

/** Operational Message */
export interface OperationalMessage {
  headerMessage?: string;
  bodyMessage?: string;
}

/** Operational Message Wrapper with status defining if the message exist */
export interface OperationalMessageWrapper {
  /** Status indicator */
  status?: OperationalMessageWrapperStatusEnum;
  /** Operational Message */
  message?: OperationalMessage;
}

/** Representation of an Order */
export interface Order {
  /** Code number of order */
  code?: string;
  /** Flag stating iv value is net-value */
  net?: boolean;
  /** Representation of a Price */
  totalPriceWithTax?: Price;
  /** Representation of a Price */
  totalPrice?: Price;
  /** Representation of a Price */
  totalTax?: Price;
  /** Representation of a Price */
  subTotal?: Price;
  /** Representation of a Price */
  deliveryCost?: Price;
  /** List of order entries */
  entries?: OrderEntry[];
  /** List of entry groups */
  entryGroups?: EntryGroup[];
  /** @format int32 */
  totalItems?: number;
  /** Representation of a Delivery mode */
  deliveryMode?: DeliveryMode;
  /** Address object */
  deliveryAddress?: Address;
  /** Payment details object */
  paymentInfo?: PaymentDetails;
  /** List of applied order promotions */
  appliedOrderPromotions?: PromotionResult[];
  /** List of applied product promotions */
  appliedProductPromotions?: PromotionResult[];
  /** Representation of a Price */
  productDiscounts?: Price;
  /** Representation of a Price */
  orderDiscounts?: Price;
  /** Representation of a Price */
  totalDiscounts?: Price;
  /** Site */
  site?: string;
  /** Store */
  store?: string;
  /** Guest user id identifier */
  guid?: string;
  /** Flag showing if order is calculated */
  calculated?: boolean;
  /** List of applied vouchers */
  appliedVouchers?: Voucher[];
  /** Representation of a Principal webservice DTO used for defining User data types */
  user?: Principal;
  /** List of pickup order entry group */
  pickupOrderGroups?: PickupOrderEntryGroup[];
  /** List of delivery order entries group */
  deliveryOrderGroups?: DeliveryOrderEntryGroup[];
  /**
   * Quantity of pickup items
   * @format int64
   */
  pickupItemsQuantity?: number;
  /**
   * Quantity of delivery items
   * @format int64
   */
  deliveryItemsQuantity?: number;
  /** Customer requested date for order retrieval */
  requestedRetrievalAt?: string;
  conditions?: SapOrderCondition[];
  pyOrderParameters?: PyOrderParameters;
  /** @format double */
  weight?: number;
  weightUnit?: string;
  /** @format double */
  rowSum?: number;
  /** @format double */
  feeSum?: number;
  /** @format double */
  rowSumIncludingFees?: number;
  /** Representation of a Price */
  orderEntryFeeSum?: Price;
  /** Representation of a Price */
  orderEntrySum?: Price;
  /** Representation of a Price */
  orderEntrySumIncludingFees?: Price;
  orderType?: OrderOrderTypeEnum;
  messages?: Message[];
  permissionResults?: PermissionResult[];
  /**
   * Date of order creation
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  created?: string;
  /** Status of order */
  status?: string;
  /** Status display */
  statusDisplay?: string;
  /** Flag showing if customer is Guest customer */
  guestCustomer?: boolean;
  /** List of consignment */
  consignments?: Consignment[];
  /** Order delivery status */
  deliveryStatus?: string;
  /** Order delivery status display */
  deliveryStatusDisplay?: string;
  /** List of unconsigned order entries */
  unconsignedEntries?: OrderEntry[];
  /**
   * Boolean flag showing if order is cancellable
   * @example true
   */
  cancellable?: boolean;
  /**
   * Boolean flag showing if order is returnable
   * @example true
   */
  returnable?: boolean;
  /** @format int32 */
  totalUnitCount?: number;
  /** Purchase order number */
  purchaseOrderNumber?: string;
  /** Customer */
  orgCustomer?: Customer;
}

/** Details of one specific order approval */
export interface OrderApproval {
  /**
   * Code that identifies the approval.
   * @example "00000005"
   */
  code: string;
  /** Representation of an Order */
  order?: Order;
  /** Boolean flag which states whether an approval decision is required. */
  approvalDecisionRequired?: boolean;
  /** Customer approval records related to this order approval. */
  customerOrderApprovalRecords?: OrderApprovalRecord[];
  /** Merchant approval records related to this order approval. */
  merchantOrderApprovalRecords?: OrderApprovalRecord[];
  deliveryAddress?: string;
  sapOrderNumber?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  orderDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  returnedToBuyer?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  completionDate?: string;
  status?: string;
  approvalStatus?: OrderApprovalApprovalStatusEnum;
  /** Simple customer data representation with basic information */
  user?: SimpleCustomer;
  /** Representation of a Principal webservice DTO used for defining User data types */
  lastInteractingApprover?: Principal;
  subTotal?: string;
  /** Representation of a Principal webservice DTO used for defining User data types */
  placedBy?: Principal;
  soldTo?: SoldTo;
  shippingCondition?: string;
  shippingConditionText?: string;
  /** @format double */
  rowWeightSum?: number;
  rowWeightUnit?: string;
  hasReinvoicingEntries?: boolean;
  /** @format int32 */
  discontinuedArticles?: number;
}

/** Order approval decision object */
export interface OrderApprovalDecision {
  /**
   * Decision from the approver when approving/rejecting an order. Typical decisions are: APPROVE, REJECT
   * @example "APPROVE"
   */
  decision: string;
  /** Comment object */
  comment?: Comment;
}

export interface OrderApprovalPage {
  /** Result list */
  results?: OrderApproval[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

/** Details of one order approval record */
export interface OrderApprovalRecord {
  /** List of permission types related to this approval record. */
  permissionTypes?: PermissionType[];
  /** Representation of a Principal webservice DTO used for defining User data types */
  approver?: Principal;
  /**
   * Status of the order approval.
   * @example "Pending approval"
   */
  statusDisplay?: string;
  /**
   * Any comments the approver (or the workflow system) added to the approval item.
   * @example "Submitted for approval"
   */
  comments?: string;
}

export interface OrderCard {
  /**
   * Order card identification code (autogenerated)
   * @example "KXL001"
   */
  code?: string;
  /**
   * Order card name
   * @example "Office material"
   */
  name?: string;
  /**
   * Order card owner uid (root unit)
   * @example "11372526"
   */
  owner?: string;
  /**
   * Order card description
   * @example "Paper, envelopes etc."
   */
  description?: string;
  /**
   * Number of principals assigned to order card
   * @format int32
   */
  assignedPrincipalCount?: number;
  /**
   * Number of products on the order card
   * @format int32
   */
  productCount?: number;
  /** Codes for order card products */
  products?: string[];
  /** Codes for order card products to be displayed on order card */
  displayProducts?: string[];
  /** Order card contact person information */
  contactPerson?: OrderCardContactPerson;
  /**
   * Order card status (ACTIVE or INACTIVE)
   * @example "INACTIVE"
   */
  status?: OrderCardStatusEnum;
  /**
   * Number of unavailable products in order card
   * @format int32
   */
  unavailableProductsCount?: number;
}

export interface OrderCardConfiguration {
  customer?: string;
  /**
   * Order card indentifier
   * @example "KXL001"
   */
  orderCard?: string;
  /**
   * Product code
   * @example "N-A541"
   */
  product?: string;
  /**
   * Article code
   * @example "511210"
   */
  article?: string;
  /**
   * SAP localized unit
   * @example "FL"
   */
  unit?: string;
  /**
   * Quantity for the specified article and unit
   * @format int32
   */
  quantity?: number;
  /**
   * Name marking texts
   * @example "Jan Banan"
   */
  nameMarkingTexts?: string[];
  /** Flag indicating if name marking should be used */
  useNameMarking?: boolean;
}

export interface OrderCardConfigurationList {
  configurations?: OrderCardConfiguration[];
}

/** Order card contact person information */
export interface OrderCardContactPerson {
  /**
   * Contact person id
   * @example "zaphbrox"
   */
  uid?: string;
  /**
   * Contact person name
   * @example "Zaphod Beeblebrox"
   */
  name?: string;
  /**
   * Contact person email
   * @example "zaphod@beeblebrox.com"
   */
  email?: string;
  /**
   * Contact person phone
   * @example "0707007007"
   */
  phone?: string;
}

export interface OrderCardPage {
  /** Result list */
  results?: OrderCard[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

export interface OrderDetailsOrder {
  orderNumber?: string;
  documentType?: string;
  soldTo?: string;
  soldToObject?: SoldTo;
  shippingCondition?: string;
  shippingConditionText?: string;
  orderNote?: string;
  costCenter?: string;
  invoiceNote?: string;
  deliveryNote?: string;
  rowWeightUnit?: string;
  addresses?: Record<string, PyAddress>;
  orderLines?: OrderDetailsOrderLine[];
  highLevelItems?: Record<string, any>;
  messages?: string[];
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  receivedDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  receivedTime?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  sapReceivedDate?: string;
  netValue?: number;
  /** Representation of a Price */
  netValuePriceData?: Price;
  vatValue?: number;
  /** Representation of a Price */
  vatValuePriceData?: Price;
  priceWithTaxValue?: number;
  /** Representation of a Price */
  priceWithTaxValueData?: Price;
  roundingErrorValue?: number;
  /** @format double */
  rowValueSum?: number;
  /** Representation of a Price */
  rowValueSumPriceData?: Price;
  /** @format double */
  feeValueSum?: number;
  /** Representation of a Price */
  feeValueSumPriceData?: Price;
  /** @format double */
  rowValueSumIncludingFees?: number;
  /** Representation of a Price */
  rowValueSumIncludingFeesPriceData?: Price;
  /** @format double */
  rowWeightSum?: number;
  statusHeader?: OrderDetailsStatusHeader;
  shippingAddress?: PyAddress;
  customerAddress?: PyAddress;
  invoiceNumbers?: string[];
  deliveryNoteNumbers?: string[];
  paymentTerms?: string;
  carryBeyondLoadingBayIncluded?: boolean;
  carryBeyondLoadingBayOption?: boolean;
  unpackingIncluded?: boolean;
  cuttingIncluded?: boolean;
  reelCuttingIncluded?: boolean;
  indentIncluded?: boolean;
  deliveringPlant?: string;
  orderConditions?: SapOrderCondition[];
  onlinePayment?: boolean;
  goodsMarking?: string;
  phoneNumber?: string;
  customerReference?: string;
  message?: string;
  reinvoicing?: Reinvoicing;
  orderType?: OrderDetailsOrderOrderTypeEnum;
  hasReturn?: boolean;
  canReturn?: boolean;
  timeCode?: string;
  sapTimeWindowTimeCode?: TimeWindowDeliveryOption;
}

export interface OrderDetailsOrderLine {
  material?: number;
  /** @format int64 */
  quantity?: number;
  unit?: Unit;
  netPrice?: number;
  /** Representation of a Price */
  netPriceData?: Price;
  /** A single comparative price for an order entry. This OrderEntryComparativePriceWsDTO is distinct from the (unfortunately named) ComparativePriceEntryWsDto. ComparativePriceEntryWsDTO is for multiple comparisons in Stock/Price info. Perhaps they could be merged. */
  comparativePriceData?: OrderEntryComparativePrice;
  linePriceIncludingShareOfOrderFees?: number;
  /** Representation of a Price */
  linePriceDataIncludingShareOfOrderFees?: Price;
  currencyIso?: string;
  warehouse?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  deliveryDate?: string;
  /** @format int64 */
  itemNumber?: number;
  /** @format int64 */
  highLevelItem?: number;
  article?: ArticleRef;
  cutting?: Cutting;
  reelCutting?: ReelCutting;
  reaming?: Reaming;
  palletFlags?: PalletFlags;
  /** Pre-printed label object */
  prePrintedLabel?: PrePrintedLabel;
  statisticsCode?: string;
  /** The accounting code, consisting of its code and description */
  accountingCode?: AccountingCode;
  shortText?: string;
  note?: string;
  itemCategory?: string;
  indent?: boolean;
  shipToAddressNumber?: string;
  doNotUseChildEntryForDisplay?: boolean;
  orderLineConditions?: SapOrderCondition[];
  nameMarkingTextLines?: string[];
  delivered?: boolean;
}

export interface OrderDetailsStatusHeader {
  blocked?: boolean;
  creditProblem?: boolean;
  incomplete?: boolean;
}

/**
 * Request body parameter that contains details such as the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name)
 *
 * The DTO is in XML or .json format.
 */
export interface OrderEntry {
  /**
   * Entry number of the order entry
   * @format int32
   */
  entryNumber?: number;
  /**
   * Quantity number of items in order entry
   * @format int64
   */
  quantity?: number;
  /** Representation of a Price */
  basePrice?: Price;
  /** Representation of a Price */
  totalPrice?: Price;
  /** Representation of a Product */
  product?: Product;
  /** Flag defining if order entry item is updateable */
  updateable?: boolean;
  /** Representation of a Delivery mode */
  deliveryMode?: DeliveryMode;
  /** Configuration info of order entry */
  configurationInfos?: ConfigurationInfo[];
  /** List of aggregated status information per entry, relevant if the entry is configurable and its configuration contains one or many issues in different severities. Note that configurators typically raise such issues only in case the parent document is changeable. In this case the issues (depending on their severity) need to be fixed before a checkout can be done. This means this segment can be present for a cart entry, for order entries it will always be empty */
  statusSummaryList?: StatusSummary[];
  /** Representation of a Point of service */
  deliveryPointOfService?: PointOfService;
  /** Representation of a Price */
  cancelledItemsPrice?: Price;
  /**
   * Quantity number of cancellable items in order entry
   * @format int64
   * @example 5
   */
  cancellableQuantity?: number;
  /** Representation of a Price */
  returnedItemsPrice?: Price;
  /**
   * Quantity number of returnable items in order entry
   * @format int64
   * @example 5
   */
  returnableQuantity?: number;
  type?: OrderEntryTypeEnum;
  article?: ArticleRef;
  pyOrderLineParameters?: PyOrderLineParameters;
  flags?: EntryFlag;
  unitsData?: OrderEntryUnits;
  conditions?: SapOrderCondition[];
  /** Representation of a Price */
  contractedPrice?: Price;
  /** Representation of a Price */
  cartPrice?: Price;
  messages?: Message[];
  a4Sample?: boolean;
  originalSample?: boolean;
  /** Representation of a Product */
  a4SampleProduct?: Product;
  a4SampleDescription?: string;
  /** Auto resolve change data */
  autoResolveChange?: AutoResolveChange;
  resolveOptions?: ResolveOption[];
}

/** A single comparative price for an order entry. This OrderEntryComparativePriceWsDTO is distinct from the (unfortunately named) ComparativePriceEntryWsDto. ComparativePriceEntryWsDTO is for multiple comparisons in Stock/Price info. Perhaps they could be merged. */
export interface OrderEntryComparativePrice {
  hasPrice?: boolean;
  price?: number;
  /** Representation of a Price */
  priceData?: Price;
  unit?: Unit;
  /** @format int64 */
  quantity?: number;
  displayQuantity?: string;
  currencySymbol?: string;
}

/** Representation of an Order entry list consumed */
export interface OrderEntryList {
  /** List of order entries */
  orderEntries?: OrderEntry[];
}

export interface OrderEntryUnits {
  currentSelectedUnit?: Unit;
  sapSelectedUnit?: Unit;
  customerSelectedUnit?: Unit;
  /** @format int64 */
  customerSelectedUnitQty?: number;
  showCustomerSelectedUnit?: boolean;
}

/** Representation of an Order History */
export interface OrderHistory {
  /** Code of Order History */
  code?: string;
  /** Status of Order History */
  status?: string;
  /** Status display */
  statusDisplay?: string;
  /**
   * Date of placing order
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  placed?: string;
  /** Guest user identifier */
  guid?: string;
  /** Representation of a Price */
  total?: Price;
}

/** Result list */
export interface OrderHistoryItem {
  orderId?: string;
  orderNumber?: string;
  /** Representation of a Price */
  total?: Price;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  creationDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  modifiedDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  orderDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  requestedDeliveryDate?: string;
  deliveryDates?: string[];
  deliveryAddress?: PyAddress;
  status?: string;
  statusText?: string;
  note?: string;
  customerPurchaseOrderNumber?: string;
  /** Simple customer data representation with basic information */
  lastInteractingApprover?: SimpleCustomer;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  sapConfirmationDate?: string;
  hasReinvoicingEntries?: boolean;
}

/** Representation of an Order History List */
export interface OrderHistoryList {
  /** List of order history items */
  orders?: OrderHistory[];
  /** List of sorts */
  sorts?: Sort[];
  /** Representation of a search results pagination */
  pagination?: DeprecatedPagination;
}

export interface OrderHistoryPage {
  /** Result list */
  results?: OrderHistoryItem[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
  /** @format int32 */
  maxQueryMonthInterval?: number;
  shipTos?: ShipTo[];
  users?: SimpleCustomer[];
}

export interface OrderMergeProposal {
  orderNumber?: string;
  orderType?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  requestedDeliveryDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  firstDeliveryDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  lastDeliveryDate?: string;
  completeDelivery?: boolean;
  shippingCondition?: string;
  shippingConditionText?: string;
  orderNote?: string;
  shippingGroupId?: string;
  netValue?: number;
  currency?: string;
  smallOrderFee?: boolean;
}

export interface OrderNote {
  orderNote?: string;
  orderNoteInvalid?: boolean;
  orderNoteLabel?: string;
  orderNoteRegex?: string;
  nonEditable?: boolean;
}

export interface OrderProcessStatus {
  sapOrderNumber?: string;
  orderProcessState?: string;
  orderStatus?: string;
}

/** Data object that contains information necessary for order return */
export interface OrderReturn {
  sapOrderNumber?: string;
  draft?: boolean;
  entries?: OrderReturnEntry[];
}

export interface OrderReturnEntry {
  /** @format int64 */
  itemNumber?: number;
  fullQuantity?: boolean;
  /** @format int64 */
  quantity?: number;
  unitCode?: string;
  unit?: Unit;
  quantityText?: string;
  reasonCode?: OrderReturnEntryReasonCodeEnum;
  reasonText?: string;
  comment?: string;
  uploadedFiles?: CustomerUploadedMedia[];
}

/** Representation of an Order Status Update Element */
export interface OrderStatusUpdateElement {
  /** Code of update element of order status */
  code?: string;
  /** Status of update element */
  status?: string;
  /** BaseSite identifier */
  baseSiteId?: string;
}

/** Representation of an Order Status Update Element List */
export interface OrderStatusUpdateElementList {
  /** List of order status update elements */
  orderStatusUpdateElements?: OrderStatusUpdateElement[];
}

/** Data object that contains information necessary for user creation */
export interface OrgCustomerCreation {
  /**
   * Email of the user
   * @example "user@company.com"
   */
  email?: string;
  /**
   * First name of the user
   * @example "Jane"
   */
  firstName?: string;
  /**
   * Last name of the user
   * @example "Doe"
   */
  lastName?: string;
  /**
   * Code of the user's title
   * @example "mrs"
   */
  titleCode?: string;
  /**
   * The user's contact number
   * @example "+46761288200"
   */
  phone?: string;
  /** Organizational Unit object. */
  orgUnit?: B2BUnit;
  /**
   * Optional user name to use for uid, prioritized to email
   * @example "ebiz_se_proc"
   */
  displayUid?: string;
}

/** Data object which contains information necessary for user modification */
export interface OrgCustomerModification {
  /**
   * Email of the user
   * @example "user@company.com"
   */
  email?: string;
  /**
   * First name of the user
   * @example "Jane"
   */
  firstName?: string;
  /**
   * Last name of the user
   * @example "Doe"
   */
  lastName?: string;
  /**
   * Code of the user's title
   * @example "mrs"
   */
  titleCode?: string;
  /**
   * The user's contact number
   * @example "+46761288200"
   */
  phone?: string;
  /** Organizational Unit object. */
  orgUnit?: B2BUnit;
  /**
   * Optional user name to use for uid, prioritized to email
   * @example "ebiz_se_proc"
   */
  displayUid?: string;
  /** login or uid of the user */
  uid?: string;
  /**
   * Boolean flag of whether the user should be activated/deactivated
   * @example true
   */
  activity?: boolean;
  /** Password of the user */
  password?: string;
  /** Whether the user is assigned to any approvers */
  isAssignedToApprovers?: boolean;
  /** List of approval roles */
  approvalRoles?: string[];
  /** Representation of a Principal webservice DTO used for defining User data types */
  userRole?: Principal;
  /** List of assigned sold tos */
  assignedSoldTos?: SoldTo[];
}

/** Data object which contains information necessary for user shipTo assignment - map with soldTo uid as key and shipTo uids as values */
export interface OrgCustomerShipToAssignment {
  /** List of shipTos to be assigned per soldTo */
  assignedShipTos?: Record<string, string[]>;
}

/** Representation of an user list used in organizational units */
export interface OrgUnitUserGroupList {
  /** List of organizational unit user groups */
  groups: B2BUserGroup[];
  /** List of sorts */
  sorts: Sort[];
  /** Representation of a search results pagination */
  pagination: DeprecatedPagination;
}

/** Representation of an user list used in organizational units */
export interface OrgUnitUserList {
  /** List of users */
  users: Customer[];
  /** List of sorts */
  sorts: Sort[];
  /** Representation of a search results pagination */
  pagination: DeprecatedPagination;
}

/** Representation of an user page used in organizational units */
export interface OrgUnitUserPage {
  /** Result list */
  results?: Customer[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

export interface OutgoingInfo {
  hasOutgoingArticles?: boolean;
  hasNonOutgoingArticles?: boolean;
}

/** Pallet flag */
export interface PalletFlag {
  type?: PalletFlagTypeEnum;
  value?: string;
}

export interface PalletFlags {
  customer?: string;
  machine?: string;
  object?: string;
}

/** Pallet flags request */
export interface PalletFlagsRequest {
  flags: PalletFlag[];
}

export interface Panel {
  zoneOrWhatever?: string;
  ticket?: string;
  name?: string;
  path?: string;
  reportTag?: string;
  attributes?: Record<string, string>;
  items?: Recommendation[];
}

export interface PanelResponse {
  panels?: Panel[];
}

/** Contains username and password used for validation */
export interface PasswordValidation {
  uid?: string;
  token?: string;
  password?: string;
}

export interface PastDateInterval {
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  dateFrom?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  dateTo?: string;
}

/** Payment details object */
export interface PaymentDetails {
  /** Unique identifier of payment detail */
  id?: string;
  /** Name of account holder */
  accountHolderName?: string;
  /** Representation of a Card Type */
  cardType?: CardType;
  /** Payment card number */
  cardNumber?: string;
  /** Start month from which payment is valid */
  startMonth?: string;
  /** Start year from which payment is valid */
  startYear?: string;
  /** Month of expiration of payment */
  expiryMonth?: string;
  /** Year of expiration of payment */
  expiryYear?: string;
  /** Issue number */
  issueNumber?: string;
  /** Identifier of subscription */
  subscriptionId?: string;
  /** Flag to mark if payment is saved one */
  saved?: boolean;
  /** Flag to mark if payment the default one */
  defaultPayment?: boolean;
  /** Address object */
  billingAddress?: Address;
}

/** Representation of a Payment details list */
export interface PaymentDetailsList {
  /** List of payment details */
  payments?: PaymentDetails[];
}

/** Representation of a Payment Mode */
export interface PaymentMode {
  /** Payment mode code */
  code?: string;
  /** Payment mode name */
  name?: string;
  /** Payment mode description */
  description?: string;
}

/** Representation of a Payment Mode List */
export interface PaymentModeList {
  /** List of payment modes */
  paymentModes?: PaymentMode[];
}

export interface PaymentOption {
  type?: PaymentOptionTypeEnum;
  displayName?: string;
  tooltip?: string;
  infoText?: string;
  cost?: number;
  icons?: Image[];
  paymentProvider?: PaymentProvider;
}

export interface PaymentOptions {
  paymentOptions?: PaymentOption[];
}

export interface PaymentProvider {
  id?: PaymentProviderIdEnum;
  /** Representation of an Image */
  icon?: Image;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  latestDeliveryDate?: string;
}

export interface PaymentRequest {
  paymentProviderUrl?: string;
  params?: Record<string, string>;
}

export interface PayrexxWebhookTransactionDto {
  /** @format int32 */
  id?: number;
  psp?: string;
  uuid?: string;
  status?: string;
  referenceId?: string;
}

export interface PayrexxWebhookTransactionsDto {
  transaction?: PayrexxWebhookTransactionDto;
}

/** Order Rule object. */
export interface Permission {
  /**
   * Permission status: Active - after valid from date and published flag=true Inactive - before Valid from and published flag=true Expired - after ValidTo date and published flag=true Unpublished - published flag=false
   * @example "true"
   */
  status?: PermissionStatusEnum;
  /**
   * Name of the permission
   * @example "My permission"
   */
  name?: string;
  /**
   * Description of the permission
   * @example "This is my permission"
   */
  description?: string;
  /**
   * Boolean flag of whether Permission is published
   * @example true
   */
  published?: boolean;
  /** Representation of a Principal webservice DTO used for defining User data types */
  owner?: Principal;
  /** Simple customer data representation with basic information */
  contactPerson?: SimpleCustomer;
  /** Request body fields required and optional to operate on Permission Type data. Typical permission types are: B2BBudgetExceededPermission, B2BOrderThresholdTimespanPermission and B2BOrderThresholdPermission */
  permissionType?: PermissionType;
  /**
   * Code of the Permission
   * @example "Rustic_10K_USD_MONTH"
   */
  code: string;
  /** Representation of a Currency */
  currency?: Currency;
  /**
   * Period range of the Permission, used for type B2BOrderThresholdTimespanPermission
   * @example "MONTH"
   */
  periodRange?: PermissionPeriodRangeEnum;
  /** Message displayed to the user */
  message?: string;
  /**
   * Date the permission is active from
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  activeFrom?: string;
  /**
   * Date the permission is active to
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  activeTo?: string;
  /** Resolution type of permission */
  resolutionType?: PermissionResolutionTypeEnum;
  /** Order summary scope used to calculate the total amount used */
  orderSummaryScope?: Principal[];
  /** Assortment scope: order card or accounting code */
  assortmentScope?: PermissionAssortmentScope;
  /** Specifies if the permission is per user */
  perUser?: boolean;
  /** Specifies if the permission is per soldTo */
  perSoldTo?: boolean;
  /** Specifies if the permission is per shipTo */
  perShipTo?: boolean;
  /** Specifies connection type of the permission */
  connectionType?: PermissionConnectionTypeEnum;
  /**
   * Number of connections based on the connection type
   * @format int32
   */
  numberOfConnections?: number;
  /** Organizational Unit object. */
  orgUnit?: B2BUnit;
  /**
   * Threshold value of the Permission, used for type B2BOrderThresholdPermission and B2BOrderThresholdTimespanPermission
   * @format double
   * @example 10000
   */
  threshold?: number;
  /**
   * Total used of the Permission, used for type B2BOrderThresholdTimespanPermission
   * @example 1000
   */
  totalUsed?: number;
  /**
   * Boolean flag of whether the user is selected
   * @example true
   */
  selected?: boolean;
  /**
   * Creation time of the permission
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  creationTime?: string;
}

/** Assortment scope: order card or accounting code */
export interface PermissionAssortmentScope {
  /** A very generic data holder with minimal information */
  orderCard?: SimpleItem;
  /** A very generic data holder with minimal information */
  accountingCode?: SimpleItem;
}

/** Representation of a Permission List */
export interface PermissionList {
  /** List of Permissions */
  orderPermissions: Permission[];
  /** List of sorts */
  sorts: Sort[];
  /** Representation of a search results pagination */
  pagination: DeprecatedPagination;
}

/** Details of a permission result related to an order */
export interface PermissionResult {
  /** Name of the approver responsible for this permission result. */
  approverName?: string;
  /**
   * Any comments the approver added to the approval item.
   * @example "The order is rejected due to insufficient budget"
   */
  approverNotes?: string;
  /** Request body fields required and optional to operate on Permission Type data. Typical permission types are: B2BBudgetExceededPermission, B2BOrderThresholdTimespanPermission and B2BOrderThresholdPermission */
  permissionType?: PermissionType;
  /**
   * Status of the permission.
   * @example "Pending approval"
   */
  statusDisplay?: string;
  /**
   * Permission resolution status, REJECTED - block, PENDING_APPROVAL - means that user can make the purchase, OPEN - requires approval before it can be bought
   * @example "REJECTED"
   */
  status?: string;
  /** Order Rule object. */
  permissionDetails?: Permission;
  /**
   * Amount used for already placed orders
   * @example 100
   */
  amountUsed?: number;
  /**
   * Amount that will be spent if the purchase is made
   * @example 100
   */
  applicableAmountFromOrder?: number;
}

/** Request body fields required and optional to operate on Permission Type data. Typical permission types are: B2BBudgetExceededPermission, B2BOrderThresholdTimespanPermission and B2BOrderThresholdPermission */
export interface PermissionType {
  /**
   * Code of the Permission Type
   * @example "B2BOrderThresholdTimespanPermission"
   */
  code: string;
  /**
   * Name of the Permission Type
   * @example "Allowed Order Threshold (per timespan)"
   */
  name?: string;
}

/** Representation of a Permission Type list */
export interface PermissionTypeList {
  /** List of Permission Types */
  orderPermissionTypes: PermissionType[];
}

/** Representation of a Pickup Order Entry Group */
export interface PickupOrderEntryGroup {
  /** Representation of a Price */
  totalPriceWithTax?: Price;
  /** List of order entries */
  entries?: OrderEntry[];
  /**
   * Quantity of order entries in a group
   * @format int64
   */
  quantity?: number;
  /** Representation of a Point of service */
  deliveryPointOfService?: PointOfService;
  /**
   * Distance calculated to pickup place
   * @format double
   */
  distance?: number;
}

/** Representation of a Point of service */
export interface PointOfService {
  /**
   * Name of the point of service
   * @example "Misato"
   */
  name?: string;
  /** Display name of the point of service */
  displayName?: string;
  /** Url address of the point of service */
  url?: string;
  /** Description of the point of service */
  description?: string;
  /** Representation of an Opening schedule */
  openingHours?: OpeningSchedule;
  /** Store content of given point of service */
  storeContent?: string;
  /** List of features for a given point of service */
  features?: Record<string, string>;
  /** Representation of a GeoPoint */
  geoPoint?: GeoPoint;
  /** Distance to the point of service as text value */
  formattedDistance?: string;
  /**
   * Distance to the point of service as number value
   * @format double
   */
  distanceKm?: number;
  /** Representation of an Image */
  mapIcon?: Image;
  /** Address object */
  address?: Address;
  /** Collection of images associated with a point of service */
  storeImages?: Image[];
  /** Instructions used for picking up an order in store */
  pickUpInStoreInstructions?: string;
}

/** Representation of a Point of Service List */
export interface PointOfServiceList {
  /** List of points of service */
  pointOfServices?: PointOfService[];
}

/** Representation of a Point Of Service Stock */
export interface PointOfServiceStock {
  /**
   * Name of the point of service
   * @example "Misato"
   */
  name?: string;
  /** Display name of the point of service */
  displayName?: string;
  /** Url address of the point of service */
  url?: string;
  /** Description of the point of service */
  description?: string;
  /** Representation of an Opening schedule */
  openingHours?: OpeningSchedule;
  /** Store content of given point of service */
  storeContent?: string;
  /** List of features for a given point of service */
  features?: Record<string, string>;
  /** Representation of a GeoPoint */
  geoPoint?: GeoPoint;
  /** Distance to the point of service as text value */
  formattedDistance?: string;
  /**
   * Distance to the point of service as number value
   * @format double
   */
  distanceKm?: number;
  /** Representation of an Image */
  mapIcon?: Image;
  /** Address object */
  address?: Address;
  /** Collection of images associated with a point of service */
  storeImages?: Image[];
  /** Instructions used for picking up an order in store */
  pickUpInStoreInstructions?: string;
  /** Representation of a Stock */
  stockInfo?: Stock;
}

/** Pre-printed label object */
export interface PrePrintedLabel {
  part?: string;
  code?: string;
  label?: string;
  /** @format int64 */
  innerPackageQuantity?: number;
  materialNumber?: string;
}

export interface PresentationAttribute {
  code?: string;
  name?: string;
  type?: string;
  value?: string;
  rawValue?: string;
  unitString?: string;
}

/** Representation of a Price */
export interface Price {
  /**
   * Currency iso format
   * @example "USD"
   */
  currencyIso?: string;
  /** Value of price in BigDecimal format */
  value?: number;
  /** Type of the price */
  priceType?: PricePriceTypeEnum;
  /** Value of price formatted */
  formattedValue?: string;
  /**
   * Minimum quantity of the price value
   * @format int64
   */
  minQuantity?: number;
  /**
   * Maximum quantity of the price value
   * @format int64
   */
  maxQuantity?: number;
  taxRate?: number;
}

export interface PriceFile {
  code?: string;
  name?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  requested?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  delivered?: string;
  priceDate?: string;
  size?: string;
  customerId?: string;
  formatDisplayName?: string;
  downloadUrl?: string;
  status?: PriceFileStatusEnum;
  outputMimeType?: string;
  unzippedDelivery?: boolean;
  erpCustomerId?: string;
  autoGenerated?: boolean;
  statusMessageCode?: string;
  /** @format int32 */
  articleCount?: number;
  categories?: Category[];
  enableRemoval?: boolean;
}

export interface PriceFileListPage {
  /** Result list */
  results?: PriceFile[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

/** Price file request body */
export interface PriceFileRequest {
  erpCustomerId?: string;
  format?: string;
  templateId?: string;
  selectedCategories?: Category[];
  standardPrice?: boolean;
  onlyTenderArticles?: boolean;
  customerUnique?: boolean;
  onlyArticlesBoughtLastTwelveMonths?: boolean;
  includeOutgoingArticles?: boolean;
  includeYsfeFee?: boolean;
  includeYfesFee?: boolean;
  priceDate?: string;
}

/** Price file request template body */
export interface PriceFileRequestTemplate {
  erpCustomerId?: string;
  format?: string;
  templateId?: string;
  selectedCategories?: Category[];
  standardPrice?: boolean;
  onlyTenderArticles?: boolean;
  customerUnique?: boolean;
  onlyArticlesBoughtLastTwelveMonths?: boolean;
  includeOutgoingArticles?: boolean;
  includeYsfeFee?: boolean;
  includeYfesFee?: boolean;
  priceDate?: string;
  code?: string;
  name?: string;
}

export interface PriceFileRequestTemplateList {
  templates?: PriceFileRequestTemplate[];
}

/** Result list */
export interface PriceFileTemplate {
  code?: string;
  name?: string;
  supportsStdPriceToggling?: boolean;
}

export interface PriceFileTemplatePage {
  /** Result list */
  results?: PriceFileTemplate[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

/** Representation of a Price Range */
export interface PriceRange {
  /** Representation of a Price */
  maxPrice?: Price;
  /** Representation of a Price */
  minPrice?: Price;
}

export interface PriceRequest {
  articleNumber?: string;
  unit?: string;
  /** @format int32 */
  quantity?: number;
}

/** Price request list */
export interface PriceRequestList {
  priceRequests?: PriceRequest[];
}

export interface PriceResponse {
  id?: string;
  articleNumber?: string;
  unit?: string;
  /** @format int64 */
  quantity?: number;
  price?: string;
  formattedPrice?: string;
  availability?: string;
  scaleDiscountString?: string;
  contractedPrice?: string;
}

export interface PriceResponseList {
  priceResponses?: PriceResponse[];
}

/** Representation of a Principal webservice DTO used for defining User data types */
export interface Principal {
  /** Unique user identifier */
  uid?: string;
  /** Name of the user */
  name?: string;
}

export interface PrincipalConfiguration {
  /** The name (code) of the PrincipalConfiguration */
  code?: string;
  /** The localized name of the PrincipalConfiguration */
  clientDisplayName?: string;
  /** Description of the PrincipalConfiguration */
  clientDescription?: string;
  /**
   * The value type of the PrincipalConfiguration. Available types: 'BOOL', 'NUMBER', 'STRING'
   * @example "BOOL"
   */
  type?: string;
  booleanValue?: boolean;
  /** @format int32 */
  intValue?: number;
  stringValue?: string;
}

export interface PrincipalConfigurationGroup {
  code?: string;
  clientDisplayName?: string;
  clientDescription?: string;
  configurations?: PrincipalConfiguration[];
}

export interface PrincipalConfigurationList {
  configurations?: PrincipalConfiguration[];
}

/** Object storing configuration name and value */
export interface PrincipalConfigurationValue {
  name?: string;
  value?: string;
}

export interface PrincipalSharedCartTemplateFlags {
  /** Representation of a Principal webservice DTO used for defining User data types */
  principal?: Principal;
  reader?: boolean;
  writer?: boolean;
}

export interface PrincipalSharedCartTemplateFlagsPage {
  /** Result list */
  results?: PrincipalSharedCartTemplateFlags[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

/** Principal data representation with user roles */
export interface PrincipalWithRoles {
  /** Unique user identifier */
  uid?: string;
  /** Name of the user */
  name?: string;
  roles?: string[];
}

export interface ProcuratorEnvironmentalChoice {
  /** Representation of an Image */
  image?: Image;
  /** Representation of an Image */
  thumbnail?: Image;
}

/** Representation of a Product */
export interface Product {
  /** Code of the product */
  code?: string;
  /** Name of the product */
  name?: string;
  /** Url address of the product */
  url?: string;
  /** Description of the product */
  description?: string;
  /** Flag defining if product is purchasable */
  purchasable?: boolean;
  /** Representation of a Stock */
  stock?: Stock;
  /** List of future stocks */
  futureStocks?: FutureStock[];
  /** Flag defining if product is available for pickup */
  availableForPickup?: boolean;
  /**
   * Rating number of average value
   * @format double
   */
  averageRating?: number;
  /**
   * Number of reviews associated with the product
   * @format int32
   */
  numberOfReviews?: number;
  /** Product summary */
  summary?: string;
  /** Data of product manufacturer */
  manufacturer?: string;
  /** Variant type of the product */
  variantType?: string;
  /** Representation of a Price */
  price?: Price;
  /** Information about base product */
  baseProduct?: string;
  /** List of images linked to product */
  images?: Image[];
  /** List of categories product belongs to */
  categories?: Category[];
  /** List of reviews associated with the product */
  reviews?: Review[];
  /** List of classifications related to the product */
  classifications?: Classification[];
  /** List of potential promotions related to the product */
  potentialPromotions?: Promotion[];
  /** List of variant options related to the product */
  variantOptions?: VariantOption[];
  /** List of base options related to the product */
  baseOptions?: BaseOption[];
  /** Flag stating if volume price should be displayed */
  volumePricesFlag?: boolean;
  /** List of volume prices */
  volumePrices?: Price[];
  /** List of product references */
  productReferences?: ProductReference[];
  /** List of variant matrixes associated with the product */
  variantMatrix?: VariantMatrixElement[];
  /** Representation of a Price Range */
  priceRange?: PriceRange;
  /** Flag stating if product is multidimensional */
  multidimensional?: boolean;
  /** Configurator type related to the product */
  configuratorType?: string;
  /** Flag stating if product is configurable */
  configurable?: boolean;
  /**
   * Tags associated with the product
   * @uniqueItems true
   */
  tags?: string[];
  /**
   * Flag specifies whether product can be added to cart. When addToCartDisabled=true, the product is not allowed to be added into cart and the reason is explained through sapAddToCartDisabledMessage; when addToCartDisabled=false, the product is allowed to be added into cart.
   * @example true
   */
  sapAddToCartDisabled?: boolean;
  /**
   * Message shows why product can not be added to cart.
   * @example "'EF-S 60mm f/2.8 USM Macro' is not available with 'EOS450D + 18-55 IS Kit'"
   */
  sapAddToCartDisabledMessage?: string;
  articles?: Article[];
  longDescription?: string;
  extraInfo?: ProductExtraInfo;
  /** Representation of an Image */
  datasheet?: Image;
  /** Representation of an Image */
  thumbnail?: Image;
  displayCategoryCode?: string;
  displayNewProductOverlay?: boolean;
  promotional?: boolean;
  referential?: boolean;
  articlePickerAttributes?: ClassificationAttributeData[];
  relatedProducts?: ProductRelation[];
  uniqueSellingPoints?: UniqueSellingPoints;
  productDownloadables?: ProductDownloadable[];
  brand?: ProductBrand;
  sample?: SampleInfo;
  outgoing?: OutgoingInfo;
  discontinued?: boolean;
  priceCampaign?: boolean;
  seo?: SeoMeta;
  tabContainerIds?: string[];
  models?: string[];
}

export interface ProductBrand {
  code?: string;
  name?: string;
  pageUrl?: string;
  /** Representation of an Image */
  image?: Image;
}

export interface ProductBrandsWrapper {
  brands?: ProductBrand[];
}

export interface ProductCertificateGroup {
  key?: string;
  description?: string;
  certificates?: Certification[];
}

export interface ProductCertification {
  /** @format int32 */
  numberOfCerts?: number;
  groups?: ProductCertificateGroup[];
}

export interface ProductDocument {
  code?: string;
  title?: string;
  type?: string;
  types?: string[];
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  authoritativeDate?: string;
  downloadUrl?: string;
  entities?: ProductRefs;
}

export interface ProductDocumentsWrapper {
  documents?: ProductDocument[];
}

export interface ProductDownloadable {
  linkText?: string;
  downloadUrl?: string;
  hasMedia?: boolean;
}

/** Representation of a Product Express Update Element */
export interface ProductExpressUpdateElement {
  /** Code of product express update element */
  code?: string;
  /** Catalog identifier */
  catalogId?: string;
  /** Catalog version */
  catalogVersion?: string;
}

/** Representation of a Product Express Update Element List */
export interface ProductExpressUpdateElementList {
  /** List of product express update element */
  productExpressUpdateElements?: ProductExpressUpdateElement[];
}

export interface ProductExtraInfo {
  assortment?: Assortment;
  applications?: string[];
  productCertifications?: ProductCertification;
  technicalCapabilities?: Record<string, string>;
  printingTechnologies?: ProductCertification;
  washingInstructions?: ProductCertification;
  extraAttributes?: Record<string, string>;
  technicalAttributes?: AttributeTable;
  materialComposition?: ProductCertification;
  topics?: string[];
}

/** Representation of a Product Future Stocks */
export interface ProductFutureStocks {
  /**
   * Product identifier
   * @example "3318057"
   */
  productCode?: string;
  /** List of future stocks */
  futureStocks?: FutureStock[];
}

/** Representation of a Product Future Stocks List */
export interface ProductFutureStocksList {
  /** List of product future stocks */
  productFutureStocks?: ProductFutureStocks[];
}

/** Inquiry form */
export interface ProductInquiryForm {
  productCode: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  contactType: string;
  anyContactDate?: boolean;
  anyContactTimeSlot?: boolean;
  model?: string;
  countryPhoneCode?: string;
  additionalInfo?: string;
  formattedContactDate?: string;
  contactTimeSlots?: string[];
}

/** Representation of a Product List */
export interface ProductList {
  /** List of products */
  products?: Product[];
  /** Catalog of product list */
  catalog?: string;
  /** Version of product list */
  version?: string;
  /**
   * Total product count
   * @format int32
   */
  totalProductCount?: number;
  /**
   * Total page count
   * @format int32
   */
  totalPageCount?: number;
  /**
   * Number of current page
   * @format int32
   */
  currentPage?: number;
}

/** Representation of a Product Reference */
export interface ProductReference {
  /** Reference type */
  referenceType?: string;
  /** Reference description */
  description?: string;
  /**
   * Reference quantity
   * @format int32
   */
  quantity?: number;
  /** Representation of a Product */
  target?: Product;
  /** Flag stating if product reference is preselected */
  preselected?: boolean;
}

/** Representation of a Product Reference List */
export interface ProductReferenceList {
  /** List of product references */
  references?: ProductReference[];
}

export interface ProductRefs {
  articleRefs?: string[];
  productRefs?: string[];
}

export interface ProductRelation {
  target?: string;
  /**
   * Catalog type of target. One of 'Article' or 'PyProduct'
   * @example "Article"
   */
  targetType?: string;
  type?: string;
}

/** Representation of a Product Search Page */
export interface ProductSearchPage {
  /** Free text search */
  freeTextSearch?: string;
  /** Code of category */
  categoryCode?: string;
  /** Redirect url address keyword */
  keywordRedirectUrl?: string;
  /** Representation of a Spell Checker Suggestion */
  spellingSuggestion?: SpellingSuggestion;
  /** List of products */
  products?: Product[];
  /** List of sorts */
  sorts?: Sort[];
  /** Representation of a search results pagination */
  pagination?: DeprecatedPagination;
  /** Representation of a Search State */
  currentQuery?: SearchState;
  /** List of breadcrumbs info */
  breadcrumbs?: Breadcrumb[];
  /** List of facets */
  facets?: Facet[];
}

/** Representation of a Promotion */
export interface Promotion {
  /** Code of the promotion */
  code?: string;
  /** Promotion title */
  title?: string;
  /** Type of the promotion */
  promotionType?: string;
  /**
   * The initial date of the promotion
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  startDate?: string;
  /**
   * Last date of validity of the promotion
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  endDate?: string;
  /** Description of the promotion */
  description?: string;
  /** Message about promotion which is displayed when planning potential promotion. This field has higher priority over promotion description */
  couldFireMessages?: string[];
  /** Message fired while the promotion is active. This is info how much you will get when applying the promotion */
  firedMessages?: string[];
  /** Representation of an Image */
  productBanner?: Image;
  /** Boolean flag if promotion is enabled */
  enabled?: boolean;
  /**
   * Priority index as numeric value of the promotion. Higher number means higher priority
   * @format int32
   */
  priority?: number;
  /** Group of the promotion */
  promotionGroup?: string;
  /** List of promotion restrictions */
  restrictions?: PromotionRestriction[];
}

/** Representation of a Promotion list */
export interface PromotionList {
  /** List of promotions */
  promotions?: Promotion[];
}

/** Representation of a Promotion order entry consumed */
export interface PromotionOrderEntryConsumed {
  /** Order entry code */
  code?: string;
  /**
   * Adjusted unit price for promotion order entry
   * @format double
   */
  adjustedUnitPrice?: number;
  /**
   * Order entry number
   * @format int32
   */
  orderEntryNumber?: number;
  /**
   * Quantity of promotion order entry
   * @format int64
   */
  quantity?: number;
}

/** Representation of a Promotion Restriction */
export interface PromotionRestriction {
  /** Type of the promotion restriction */
  restrictionType?: string;
  /** Description of the promotion restriction */
  description?: string;
}

/** Representation of a Promotion result */
export interface PromotionResult {
  /** Description of promotion result */
  description?: string;
  /** Representation of a Promotion */
  promotion?: Promotion;
  /** List of promotion order entries consumed */
  consumedEntries?: PromotionOrderEntryConsumed[];
}

/** Representation of a Promotion result list */
export interface PromotionResultList {
  /** List of promotion results */
  promotions?: PromotionResult[];
}

export interface PunchOutData {
  properties?: Record<string, string>;
}

export interface PunchOutPayload {
  punchOut?: boolean;
  /** Payload content in a key-value map which can contain field names to be used in a form along with their values. Or if used in a server post just a single key with the payload in its value. For example. */
  payloadContent?: Record<string, string>;
  /** Could be for example 'text/xml' or 'application/x-www-form-urlencoded' In the case of OCI for example, this should be the second form, while cXML is just text/xml. */
  contentType?: string;
  /** If set, will be used over UTF-8. OCI standard gives consumer possibility of deciding return charset. */
  outputCharset?: string;
  destinationAddress?: string;
  /** If true, then a view will likely be rendered and the payload is delivered by the client browser. If false, then the server will attempt to perform the POST. */
  clientBrowserPost?: boolean;
}

export interface PyAddress {
  id?: string;
  countryIso?: string;
  name1?: string;
  name2?: string;
  name3?: string;
  name4?: string;
  street?: string;
  city?: string;
  postalCode?: string;
  phone?: string;
  /** This field is for when a customer needs to set a countryPhoneCode, that differs from the countryPhoneCode found in country. Maps to phone2 in ShipToData */
  countryPhoneCode?: string;
  computedDescription?: string;
  /** Response body fields which will be returned while fetching the list of countries. The DTO is in XML or .json format */
  country?: Country;
}

export interface PyOrderLineParameters {
  sapOrderLineNumber?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  requestedDeliveryDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  agreedDeliveryDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  fullDeliveryDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  sapEarliestDate?: string;
  /** @format int64 */
  confirmedQuantity?: number;
  linePriceIncludingShareOfOrderFees?: number;
  /** Representation of a Price */
  linePriceDataIncludingShareOfOrderFees?: Price;
  /** A single comparative price for an order entry. This OrderEntryComparativePriceWsDTO is distinct from the (unfortunately named) ComparativePriceEntryWsDto. ComparativePriceEntryWsDTO is for multiple comparisons in Stock/Price info. Perhaps they could be merged. */
  comparativePriceData?: OrderEntryComparativePrice;
  itemCategory?: string;
  warehouse?: Warehouse;
  note?: string;
  statisticsCode?: string;
  indent?: boolean;
  palletFlags?: PalletFlags;
  cutting?: Cutting;
  reelCutting?: ReelCutting;
  reaming?: Reaming;
  explodedOrderLines?: ExplodedOrderLine[];
  splitOrderEntry?: boolean;
  /** Representation of a Price */
  totalPriceWithTax?: Price;
  /** Representation of a Price */
  totalTax?: Price;
  /** Pre-printed label object */
  prePrintedLabel?: PrePrintedLabel;
  prePrintedMaterialNumber?: string;
  fullPalletNote?: boolean;
  /** The accounting code, consisting of its code and description */
  accountingCode?: AccountingCode;
}

export interface PyOrderParameters {
  sapOrderNumber?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  requestedDeliveryDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  agreedDeliveryDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  completeDeliveryDate?: string;
  shippingCondition?: string;
  deliveryNote?: string;
  message?: string;
  goodsMarking?: string;
  customerReference?: string;
  phoneNumber?: string;
  reinvoicing?: Reinvoicing;
  invoiceNote?: string;
  orderNote?: string;
  costPlace?: string;
  paymentTerms?: string;
  smallOrderFeeHeadsUpMessage?: string;
  /** Custom ship-to */
  shipTo?: ShipTo;
  orderMergeProposals?: OrderMergeProposal[];
  serviceOptions?: ServiceOption[];
  shippingOptions?: ShippingOption[];
  carryBeyondLoadingBayOption?: boolean;
  timeCode?: string;
  sapTimeWindowTimeCode?: TimeWindowDeliveryOption;
}

export interface PyProductRef {
  code?: string;
}

export interface PyProductRefList {
  productRefs?: PyProductRef[];
}

export interface PyProductReference {
  hasConsistsOfReferences?: boolean;
}

export interface Reaming {
  reamed?: boolean;
}

export interface Recommendation {
  ticket?: string;
  type?: string;
  ref?: string;
  attributes?: Record<string, string[]>;
}

export interface ReelCutting {
  description?: string;
  widths?: number[];
  remainder?: boolean;
  /** @format int64 */
  resultingLength?: number;
  resultingUnit?: Unit;
  /** Representation of a Price */
  cuttingPrice?: Price;
  /** Representation of a Price */
  packagingPrice?: Price;
}

/** Reel cutting request */
export interface ReelCuttingRequest {
  daughterWidths: number[];
  remainder: boolean;
  /** @format int32 */
  daughterLength: number;
}

/** Just contains a ref/code */
export interface Ref {
  ref: string;
}

export interface RefsSearchPage {
  /** Result list */
  results?: Ref[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

/** Response body fields which will be returned while fetching the list of country's regions. */
export interface Region {
  /**
   * Country and Region code in iso format
   * @example "US-NY"
   */
  isocode?: string;
  /**
   * Region code in short iso form
   * @example "NY"
   */
  isocodeShort?: string;
  /**
   * Country code in iso format
   * @example "US"
   */
  countryIso?: string;
  /**
   * Name of the region
   * @example "New York"
   */
  name?: string;
}

/** List of Regions */
export interface RegionList {
  /** This is the list of Region fields that should be returned in the response body */
  regions?: Region[];
}

export interface Reinvoicing {
  customerNumber?: string;
  contractNumber?: string;
  referenceNumber1?: string;
  referenceNumber2?: string;
}

/** Reinvoicing request */
export interface ReinvoicingRequest {
  customerNumber?: string;
  contractNumber?: string;
  referenceNumber1?: string;
  referenceNumber2?: string;
}

/** Representation of a request of replacing an existing user's login id. */
export interface ReplaceLoginIdInput {
  /**
   * New login id that is a unique string to identify a user.
   * @example "mark.rivers@pronto-hw.com"
   */
  newLoginId: string;
  /**
   * User password
   * @example "It'sMyPwd!0"
   */
  password: string;
}

/** Representation of a request of replacing an existing user's password */
export interface ReplacePasswordInput {
  /**
   * Current password of the customer.
   * @example "It'sMyPwd!0"
   */
  oldPassword: string;
  /**
   * New password of the customer.
   * @example "It'sMyPwd!1"
   */
  newPassword: string;
}

/** Request body parameter that contains details such as token and new password */
export interface ResetPassword {
  /** token value which will be generated as unique string that will be sent with email to allow user for completing reset-password operation */
  token: string;
  /** new password string which is required to complete process of resetting password */
  newPassword: string;
}

export interface ResolveOption {
  /** @format int32 */
  entryNumber?: number;
  action?: ResolveOptionActionEnum;
  text?: string;
  alternativeArticles?: OrderEntry[];
  properAlternativeArticles?: boolean;
}

export interface ReturnReason {
  reasonCode?: ReturnReasonReasonCodeEnum;
  reasonText?: string;
  fileUploadMandatory?: boolean;
}

export interface ReturnReasonList {
  returnReasons?: ReturnReason[];
}

/** Representation of a return request for an order */
export interface ReturnRequest {
  /**
   * Boolean flag for whether the return request is cancellable
   * @example true
   */
  cancellable?: boolean;
  /**
   * Return request code
   * @example "00000001"
   */
  code?: string;
  /**
   * Date of the return request creation
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  creationTime?: string;
  /** Representation of a Price */
  deliveryCost?: Price;
  /** Representation of an Order */
  order?: Order;
  /**
   * Boolean flag for whether there is a delivery cost for refund
   * @example false
   */
  refundDeliveryCost?: boolean;
  /** Entries of the return request which contains information about the returned product */
  returnEntries?: ReturnRequestEntry[];
  /** URL of the return label */
  returnLabelDownloadUrl?: string;
  /**
   * Return merchandise authorization number
   * @example "00000001"
   */
  rma?: string;
  /** Status of return request */
  status?: string;
  /** Representation of a Price */
  subTotal?: Price;
  /** Representation of a Price */
  totalPrice?: Price;
}

/** Representation of a return request entry which contains information about the returned product */
export interface ReturnRequestEntry {
  /**
   * Request body parameter that contains details such as the quantity of product (quantity), and the pickup store name (deliveryPointOfService.name)
   *
   * The DTO is in XML or .json format.
   */
  orderEntry?: OrderEntry;
  /**
   * Quantity which is expected to be returned for this return request entry
   * @format int64
   * @example 5
   */
  expectedQuantity?: number;
  /** Representation of a Price */
  refundAmount?: Price;
}

/** Representation of a return request entry input for an order */
export interface ReturnRequestEntryInput {
  /**
   * Order entry number of the returned product
   * @format int32
   * @example 1
   */
  orderEntryNumber: number;
  /**
   * Quantity of the product which belongs to the order entry and is requested to be returned
   * @format int64
   * @example 5
   */
  quantity: number;
}

/** Return request input list for the current order. */
export interface ReturnRequestEntryInputList {
  /**
   * Code of the order which return request is related to
   * @example "00000001"
   */
  orderCode: string;
  /** Return request entry inputs which contain information about the order entries which are requested to be returned */
  returnRequestEntryInputs: ReturnRequestEntryInput[];
}

/** Representation of an Order Return Request List */
export interface ReturnRequestList {
  /** List of order return requests */
  returnRequests?: ReturnRequest[];
  /** List of sorts */
  sorts?: Sort[];
  /** Representation of a search results pagination */
  pagination?: DeprecatedPagination;
}

/** Return request modification object. */
export interface ReturnRequestModification {
  /** Status of the return request */
  status?: ReturnRequestModificationStatusEnum;
}

/** Object contains review details like : rating, alias, headline, comment. */
export interface Review {
  /** Identifier of review */
  id?: string;
  /** Review headline */
  headline?: string;
  /** Review comment */
  comment?: string;
  /**
   * Review rating value
   * @format double
   */
  rating?: number;
  /**
   * Date of the review
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  date?: string;
  /** Alias name for the review */
  alias?: string;
  /** User's object. */
  principal?: User;
}

/** Representation of a Review List */
export interface ReviewList {
  /** List of reviews */
  reviews?: Review[];
}

/** Representation of an Invoice */
export interface SAPInvoice {
  /**
   * Invoice Id
   * @example "9560887"
   */
  invoiceId?: string;
  /**
   * Invoice creation date
   * @format date-time
   * @example "2020-09-16T04:55:09.505Z"
   */
  createdAt?: string;
  /** Representation of a Price */
  totalAmount?: Price;
  /** Representation of a Price */
  netAmount?: Price;
  /**
   * External system identifier where the invoice resides.
   * @example "S4SALES"
   */
  externalSystemId?: string;
}

/** Representation of an Invoice List */
export interface SAPInvoiceList {
  /** list of invoice */
  invoices?: SAPInvoice[];
  /** sorting information */
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

export interface SalesOrg {
  code?: string;
  name?: string;
  sapClient?: SapClient;
}

export interface SampleInfo {
  a4SampleAvailable?: boolean;
  a4SampleGrammageList?: number[];
  originalSampleAvailable?: boolean;
}

export interface SapClient {
  code?: string;
  name?: string;
}

export interface SapOrderCondition {
  activeCondition?: boolean;
  conditionClass?: string;
  conditionCategory?: string;
  groupCondition?: boolean;
  name?: string;
  tooltipText?: string;
  rate?: number;
  rateCurrency?: string;
  salesDocumentCurrency?: string;
  type?: string;
  value?: number;
  /** Representation of a Price */
  priceData?: Price;
}

/** Used to return Sap Price Campaign data */
export interface SapPriceCampaign {
  /** Sap Price Campaign code */
  code?: string;
  /** Sap Price Campaign description */
  description?: string;
  /** Sap Price Campaign link */
  link?: string;
  /** Sap Price Campaign is fallback or not */
  fallbackCampaign?: boolean;
}

/** Entries used to build a price campaign banner */
export interface SapPriceCampaignBannerEntry {
  /** Text */
  text?: string;
  /** Link */
  link?: string;
  /** Representation of an Image */
  image?: Image;
}

/** Used to return Sap Price Campaign details data */
export interface SapPriceCampaignDetails {
  /** Sap Price Campaign code */
  code?: string;
  /** Sap Price Campaign description */
  description?: string;
  /** Sap Price Campaign link */
  link?: string;
  /** Sap Price Campaign is fallback or not */
  fallbackCampaign?: boolean;
  /** Sap Price Campaign header title */
  headerTitle?: string;
  /** Sap Price Campaign header text */
  headerText?: string;
  /** Representation of an Image */
  headerImage?: Image;
  /** Entries used to build a price campaign banner */
  entries?: SapPriceCampaignBannerEntry[];
}

/** Sap Price Campaigns data */
export interface SapPriceCampaignList {
  /** Link to all campaigns */
  allCampaignsLink?: string;
  /** List of price campaigns */
  entries?: SapPriceCampaign[];
}

/** Representation of a Save Cart Result */
export interface SaveCartResult {
  /** Representation of a Cart */
  savedCartData?: Cart;
}

/** Representation of a Search Query */
export interface SearchQuery {
  /** Value of search query */
  value?: string;
}

export interface SearchScoreExplanation {
  factors?: SearchScoreFactor[];
  raw?: string;
  totalScore?: string;
  calculatedTotal?: string;
  docBoost?: string;
}

export interface SearchScoreFactor {
  /** @format double */
  rawWeight?: number;
  weight?: string;
  field?: string;
  word?: string;
  lineBoost?: string;
}

/** Representation of a Search State */
export interface SearchState {
  /** Url address of search state */
  url?: string;
  /** Representation of a Search Query */
  query?: SearchQuery;
}

export interface SearchTermFacetMappingHint {
  indexedProperty?: string;
  exampleValue?: string;
  hideIfMatchingRegEx?: string;
  hintFormat?: string;
  matchingRegEx?: string;
}

export interface SeoMeta {
  canonicalUrl?: string;
  altHrefLangs?: AltHrefLang[];
}

export interface ServiceOption {
  type?: ServiceOptionTypeEnum;
  selected?: boolean;
  /** Representation of a Price */
  cost?: Price;
  timeWindows?: TimeWindowDeliveryOptions;
}

/** Custom ship-to */
export interface ShipTo {
  id?: string;
  countryIso?: string;
  name1?: string;
  name2?: string;
  name3?: string;
  name4?: string;
  street?: string;
  city?: string;
  postalCode?: string;
  phone?: string;
  /** This field is for when a customer needs to set a countryPhoneCode, that differs from the countryPhoneCode found in country. Maps to phone2 in ShipToData */
  countryPhoneCode?: string;
  computedDescription?: string;
  /** Response body fields which will be returned while fetching the list of countries. The DTO is in XML or .json format */
  country?: Country;
  custom?: boolean;
  hasDeliveryFee?: boolean;
  transportZone?: string;
  unloadingPoints?: UnloadingPoint[];
}

export interface ShipToList {
  shipTos?: ShipTo[];
}

export interface ShipToPage {
  /** Result list */
  results?: ShipTo[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

export interface Shipping {
  /** Custom ship-to */
  currentShipTo?: ShipTo;
  shipTos?: ShipTo[];
  /** @format int64 */
  numberOfDaysToEarliestDelivery?: number;
  goodsReceivingDaysString?: string;
  holidaysString?: string;
  completeDeliveryRequired?: boolean;
  completeDeliveryChosen?: boolean;
  shippingWithLateTour?: boolean;
}

export interface ShippingOption {
  type?: ShippingOptionTypeEnum;
  selected?: boolean;
  deliveryDates?: string[];
  deliveryDatesUpdateable?: boolean;
  /** Representation of a Price */
  cost?: Price;
  properties?: Record<string, object>;
  deliveryOptions?: TimeWindowDeliveryOptions;
}

/** Simple customer data representation with basic information */
export interface SimpleCustomer {
  /** Unique user identifier */
  uid?: string;
  /** Name of the user */
  name?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  customerId?: string;
}

export interface SimpleCustomerPage {
  /** Result list */
  results?: SimpleCustomer[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

/** A very generic data holder with minimal information */
export interface SimpleItem {
  /** Type of the item */
  type?: string;
  /** Identifier that can be used to find the item in the DB */
  identifier?: string;
  /** Name - display name of the item */
  name?: string;
}

export interface SiteConfig {
  orderReturnEnabled?: boolean;
}

export interface SiteFamilyConfig {
  onlineChatConfig?: OnlineChatConfig;
}

export interface SoldTo {
  /**
   * Boolean flag of whether Organizational Unit is active
   * @example true
   */
  active?: boolean;
  /**
   * Identifier of the organizational unit
   * @example "Pronto"
   */
  uid: string;
  /**
   * Name of the organizational unit
   * @example "Pronto"
   */
  name?: string;
  /** Description of the organizational unit */
  description?: string;
  /** Organizational Unit object. */
  parent?: B2BUnit;
  /** Approvers of the organizational unit node */
  approvers?: Customer[];
  /** Managers of the organizational unit node */
  managers?: Customer[];
  /** Administrators of the organizational unit node */
  administrators?: Customer[];
  /** Customers of the organizational unit node */
  customers?: Customer[];
  /** List of approver groups */
  approverGroups?: B2BUserGroup[];
  /** List of b2b user groups */
  userGroups?: B2BUserGroup[];
  /** Custom ship-to */
  shipTo?: ShipTo;
  /** Custom ship-to */
  customerAddress?: ShipTo;
  salesOrg?: SalesOrg;
  /** Representation of a Currency */
  currency?: Currency;
  completeDeliveryRequired?: boolean;
  paperManagementEnabled?: boolean;
  outgoingAssortmentAllowed?: boolean;
  campaignsAllowed?: boolean;
  showingOfPricesAllowed?: boolean;
  customerPricingGroup?: string;
  /** Not populated by Orika */
  creditBlocked?: boolean;
  priceFileType?: string;
}

export interface SoldToList {
  soldTos?: SoldTo[];
}

export interface SoldToPage {
  /** Result list */
  results?: SoldTo[];
  sorts?: Sort[];
  /** Pagination info */
  pagination?: Pagination;
}

/** Representation of an Article Search along with facets, breadcrumbs, sorts, the current search state and spelling suggestions. */
export interface SolrArticleSearchPage {
  /** Free text search */
  freeTextSearch?: string;
  /** Code of category currently 'active' */
  categoryCode?: string;
  /** Redirect url address keyword */
  keywordRedirectUrl?: string;
  /** Representation of a Spell Checker Suggestion */
  spellingSuggestion?: SpellingSuggestion;
  /** List of sorts */
  sorts?: Sort[];
  /** Representation of a search results pagination */
  pagination?: DeprecatedPagination;
  /** Representation of a Search State */
  currentQuery?: SearchState;
  /** List of breadcrumbs info */
  breadcrumbs?: Breadcrumb[];
  /** List of facets */
  facets?: Facet[];
  /** List of categories matching the search */
  categoryRefs?: string[];
  /** List of categories matching the search */
  areaOfUseRefs?: string[];
  /** List of articles matching the search */
  articleRefs?: string[];
  /** List of articles matching the search */
  articleResultRefs?: SolrResultEntityRef[];
}

/** Representation of a Product Search along with facets, breadcrumbs, sorts, the current search state and spelling suggestions. */
export interface SolrProductSearchPage {
  /** Free text search */
  freeTextSearch?: string;
  /** Code of category currently 'active' */
  categoryCode?: string;
  /** Redirect url address keyword */
  keywordRedirectUrl?: string;
  /** Representation of a Spell Checker Suggestion */
  spellingSuggestion?: SpellingSuggestion;
  /** List of sorts */
  sorts?: Sort[];
  /** Representation of a search results pagination */
  pagination?: DeprecatedPagination;
  /** Representation of a Search State */
  currentQuery?: SearchState;
  /** List of breadcrumbs info */
  breadcrumbs?: Breadcrumb[];
  /** List of facets */
  facets?: Facet[];
  /** List of categories matching the search */
  categoryRefs?: string[];
  /** List of categories matching the search */
  areaOfUseRefs?: string[];
  /** List of products matching the search */
  productRefs?: string[];
  /** New list of products matching the search */
  productResultRefs?: SolrResultEntityRef[];
}

/** Represents a search result entry */
export interface SolrResultEntityRef {
  /** The reference */
  ref?: string;
  /** The indexing timestamp string, if this is later than the stored reference then the local entity should be evicted and re-fetched. */
  ts?: string;
  /**
   * The score of the SolrResult
   * @format float
   */
  score?: number;
  explanation?: SearchScoreExplanation;
}

/** Representation a Sort option */
export interface Sort {
  /** Code of Sort */
  code?: string;
  /** Name of Sort */
  name?: string;
  /** Flag stating when Sort is selected */
  selected?: boolean;
}

export interface SpecialMessage {
  mainMessage?: string;
  url?: string;
  linkText?: string;
}

/** Representation of a special opening day */
export interface SpecialOpeningDay {
  /** Representation of a Time */
  openingTime?: Time;
  /** Representation of a Time */
  closingTime?: Time;
  /**
   * Date of special opening day
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  date?: string;
  /** Text representation of the date of special opening day */
  formattedDate?: string;
  /** Flag stating if special opening day is closed */
  closed?: boolean;
  /** Name of the special opening day event */
  name?: string;
  /** Comment field */
  comment?: string;
}

/** Representation of a Spell Checker Suggestion */
export interface SpellingSuggestion {
  /** Spelling suggestion */
  suggestion?: string;
  /** Query for spelling suggestion */
  query?: string;
}

export interface Statistics {
  id?: string;
  description?: string;
  /** @format double */
  weight?: number;
  /** @format double */
  netOrderValue?: number;
  /** Representation of a Price */
  netOrderPriceData?: Price;
  /** @format int32 */
  numberOfOrders?: number;
  /** @format int32 */
  numberOfOrderLines?: number;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  date?: string;
  /** @format int32 */
  quantity?: number;
  unit?: string;
}

export interface StatisticsList {
  statistics?: Statistics[];
}

/** Representation of a status summary, an aggregated view on issues for a specific status or severity. These issues are attached to configurations of products or order entries */
export interface StatusSummary {
  /**
   * Status or severity indicator, can be one of ERROR, WARNING, INFO or SUCCESS
   * @example "ERROR"
   */
  status?: string;
  /**
   * Number of issues per status
   * @format int32
   * @example 3
   */
  numberOfIssues?: number;
}

/** Representation of a Stock */
export interface Stock {
  /**
   * Status of stock level
   * @example "inStock"
   */
  stockLevelStatus?: string;
  /**
   * Stock level expressed as number
   * @format int64
   * @example 25
   */
  stockLevel?: number;
  /**
   * Indicate whether Stock level value is rounded
   * @example false
   */
  isValueRounded?: boolean;
}

export interface StockInfo {
  /** @format double */
  quantity?: number;
  unit?: Unit;
  warehouseName?: string;
}

/** Representation of a Store Count */
export interface StoreCount {
  /** Type of store count */
  type?: string;
  /** Name of store count */
  name?: string;
  /** Iso code of store */
  isoCode?: string;
  /**
   * Count
   * @format int32
   */
  count?: number;
  /** List of store counts */
  storeCountDataList?: StoreCount[];
}

/** Representation of a Store Count List */
export interface StoreCountList {
  /** List of store counts */
  countriesAndRegionsStoreCount?: StoreCount[];
}

/** Representation of a Store finder search page */
export interface StoreFinderSearchPage {
  /** List of stores */
  stores?: PointOfService[];
  /** List of sortings */
  sorts?: Sort[];
  /** Representation of a search results pagination */
  pagination?: DeprecatedPagination;
  /** Location text */
  locationText?: string;
  /**
   * Source latitude
   * @format double
   */
  sourceLatitude?: number;
  /**
   * Source longitude
   * @format double
   */
  sourceLongitude?: number;
  /**
   * Bound north latitude
   * @format double
   */
  boundNorthLatitude?: number;
  /**
   * Bound east longitude
   * @format double
   */
  boundEastLongitude?: number;
  /**
   * Bound south latitude
   * @format double
   */
  boundSouthLatitude?: number;
  /**
   * Bound west longitude
   * @format double
   */
  boundWestLongitude?: number;
}

/** Representation of a Store Finder Stock Search Page */
export interface StoreFinderStockSearchPage {
  /** List of stores */
  stores?: PointOfServiceStock[];
  /** List of sorts */
  sorts?: Sort[];
  /** Representation of a search results pagination */
  pagination?: DeprecatedPagination;
  /** Location text */
  locationText?: string;
  /**
   * Source latitude
   * @format double
   */
  sourceLatitude?: number;
  /**
   * Source longitude
   * @format double
   */
  sourceLongitude?: number;
  /**
   * Bound to north latitude
   * @format double
   */
  boundNorthLatitude?: number;
  /**
   * Bound to east longitude
   * @format double
   */
  boundEastLongitude?: number;
  /**
   * Bound to south latitude
   * @format double
   */
  boundSouthLatitude?: number;
  /**
   * Bound to west longitude
   * @format double
   */
  boundWestLongitude?: number;
  /** Representation of a Product */
  product?: Product;
}

/** A map(string,string) */
export interface StringMap {
  value: Record<string, string>;
}

export interface SubstituteRef {
  ref?: string;
  type?: SubstituteRefTypeEnum;
  replacement?: boolean;
  alternative?: boolean;
  recommendation?: boolean;
}

export interface Substitutes {
  substituteTo?: string;
  refs?: SubstituteRef[];
  /** @format int32 */
  substitutesCount?: number;
}

/** Representation of a Suggestion */
export interface Suggestion {
  /** Suggestion value */
  value?: string;
}

/** Representation of a Suggestion List */
export interface SuggestionList {
  /** List of suggestions */
  suggestions?: Suggestion[];
}

export interface TechnicalAttribute {
  name?: string;
  isoCode?: string;
  unit?: string;
  code?: string;
}

/** Representation of a Time */
export interface Time {
  /**
   * Hour part of the time data
   * @format byte
   */
  hour?: string;
  /**
   * Minute part of the time data
   * @format byte
   */
  minute?: string;
  /** Formatted hour */
  formattedHour?: string;
}

export interface TimeWindowDeliveryOption {
  code?: string;
  description?: string;
  fromTime?: string;
  toTime?: string;
}

export interface TimeWindowDeliveryOptions {
  defaultOption?: TimeWindowDeliveryOption;
  otherOptions?: TimeWindowDeliveryOption[];
  weightExceeded?: boolean;
}

export interface TinyCart {
  /**
   * Tiny cart identifier
   * @example "12345678"
   */
  code?: string;
  cartType?: string;
  /** @format int32 */
  numberOfEntries?: number;
}

export interface TinyCartList {
  tinyCarts?: TinyCart[];
}

/** Representation of a Title */
export interface Title {
  /** Title code */
  code?: string;
  /** Title name */
  name?: string;
}

/** Representation of a Title List */
export interface TitleList {
  /** List of titles */
  titles?: Title[];
}

export interface TrackOrderBillOfLading {
  billOfLadingId?: string;
  description?: string;
  shipPoints?: TrackOrderShipPoint[];
}

export interface TrackOrderDelivery {
  deliveryId?: string;
  description?: string;
  location?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  orderCreatedDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  deliveryCreatedDate?: string;
  overallPickingStatus?: TrackOrderDeliveryOverallPickingStatusEnum;
  transportPlanningStatus?: TrackOrderDeliveryTransportPlanningStatusEnum;
  totalGoodsMovementStatus?: TrackOrderDeliveryTotalGoodsMovementStatusEnum;
  transitStatus?: TrackOrderDeliveryTransitStatusEnum;
  deliveryStatus?: TrackOrderDeliveryDeliveryStatusEnum;
  billingStatus?: TrackOrderDeliveryBillingStatusEnum;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  overallPickingStatusDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  transportPlanningStatusDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  totalGoodsMovementStatusDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  transitStatusDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  deliveryStatusDate?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  billingStatusDate?: string;
  handlingUnits?: Record<string, TrackOrderHandlingUnit[]>;
  items?: TrackOrderItem[];
}

export interface TrackOrderHandlingUnit {
  handlingUnitId?: string;
  carrier?: string;
  /** @format double */
  weight?: number;
  weightUnit?: string;
  /**
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  date?: string;
  trackingNumber?: string;
  trackingUrl?: string;
}

export interface TrackOrderItem {
  article?: ArticleRef;
  unit?: Unit;
  /** @format int64 */
  quantity?: number;
}

export interface TrackOrderShipPoint {
  shipPointId?: string;
  description?: string;
  location?: string;
  deliveries?: TrackOrderDelivery[];
}

export interface TrackOrderShipTo {
  shipToId?: string;
  description?: string;
  billOfLadings?: TrackOrderBillOfLading[];
}

export interface UmamiConfiguration {
  code?: string;
  subdomain?: string;
}

export interface UniqueSellingPoint {
  point?: string;
}

export interface UniqueSellingPoints {
  points?: UniqueSellingPoint[];
}

export interface Unit {
  displayNameInSingular?: string;
  displayNameInPlural?: string;
  code?: string;
  /** @format int32 */
  inEcommerceUnit?: number;
  /** @format int32 */
  minimumPurchaseQuantity?: number;
  packageLevelName?: string;
  locSymbol?: string;
  isoCode?: string;
}

export interface UnitData {
  displayNameInSingular?: string;
  displayNameInPlural?: string;
  code?: string;
  /** @format int32 */
  inEcommerceUnit?: number;
  /** @format int32 */
  minimumPurchaseQuantity?: number;
  packageLevelName?: string;
  locSymbol?: string;
  isoCode?: string;
}

export interface UnloadingPoint {
  id?: string;
  defaultUnloadingPoint?: boolean;
  name?: string;
  /** Representation of an Opening schedule */
  goodsReceivingHours?: OpeningSchedule;
}

/** User's object. */
export interface User {
  /** Unique user identifier */
  uid?: string;
  /** Name of the user */
  name?: string;
  /** Address object */
  defaultAddress?: Address;
  /** User title code */
  titleCode?: string;
  /** User title */
  title?: string;
  /** User first name */
  firstName?: string;
  /** User last name */
  lastName?: string;
  /** Representation of a Currency */
  currency?: Currency;
  /** Representation of a Language */
  language?: Language;
  /** User identifier */
  displayUid?: string;
  /** Customer identifier */
  customerId?: string;
  /**
   * Deactivation date
   * @format date-time
   * @example "2024-06-17T12:34:56+02:00"
   */
  deactivationDate?: string;
  /**
   * Name of the default pick up location
   * @example "Misato"
   */
  defaultPointOfServiceName?: string;
  /**
   * The latest cart operated by customer
   * @example "000000001"
   */
  lastCartId?: string;
  /**
   * Indicates whether customer has order. Default value is false.
   * @example false
   */
  hasOrder?: boolean;
  /** Representation of a profile picture. */
  userAvatar?: UserAvatar;
}

/** Representation of a profile picture. */
export interface UserAvatar {
  /**
   * URL of customer profile image in media server. It's optional.
   * @example "/medias/?context=bWFzdGVyfHJvb3R8NTMyMDB8aW1hZ2UvanBlZ3xhRGM1TDJneFl5ODROemszTlRNd016a3dOVFU0TG1wd1p3fDViODZlNmFiZTkyNzFjZDFmM2I5ZWU3OGJhZWEzMjViZDBiZTQ2NjM3YzIyMTg"
   */
  url?: string;
  /**
   * Image format. It's optional.
   * @example "jpg"
   */
  format?: string;
}

/** User group object with id and name. */
export interface UserGroup {
  /** Unique user identifier */
  uid?: string;
  /** Name of the user */
  name?: string;
  /** List of members */
  members?: Principal[];
  /**
   * Number of members
   * @format int32
   */
  membersCount?: number;
}

/** Representation of a list of groups */
export interface UserGroupList {
  /** List of groups */
  groups: UserGroup[];
}

/** Representation of an user list */
export interface UserList {
  /** List of users */
  users: Customer[];
}

export interface UserRole {
  uid?: string;
  name?: string;
  displayName?: string;
  description?: string;
  configurationGroups?: PrincipalConfigurationGroup[];
  activationRequiresEmailConfirmation?: boolean;
}

export interface UserRoleList {
  roles?: UserRole[];
}

/** User's object. */
export interface UserSignUp {
  /**
   * user id, unique string required to create new user. It can be email
   * @example "akiro.nakamura@rustic-hw.com"
   */
  uid: string;
  /**
   * first name of the user
   * @example "Arika"
   */
  firstName: string;
  /**
   * last name of the user
   * @example "Nakamura"
   */
  lastName: string;
  /** @example "mr" */
  titleCode?: string;
  /** user password */
  password: string;
  email: string;
  phone: string;
  countryPhoneCode?: string;
  /** Organizational Unit object. */
  designatedRootUnit?: B2BUnit;
  soldToId?: string;
  'candidateSoldToExists '?: boolean;
  manualCompanyInfoInput?: boolean;
  marketing: boolean;
  deliveryTerms?: boolean;
  termsAndConditions: boolean;
  companyAddress?: PyAddress;
  message?: string;
  additionalInfo?: string;
  organizationNumber?: string;
  taxCode?: string;
  recaptcha: string;
}

/** A value of <T> */
export interface Value {
  value: string;
}

/** Representation of a Variant Category */
export interface VariantCategory {
  /** Variant category name */
  name?: string;
  /** Flag if varian category has image assigned */
  hasImage?: boolean;
  /**
   * Priority number of variant category
   * @format int32
   */
  priority?: number;
}

/** Representation of a Variant Matrix Element */
export interface VariantMatrixElement {
  /** Representation of a Variant Value Category */
  variantValueCategory?: VariantValueCategory;
  /** Representation of a Variant Category */
  parentVariantCategory?: VariantCategory;
  /** Representation of a Variant Option */
  variantOption?: VariantOption;
  /** List of elements with the type of variant matrix element */
  elements?: VariantMatrixElement[];
  isLeaf?: boolean;
}

/** Representation of a Variant Option */
export interface VariantOption {
  /** Code of the variant option */
  code?: string;
  /** Representation of a Stock */
  stock?: Stock;
  /** Url address of the variant option */
  url?: string;
  /** Representation of a Price */
  priceData?: Price;
  /** List of variant option qualifiers */
  variantOptionQualifiers?: VariantOptionQualifier[];
}

/** Representation of a Variant Option Qualifier */
export interface VariantOptionQualifier {
  /** Qualifier */
  qualifier?: string;
  /** Name of variant option qualifier */
  name?: string;
  /** Value of variant option qualifier */
  value?: string;
  /** Representation of an Image */
  image?: Image;
}

/** Representation of a Variant Value Category */
export interface VariantValueCategory {
  /** Name of the variant value category */
  name?: string;
  /**
   * Sequence number of variant value category
   * @format int32
   */
  sequence?: number;
  /** Parent category of variant value category */
  superCategories?: VariantCategory[];
}

/** Representation of a Voucher */
export interface Voucher {
  /** The identifier of the Voucher. This is the first part of voucher code which holds first 3 letters, like: 123 */
  code?: string;
  /** Voucher code, is the holder for keeping specific occasional voucher related to business usage. It can be generated and looks like: 123-H8BC-Y3D5-34AL */
  voucherCode?: string;
  /** Name of the voucher */
  name?: string;
  /** Description of the voucher */
  description?: string;
  /**
   * Value of the voucher. Example of such value is: 15.0d
   * @format double
   */
  value?: number;
  /** Formatted value of the voucher */
  valueFormatted?: string;
  /** The value of the voucher to display. Example: 15.0% */
  valueString?: string;
  /** Specifies if the order this voucher is applied to is shipped for free (true) or not (false). Defaults to false. */
  freeShipping?: boolean;
  /** Representation of a Currency */
  currency?: Currency;
  /** Representation of a Price */
  appliedValue?: Price;
}

/** Representation of a Voucher List */
export interface VoucherList {
  /** List of vouchers */
  vouchers?: Voucher[];
}

export interface Warehouse {
  name?: string;
  name2?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  /** Response body fields which will be returned while fetching the list of countries. The DTO is in XML or .json format */
  country?: Country;
  /** Response body fields which will be returned while fetching the list of countries. The DTO is in XML or .json format */
  region?: Country;
  poBox?: string;
  sapClient?: SapClient;
  salesOrg?: SalesOrg;
  active?: boolean;
}

export interface WarehouseArticle {
  warehouseStatus?: LocalArticleWarehouseStatus;
  stock?: WarehouseStock;
}

export interface WarehouseStock {
  unit?: Unit;
  unitString?: string;
  formattedUnit?: string;
  /** @format double */
  quantity?: number;
  formattedQuantity?: string;
}

/** Representation of a Weekday Opening Day */
export interface WeekdayOpeningDay {
  /** Representation of a Time */
  openingTime?: Time;
  /** Representation of a Time */
  closingTime?: Time;
  /** Text representation of week day opening day */
  weekDay?: string;
  /** Flag stating if weekday opening day is closed */
  closed?: boolean;
}

/** Representation of a search results pagination */
export interface DeprecatedPagination {
  /**
   * The number of results per page. A page may have less results if there are less than a full page of results, only on the last page in the results
   * @format int32
   */
  pageSize?: number;
  /**
   * The current page number. The first page is number zero (0), the second page is number one (1), and so on
   * @format int32
   */
  currentPage?: number;
  /** The selected sort code */
  sort?: string;
  /**
   * The total number of pages. This is the number of pages, each of pageSize, required to display the totalResults.
   * @format int32
   */
  totalPages?: number;
  /**
   * The total number of matched results across all pages
   * @format int64
   */
  totalResults?: number;
}

/** List of errors */
export interface ErrorList {
  errors?: Error[];
}

/** Pagination info */
export interface Pagination {
  /**
   * Number of elements on this page
   * @format int32
   */
  count?: number;
  /**
   * Total number of elements
   * @format int64
   */
  totalCount?: number;
  /**
   * Current page number
   * @format int32
   */
  page?: number;
  /**
   * Total number of pages
   * @format int32
   */
  totalPages?: number;
  /** Indicates if there is next page */
  hasNext?: boolean;
  /** Indicates if there is previous page */
  hasPrevious?: boolean;
}

/** Sort option */
export interface Sort {
  code?: string;
  asc?: boolean;
}

export enum ArticlePackagingEnum {
  REAMED = 'REAMED',
  UNREAMED = 'UNREAMED',
  BULKED = 'BULKED',
  UNKNOWN = 'UNKNOWN',
}

export enum AutoResolveChangeChangeTypeEnum {
  CHANGE_TYPE_DATE = 'CHANGE_TYPE_DATE',
  CHANGE_TYPE_NONE = 'CHANGE_TYPE_NONE',
  CHANGE_TYPE_QUANTITY = 'CHANGE_TYPE_QUANTITY',
  CHANGE_TYPE_SPLIT = 'CHANGE_TYPE_SPLIT',
}

/** Badge Type */
export enum BadgeTypeEnum {
  RETURNED_ORDERS = 'RETURNED_ORDERS',
  PENDING_APPROVAL_ORDERS = 'PENDING_APPROVAL_ORDERS',
  NEW_USERS_COUNT = 'NEW_USERS_COUNT',
  LOCKED_OUT_USERS_COUNT = 'LOCKED_OUT_USERS_COUNT',
  SHOPPING_LISTS_WITH_UNAVAILABLE_ARTICLES_COUNT = 'SHOPPING_LISTS_WITH_UNAVAILABLE_ARTICLES_COUNT',
  ORDER_CARDS_WITH_UNAVAILABLE_PRODUCTS_COUNT = 'ORDER_CARDS_WITH_UNAVAILABLE_PRODUCTS_COUNT',
  AREAS_OF_USE_UNAVAILABLE_PRODUCTS_COUNT = 'AREAS_OF_USE_UNAVAILABLE_PRODUCTS_COUNT',
}

export enum CmsFormFieldTypeEnum {
  EMAIL = 'EMAIL',
  CHECKBOX = 'CHECKBOX',
  RADIOBUTTON = 'RADIOBUTTON',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  LONGTEXT = 'LONGTEXT',
  LABEL = 'LABEL',
  FILE = 'FILE',
}

export enum CartOrderTypeEnum {
  STOCK = 'STOCK',
  SAMPLE = 'SAMPLE',
  FREE_STOCK = 'FREE_STOCK',
  FREE_STOCK_FILLUP = 'FREE_STOCK_FILLUP',
  INVOICED_STOCK = 'INVOICED_STOCK',
  INVOICED_STOCK_FILLUP = 'INVOICED_STOCK_FILLUP',
  CONSIGNMENT_STOCK = 'CONSIGNMENT_STOCK',
  CONSIGNMENT_STOCK_FILLUP = 'CONSIGNMENT_STOCK_FILLUP',
}

export enum CartTemplateOrderTypeEnum {
  STOCK = 'STOCK',
  SAMPLE = 'SAMPLE',
  FREE_STOCK = 'FREE_STOCK',
  FREE_STOCK_FILLUP = 'FREE_STOCK_FILLUP',
  INVOICED_STOCK = 'INVOICED_STOCK',
  INVOICED_STOCK_FILLUP = 'INVOICED_STOCK_FILLUP',
  CONSIGNMENT_STOCK = 'CONSIGNMENT_STOCK',
  CONSIGNMENT_STOCK_FILLUP = 'CONSIGNMENT_STOCK_FILLUP',
}

export enum CartTemplateAccessTypeEnum {
  EVERYONE = 'EVERYONE',
  PRIVATE = 'PRIVATE',
  SHARED = 'SHARED',
  INVITED_TO = 'INVITED_TO',
}

export enum CartTemplateSummaryAccessTypeEnum {
  EVERYONE = 'EVERYONE',
  PRIVATE = 'PRIVATE',
  SHARED = 'SHARED',
  INVITED_TO = 'INVITED_TO',
}

/** Import status */
export enum DataImportStatusEnum {
  IMPORTED = 'IMPORTED',
  IMPORTED_WITH_ERRORS = 'IMPORTED_WITH_ERRORS',
  FAILED = 'FAILED',
}

/** Type of the image, can be PRIMARY or GALLERY */
export enum ImageImageTypeEnum {
  PRIMARY = 'PRIMARY',
  GALLERY = 'GALLERY',
}

/** Status indicator */
export enum OperationalMessageWrapperStatusEnum {
  ENABLED = 'ENABLED',
  NOT_MODIFIED = 'NOT_MODIFIED',
  EMPTY = 'EMPTY',
}

export enum OrderOrderTypeEnum {
  STOCK = 'STOCK',
  SAMPLE = 'SAMPLE',
  FREE_STOCK = 'FREE_STOCK',
  FREE_STOCK_FILLUP = 'FREE_STOCK_FILLUP',
  INVOICED_STOCK = 'INVOICED_STOCK',
  INVOICED_STOCK_FILLUP = 'INVOICED_STOCK_FILLUP',
  CONSIGNMENT_STOCK = 'CONSIGNMENT_STOCK',
  CONSIGNMENT_STOCK_FILLUP = 'CONSIGNMENT_STOCK_FILLUP',
}

export enum OrderApprovalApprovalStatusEnum {
  NEW = 'NEW',
  APPROVER_EDITING = 'APPROVER_EDITING',
  RETURNED_TO_USER = 'RETURNED_TO_USER',
  UPDATED = 'UPDATED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ASSIGNED_TO_ADMIN = 'ASSIGNED_TO_ADMIN',
}

/**
 * Order card status (ACTIVE or INACTIVE)
 * @example "INACTIVE"
 */
export enum OrderCardStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum OrderDetailsOrderOrderTypeEnum {
  STOCK = 'STOCK',
  SAMPLE = 'SAMPLE',
  FREE_STOCK = 'FREE_STOCK',
  FREE_STOCK_FILLUP = 'FREE_STOCK_FILLUP',
  INVOICED_STOCK = 'INVOICED_STOCK',
  INVOICED_STOCK_FILLUP = 'INVOICED_STOCK_FILLUP',
  CONSIGNMENT_STOCK = 'CONSIGNMENT_STOCK',
  CONSIGNMENT_STOCK_FILLUP = 'CONSIGNMENT_STOCK_FILLUP',
}

export enum OrderEntryTypeEnum {
  STOCK_ITEM = 'STOCK_ITEM',
  ORIGINAL_SAMPLE = 'ORIGINAL_SAMPLE',
  A4SAMPLE = 'A4_SAMPLE',
  FREE_STOCK_ITEM = 'FREE_STOCK_ITEM',
  FREE_STOCK_FILLUP_ITEM = 'FREE_STOCK_FILLUP_ITEM',
  INVOICED_STOCK_ITEM = 'INVOICED_STOCK_ITEM',
  INVOICED_STOCK_FILLUP_ITEM = 'INVOICED_STOCK_FILLUP_ITEM',
  CONSIGNMENT_STOCK_ITEM = 'CONSIGNMENT_STOCK_ITEM',
  CONSIGNMENT_STOCK_FILLUP_ITEM = 'CONSIGNMENT_STOCK_FILLUP_ITEM',
}

export enum OrderReturnEntryReasonCodeEnum {
  DAMAGED_ITEM = 'DAMAGED_ITEM',
  CHANGED_MY_MIND = 'CHANGED_MY_MIND',
  ORDERED_TOO_MUCH = 'ORDERED_TOO_MUCH',
  WRONG_ITEMS = 'WRONG_ITEMS',
  BROKEN_PACKAGE = 'BROKEN_PACKAGE',
  DEFECT_UPON_RECEIPT = 'DEFECT_UPON_RECEIPT',
  QUALITY_ISSUE = 'QUALITY_ISSUE',
  OTHER = 'OTHER',
}

export enum PalletFlagTypeEnum {
  CUSTOMER = 'CUSTOMER',
  MACHINE_NO = 'MACHINE_NO',
  OBJECT = 'OBJECT',
}

export enum PaymentOptionTypeEnum {
  INVOICE = 'INVOICE',
  CREDIT_CARD = 'CREDIT_CARD',
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
  CASH_ON_PICKUP = 'CASH_ON_PICKUP',
}

export enum PaymentProviderIdEnum {
  NETS = 'NETS',
  GPWEBPAY = 'GPWEBPAY',
  MOLLIE = 'MOLLIE',
  AUTOPAY = 'AUTOPAY',
  PAYREXX = 'PAYREXX',
  SIAVPOS = 'SIAVPOS',
}

/**
 * Permission status: Active - after valid from date and published flag=true Inactive - before Valid from and published flag=true Expired - after ValidTo date and published flag=true Unpublished - published flag=false
 * @example "true"
 */
export enum PermissionStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
  UNPUBLISHED = 'UNPUBLISHED',
}

/**
 * Period range of the Permission, used for type B2BOrderThresholdTimespanPermission
 * @example "MONTH"
 */
export enum PermissionPeriodRangeEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  HALF_YEAR = 'HALF_YEAR',
  YEAR = 'YEAR',
}

/** Resolution type of permission */
export enum PermissionResolutionTypeEnum {
  STANDARD = 'STANDARD',
  BLOCK = 'BLOCK',
}

/** Specifies connection type of the permission */
export enum PermissionConnectionTypeEnum {
  USERS = 'USERS',
  SHIPTOS = 'SHIPTOS',
  SOLDTOS = 'SOLDTOS',
  ORDER_CARD = 'ORDER_CARD',
}

/** Type of the price */
export enum PricePriceTypeEnum {
  BUY = 'BUY',
  FROM = 'FROM',
}

export enum PriceFileStatusEnum {
  FINISHED = 'FINISHED',
  PROCESSING = 'PROCESSING',
  REQUESTED = 'REQUESTED',
  ERROR = 'ERROR',
  CREATED = 'CREATED',
  CANCELED = 'CANCELED',
}

export enum ResolveOptionActionEnum {
  ALTERNATIVE_ARTICLE = 'ALTERNATIVE_ARTICLE',
  ALTERNATIVEARTICLE1 = 'ALTERNATIVE_ARTICLE1',
  ALTERNATIVEARTICLE2 = 'ALTERNATIVE_ARTICLE2',
  ALTERNATIVEARTICLE3 = 'ALTERNATIVE_ARTICLE3',
  ALTERNATIVEARTICLE4 = 'ALTERNATIVE_ARTICLE4',
  ALTERNATIVE_DATE = 'ALTERNATIVE_DATE',
  PARTIAL_DELIVERY = 'PARTIAL_DELIVERY',
  REMOVE_ENTRY = 'REMOVE_ENTRY',
  COMPLETE_DELIVERY = 'COMPLETE_DELIVERY',
  ALTERNATIVE_AVAILABLE_AND_REST_ORDER = 'ALTERNATIVE_AVAILABLE_AND_REST_ORDER',
  PRE_PRINT = 'PRE_PRINT',
  DO_NOTHING = 'DO_NOTHING',
}

export enum ReturnReasonReasonCodeEnum {
  DAMAGED_ITEM = 'DAMAGED_ITEM',
  CHANGED_MY_MIND = 'CHANGED_MY_MIND',
  ORDERED_TOO_MUCH = 'ORDERED_TOO_MUCH',
  WRONG_ITEMS = 'WRONG_ITEMS',
  BROKEN_PACKAGE = 'BROKEN_PACKAGE',
  DEFECT_UPON_RECEIPT = 'DEFECT_UPON_RECEIPT',
  QUALITY_ISSUE = 'QUALITY_ISSUE',
  OTHER = 'OTHER',
}

/** Status of the return request */
export enum ReturnRequestModificationStatusEnum {
  CANCELLING = 'CANCELLING',
}

export enum ServiceOptionTypeEnum {
  CARRY_BEYOND_LOADING_BAY = 'CARRY_BEYOND_LOADING_BAY',
  UNPACKING = 'UNPACKING',
  TIME_WINDOW_CODE = 'TIME_WINDOW_CODE',
}

export enum ShippingOptionTypeEnum {
  STANDARD = 'STANDARD',
  COMPLETE = 'COMPLETE',
  COMPLETE_FORCED = 'COMPLETE_FORCED',
  SPECIFIC_DATE = 'SPECIFIC_DATE',
  SAME_DAY = 'SAME_DAY',
  TIME_WINDOW = 'TIME_WINDOW',
  PICKUP = 'PICKUP',
  PICKUP_FORCED = 'PICKUP_FORCED',
  EVENING_EXPRESS = 'EVENING_EXPRESS',
  PICKUP_SAME_DAY = 'PICKUP_SAME_DAY',
}

export enum SubstituteRefTypeEnum {
  ARTICLE = 'ARTICLE',
  PRODUCT = 'PRODUCT',
}

export enum TrackOrderDeliveryOverallPickingStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED',
  COMPLETED = 'COMPLETED',
}

export enum TrackOrderDeliveryTransportPlanningStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED',
  COMPLETED = 'COMPLETED',
}

export enum TrackOrderDeliveryTotalGoodsMovementStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED',
  COMPLETED = 'COMPLETED',
}

export enum TrackOrderDeliveryTransitStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED',
  COMPLETED = 'COMPLETED',
}

export enum TrackOrderDeliveryDeliveryStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED',
  COMPLETED = 'COMPLETED',
}

export enum TrackOrderDeliveryBillingStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED',
  COMPLETED = 'COMPLETED',
}

/** Response configuration. This is the list of fields that should be returned in the response body. Examples: BASIC, DEFAULT, FULL. */
export enum GetComponentsByIdsParamsFieldsEnum {
  BASIC = 'BASIC',
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
}

/** Response configuration. This is the list of fields that should be returned in the response body. Examples: BASIC, DEFAULT, FULL. */
export enum SearchComponentsByIdsParamsFieldsEnum {
  BASIC = 'BASIC',
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
}

/** Response configuration. This is the list of fields that should be returned in the response body. Examples: BASIC, DEFAULT, FULL. */
export enum GetComponentByIdParamsFieldsEnum {
  BASIC = 'BASIC',
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
}

/** Response configuration. This is the list of fields that should be returned in the response body. Examples: BASIC, DEFAULT, FULL. */
export enum GetPageParamsFieldsEnum {
  BASIC = 'BASIC',
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
}

/** Page type */
export enum GetPageParamsPageTypeEnum {
  ContentPage = 'ContentPage',
  ProductPage = 'ProductPage',
  CategoryPage = 'CategoryPage',
  CatalogPage = 'CatalogPage',
}

/** Response configuration. This is the list of fields that should be returned in the response body. Examples: BASIC, DEFAULT, FULL. */
export enum GetPageByIdParamsFieldsEnum {
  BASIC = 'BASIC',
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
}

/** Response configuration. This is the list of fields that should be returned in the response body. Examples: BASIC, DEFAULT, FULL. */
export enum GetAllPagesParamsFieldsEnum {
  BASIC = 'BASIC',
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
}

/** Page type. */
export enum GetAllPagesParamsPageTypeEnum {
  ContentPage = 'ContentPage',
  ProductPage = 'ProductPage',
  CategoryPage = 'CategoryPage',
  CatalogPage = 'CatalogPage',
}

/** The type of countries. */
export enum GetCountries1ParamsTypeEnum {
  SHIPPING = 'SHIPPING',
  BILLING = 'BILLING',
}

/** Type of promotions that should be returned. Possible values are: <ul><li>all: All available promotions are returned. </li><li>product: Only product promotions are returned. </li><li>order: Only order promotions are returned. </li></ul> */
export enum GetPromotionsParamsTypeEnum {
  All = 'all',
  Product = 'product',
  Order = 'order',
}

/** Search mode */
export enum GetPageableCustomersParamsModeEnum {
  UID = 'UID',
  CUSTOMER = 'CUSTOMER',
  SOLDTO = 'SOLDTO',
  CPG = 'CPG',
  EMAIL = 'EMAIL',
  SOLDTO_NAME = 'SOLDTO_NAME',
  CUSTOMER_PK = 'CUSTOMER_PK',
}

/** Company search service type */
export enum GetWorksiteSignUpInfoByIdParamsSearchTypeEnum {
  BISNODE = 'BISNODE',
  DNB = 'DNB',
}

/** Order card status. Possible values are: 'ACTIVE' and 'INACTIVE' */
export enum GetOrderCardsParamsStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

/** Order card action */
export enum UpdateOrderCardPrincipalsParamsActionEnum {
  Add = 'add',
  Remove = 'remove',
}

/** Order card action */
export enum UpdateOrderCardPrincipals1ParamsActionEnum {
  Add = 'add',
  Remove = 'remove',
}

/** Order card action, Possible values are: 'add' or 'remove' */
export enum UpdateOrderCardProductsParamsActionEnum {
  Add = 'add',
  Remove = 'remove',
}

/** Order card status */
export enum SetOrderCardStatusParamsStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

/** Status filter:ACTIVE,INACTIVE,EXPIRED,UNPUBLISHED */
export enum GetOrderRules1ParamsStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum GetApproverOrderApprovalsParamsApprovalStatusEnum {
  NEW = 'NEW',
  APPROVER_EDITING = 'APPROVER_EDITING',
  RETURNED_TO_USER = 'RETURNED_TO_USER',
  UPDATED = 'UPDATED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ASSIGNED_TO_ADMIN = 'ASSIGNED_TO_ADMIN',
}

export enum GetBuyerOrderApprovalsParamsApprovalStatusEnum {
  NEW = 'NEW',
  APPROVER_EDITING = 'APPROVER_EDITING',
  RETURNED_TO_USER = 'RETURNED_TO_USER',
  UPDATED = 'UPDATED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ASSIGNED_TO_ADMIN = 'ASSIGNED_TO_ADMIN',
}

/** Enabling action */
export enum SetCarryBeyondLoadingBayParamsEnablingActionEnum {
  Enable = 'enable',
  Disable = 'disable',
}

/** Enabling action */
export enum SetCompleteDeliveryParamsEnablingActionEnum {
  Enable = 'enable',
  Disable = 'disable',
}

/** Resolve change type */
export enum ApplyResolveOptionParamsTypeEnum {
  ALTERNATIVE_ARTICLE = 'ALTERNATIVE_ARTICLE',
  ALTERNATIVEARTICLE1 = 'ALTERNATIVE_ARTICLE1',
  ALTERNATIVEARTICLE2 = 'ALTERNATIVE_ARTICLE2',
  ALTERNATIVEARTICLE3 = 'ALTERNATIVE_ARTICLE3',
  ALTERNATIVEARTICLE4 = 'ALTERNATIVE_ARTICLE4',
  ALTERNATIVE_DATE = 'ALTERNATIVE_DATE',
  PARTIAL_DELIVERY = 'PARTIAL_DELIVERY',
  REMOVE_ENTRY = 'REMOVE_ENTRY',
  COMPLETE_DELIVERY = 'COMPLETE_DELIVERY',
  ALTERNATIVE_AVAILABLE_AND_REST_ORDER = 'ALTERNATIVE_AVAILABLE_AND_REST_ORDER',
  PRE_PRINT = 'PRE_PRINT',
  DO_NOTHING = 'DO_NOTHING',
}

/** Enabling action */
export enum SetEveningExpressParamsEnablingActionEnum {
  Enable = 'enable',
  Disable = 'disable',
}

/** Payment type */
export enum SetPaymentOptionParamsPaymentTypeEnum {
  INVOICE = 'INVOICE',
  CREDIT_CARD = 'CREDIT_CARD',
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
  CASH_ON_PICKUP = 'CASH_ON_PICKUP',
}

/** Cart type */
export enum SetPhoneNumberParamsCartTypeEnum {
  Stock = 'stock',
  Sample = 'sample',
  FreeStock = 'freeStock',
  FreeStockFillup = 'freeStockFillup',
  InvoicedStock = 'invoicedStock',
  InvoicedStockFillup = 'invoicedStockFillup',
  ConsignmentStock = 'consignmentStock',
  ConsignmentStockFillup = 'consignmentStockFillup',
}

/** Enabling action */
export enum SetPickupParamsEnablingActionEnum {
  Enable = 'enable',
  Disable = 'disable',
}

/** Enabling action */
export enum SetTimeWindowCodeDeliveryParamsEnablingActionEnum {
  Enable = 'enable',
  Disable = 'disable',
}

/** Enabling action */
export enum SetTimeWindowDeliveryParamsEnablingActionEnum {
  Enable = 'enable',
  Disable = 'disable',
}

/** Enabling action */
export enum SetUnpackingParamsEnablingActionEnum {
  Enable = 'enable',
  Disable = 'disable',
}

/** Country type */
export enum GetCountriesByTypeParamsTypeEnum {
  ONBOARDING = 'ONBOARDING',
  SHIPPING = 'SHIPPING',
  BILLING = 'BILLING',
}

export enum InvoicesParamsPaymentStatusEnum {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  OVERDUE = 'OVERDUE',
}

export enum InvoiceExcelExport1ParamsPaymentStatusEnum {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  OVERDUE = 'OVERDUE',
}

/** Status filter:ACTIVE,INACTIVE or empty */
export enum GetBuyerOrderRulesParamsStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
  UNPUBLISHED = 'UNPUBLISHED',
}

/**
 * Payment provider for active user.
 * @example "'NETS', 'GPWEBPAY', 'AUTOPAY', 'PAYREXX', 'SIAVPOS' and 'MOLLIE'"
 */
export enum GetPaymentDetails1ParamsPaymentProviderEnum {
  NETS = 'NETS',
  GPWEBPAY = 'GPWEBPAY',
  MOLLIE = 'MOLLIE',
  AUTOPAY = 'AUTOPAY',
  PAYREXX = 'PAYREXX',
  SIAVPOS = 'SIAVPOS',
}

export enum CheckPriceFileStatusEnum {
  FINISHED = 'FINISHED',
  PROCESSING = 'PROCESSING',
  REQUESTED = 'REQUESTED',
  ERROR = 'ERROR',
  CREATED = 'CREATED',
  CANCELED = 'CANCELED',
}

export enum SetPriceFileStatusParamsStatusEnum {
  CANCELED = 'CANCELED',
}

/** Query type */
export enum GetStatisticsParamsQueryTypeEnum {
  SoldTo = 'soldTo',
  Category = 'category',
  Article = 'article',
  Month = 'month',
}

export enum GetStatisticsParamsEnum {
  SoldTo = 'soldTo',
  Category = 'category',
  Article = 'article',
  Month = 'month',
}

/** Query type */
export enum DownloadStatisticsParamsQueryTypeEnum {
  SoldTo = 'soldTo',
  Category = 'category',
  Article = 'article',
  Month = 'month',
}

export enum DownloadStatisticsParamsEnum {
  SoldTo = 'soldTo',
  Category = 'category',
  Article = 'article',
  Month = 'month',
}

export enum GetAvailableUserRolesParamsConfigurationsEnum {
  EnableCustomCatalog = 'enableCustomCatalog',
  CustomCatalogName = 'customCatalogName',
  AreaOfUseVersionId = 'areaOfUseVersionId',
  AllowNetpriceIncompletionInCheckout = 'allowNetpriceIncompletionInCheckout',
  ArticlePriceFetchInterval = 'articlePriceFetchInterval',
  DisplayUserFirstnameInHeader = 'displayUserFirstnameInHeader',
  EnableAccountingCodes = 'enableAccountingCodes',
  EnableAccountingCodesAdmin = 'enableAccountingCodesAdmin',
  EnableMandatoryAccountingCodes = 'enableMandatoryAccountingCodes',
  AccountingCodesLabel = 'accountingCodesLabel',
  EnableAddressAndDatePerOrderline = 'enableAddressAndDatePerOrderline',
  EnableAdvancedSearch = 'enableAdvancedSearch',
  EnableAlwaysSendPricesInSingleUnits = 'enableAlwaysSendPricesInSingleUnits',
  EnableArticleLevelCertificatesProcurator = 'enableArticleLevelCertificatesProcurator',
  EnableBomUnpacking = 'enableBomUnpacking',
  EnableIssStatistics = 'enableIssStatistics',
  EnablePriceFileMaster = 'enablePriceFileMaster',
  EnableBreakableArticleExplanation = 'enableBreakableArticleExplanation',
  EnableCategoryHierarchyDebug = 'enableCategoryHierarchyDebug',
  EnableCategoryHierarchyCache = 'enableCategoryHierarchyCache',
  EnableCostPlace = 'enableCostPlace',
  EnableCreditCardPayment = 'enableCreditCardPayment',
  EnableCreditWarnings = 'enableCreditWarnings',
  EnableCreditWarningsForIndent = 'enableCreditWarningsForIndent',
  EnableCustomerFreeStock = 'enableCustomerFreeStock',
  EnableCustomerFreeStockCreation = 'enableCustomerFreeStockCreation',
  EnableCustomerFreeStockFillup = 'enableCustomerFreeStockFillup',
  EnableCustomerUnique = 'enableCustomerUnique',
  EnableTendered = 'enableTendered',
  EnableCustomerInvoicedStock = 'enableCustomerInvoicedStock',
  EnableCustomerInvoicedStockFillup = 'enableCustomerInvoicedStockFillup',
  EnableCustomerConsignmentStock = 'enableCustomerConsignmentStock',
  EnableCustomerConsignmentStockFillup = 'enableCustomerConsignmentStockFillup',
  EnableProcuratorEnvironmentalChoice = 'enableProcuratorEnvironmentalChoice',
  EnableCustomShipTo = 'enableCustomShipTo',
  EnableUserShipToFilter = 'enableUserShipToFilter',
  EnableUserShipToFilterAdmin = 'enableUserShipToFilterAdmin',
  EnableSapOnlineFunctions = 'enableSapOnlineFunctions',
  EnableDefaultWarehouseForBreakable = 'enableDefaultWarehouseForBreakable',
  EnableDefaultWarehouseForCuttable = 'enableDefaultWarehouseForCuttable',
  EnableDeliveryCountrySelection = 'enableDeliveryCountrySelection',
  EnableDeliveryPlantEmailSubjectParam = 'enableDeliveryPlantEmailSubjectParam',
  AvailableCustomerLoginTypes = 'availableCustomerLoginTypes',
  CustomerLoginType = 'customerLoginType',
  EnableDisplayCountryPhoneNumber = 'enableDisplayCountryPhoneNumber',
  EnableDisplayInternalOrderComments = 'enableDisplayInternalOrderComments',
  EnableUsernameReminderEmail = 'enableUsernameReminderEmail',
  EnableDisplaySearchHint = 'enableDisplaySearchHint',
  EnableDeliveryNote = 'enableDeliveryNote',
  EnableFullPalletUpgrade = 'enableFullPalletUpgrade',
  EnableExcludeFullPalletUpgrade = 'enableExcludeFullPalletUpgrade',
  EnableExcludeIndentArticlesFromPriceFile = 'enableExcludeIndentArticlesFromPriceFile',
  EnableExpressDelivery = 'enableExpressDelivery',
  ExpressDeliveryShippingCondition = 'expressDeliveryShippingCondition',
  DefaultShippingCondition = 'defaultShippingCondition',
  EnableCutting = 'enableCutting',
  EnableCuttingDustFree = 'enableCuttingDustFree',
  EnableCuttingPerpendicular = 'enableCuttingPerpendicular',
  EnableContractedArticleIcon = 'enableContractedArticleIcon',
  EnableReaming = 'enableReaming',
  EnableRefiningRules = 'enableRefiningRules',
  DefaultNameMarkingText = 'defaultNameMarkingText',
  ReamingAdministrationFeeUnit = 'reamingAdministrationFeeUnit',
  EnableOrderLineDatesInOrderHistoryList = 'enableOrderLineDatesInOrderHistoryList',
  EnableInvoiceNote = 'enableInvoiceNote',
  EnableMandatoryApproverCommentsInEditMode = 'enableMandatoryApproverCommentsInEditMode',
  InvoiceNoteLabel = 'invoiceNoteLabel',
  EnableLogin = 'enableLogin',
  EnableMandatoryOrderNote = 'enableMandatoryOrderNote',
  EnableMandatoryOrderLineNote = 'enableMandatoryOrderLineNote',
  EnableMandatoryDeliveryNote = 'enableMandatoryDeliveryNote',
  EnableMultipleCustomer = 'enableMultipleCustomer',
  EnableMyAccount = 'enableMyAccount',
  EnableNetPrices = 'enableNetPrices',
  EnableDisplayUsps = 'enableDisplayUsps',
  EnableDisplayLongDescription = 'enableDisplayLongDescription',
  EnableOrderCards = 'enableOrderCards',
  EnableOrderCardsUser = 'enableOrderCardsUser',
  EnableOrderCardsAdmin = 'enableOrderCardsAdmin',
  EnableOrderMerge = 'enableOrderMerge',
  EnableOrderNote = 'enableOrderNote',
  EnableOrderNoteRow = 'enableOrderNoteRow',
  EnableOrderTexts = 'enableOrderTexts',
  EnablePalletFlags = 'enablePalletFlags',
  EnableFullPalletNote = 'enableFullPalletNote',
  EnablePickup = 'enablePickup',
  EnablePriceFiles = 'enablePriceFiles',
  EnablePurchaseStatistics = 'enablePurchaseStatistics',
  CategoryPurchaseStatisticsReportConfigCode = 'categoryPurchaseStatisticsReportConfigCode',
  ArticlePurchaseStatisticsReportConfigCode = 'articlePurchaseStatisticsReportConfigCode',
  MonthlyPurchaseStatisticsReportConfigCode = 'monthlyPurchaseStatisticsReportConfigCode',
  EnableShop = 'enableShop',
  EnableSiteContextSwitch = 'enableSiteContextSwitch',
  EnableOrderTemplates = 'enableOrderTemplates',
  EnableSendUserActivationEmail = 'enableSendUserActivationEmail',
  EnableSmallOrderFeeWarning = 'enableSmallOrderFeeWarning',
  EnableSmallOrderTransportFeeWarning = 'enableSmallOrderTransportFeeWarning',
  EnableSocialSharing = 'enableSocialSharing',
  EnableTimeWindowDelivery = 'enableTimeWindowDelivery',
  EnableTimeCode = 'enableTimeCode',
  EnableTodayAsDeliveryDate = 'enableTodayAsDeliveryDate',
  EnableToCheckout = 'enableToCheckout',
  EnableTogglingOfAddons = 'enableTogglingOfAddons',
  EnableLocalCategorySorting = 'enableLocalCategorySorting',
  EnableTogglingOfCustomerAssortment = 'enableTogglingOfCustomerAssortment',
  EnableWarehouseAndStockLevel = 'enableWarehouseAndStockLevel',
  EnableLastOrderedArticles = 'enableLastOrderedArticles',
  EnableMostOrderedArticles = 'enableMostOrderedArticles',
  EnableOrderHistory = 'enableOrderHistory',
  EnableInvoiceValueVAT = 'enableInvoiceValueVAT',
  EnableInvoiceHistory = 'enableInvoiceHistory',
  EnableDeliveryHistory = 'enableDeliveryHistory',
  EnableRemovalOfCarryBeyondLoadingBay = 'enableRemovalOfCarryBeyondLoadingBay',
  EnableRemovalOfUnpacking = 'enableRemovalOfUnpacking',
  EnableDisplayUnpackingFeePerOrderLine = 'enableDisplayUnpackingFeePerOrderLine',
  EnableSampleOrders = 'enableSampleOrders',
  EnableSharedCartTemplates = 'enableSharedCartTemplates',
  EnableSharingCartTemplates = 'enableSharingCartTemplates',
  AvailableSharingCartTemplatesModes = 'availableSharingCartTemplatesModes',
  SharingCartTemplatesMode = 'sharingCartTemplatesMode',
  EnableOneTimeCustomers = 'enableOneTimeCustomers',
  EnableOrganizationOnboardingDuringRegistration = 'enableOrganizationOnboardingDuringRegistration',
  MaxOrderlinesPerOrder = 'maxOrderlinesPerOrder',
  MaxA4SampleSheetsPerProduct = 'maxA4SampleSheetsPerProduct',
  MaxA4SampleProductsPerOrder = 'maxA4SampleProductsPerOrder',
  MaxOriginalSampleSheetsPerProduct = 'maxOriginalSampleSheetsPerProduct',
  MaxOriginalSampleProductsPerOrder = 'maxOriginalSampleProductsPerOrder',
  MaxCustomerRecentlyViewedProducts = 'maxCustomerRecentlyViewedProducts',
  A4SampleUnit = 'a4SampleUnit',
  SampleAdministrationFeeArticleNumberForA4Samples = 'sampleAdministrationFeeArticleNumberForA4Samples',
  SampleAdministrationFeeArticleNumberForOriginalSizeSamples = 'sampleAdministrationFeeArticleNumberForOriginalSizeSamples',
  DisabledProfileFields = 'disabledProfileFields',
  SampleAdministrationFeeUnit = 'sampleAdministrationFeeUnit',
  EnableTrackAndTrace = 'enableTrackAndTrace',
  EnableContactPersonStatusCheckAtLogin = 'enableContactPersonStatusCheckAtLogin',
  EnableEveningExpress = 'enableEveningExpress',
  EnableOnlineChat = 'enableOnlineChat',
  EnablePrePrintedLabels = 'enablePrePrintedLabels',
  EnforcePrePrintedCartSegregation = 'enforcePrePrintedCartSegregation',
  PrePrintingAdministrationFeeUnit = 'prePrintingAdministrationFeeUnit',
  PrePrintingConfigurationUnit = 'prePrintingConfigurationUnit',
  EnableUpdateLastLogonInSap = 'enableUpdateLastLogonInSap',
  EnableReelCutting = 'enableReelCutting',
  EnableReelWidthCutting = 'enableReelWidthCutting',
  EnableReelLengthCutting = 'enableReelLengthCutting',
  EnableMessageInCheckout = 'enableMessageInCheckout',
  EnableMandatoryPhoneNumberInCheckout = 'enableMandatoryPhoneNumberInCheckout',
  EnableStatisticsCode = 'enableStatisticsCode',
  EnableMandatoryStatisticsCode = 'enableMandatoryStatisticsCode',
  StatisticsCodeLabel = 'statisticsCodeLabel',
  EnableGoodsMarking = 'enableGoodsMarking',
  EnableMandatoryGoodsmarking = 'enableMandatoryGoodsmarking',
  MandatoryGoodsmarkingLabel = 'mandatoryGoodsmarkingLabel',
  EnableCustomerReference = 'enableCustomerReference',
  EnableMandatoryCustomerReference = 'enableMandatoryCustomerReference',
  CustomerReferenceLabel = 'customerReferenceLabel',
  EnablePhoneNumberInCheckout = 'enablePhoneNumberInCheckout',
  EnableReinvoicing = 'enableReinvoicing',
  EnableMandatoryReinvoicingCustomerNumber = 'enableMandatoryReinvoicingCustomerNumber',
  ReinvoicingCustomerNumberLabel = 'reinvoicingCustomerNumberLabel',
  EnableMandatoryReinvoicingId = 'enableMandatoryReinvoicingId',
  ReinvoicingIdLabel = 'reinvoicingIdLabel',
  EnableMandatoryReinvoicingReferenceNumber = 'enableMandatoryReinvoicingReferenceNumber',
  ReinvoicingReferenceNumberLabel = 'reinvoicingReferenceNumberLabel',
  EnableMandatoryReinvoicingReferenceNumber2 = 'enableMandatoryReinvoicingReferenceNumber2',
  ReinvoicingReferenceNumberLabel2 = 'reinvoicingReferenceNumberLabel2',
  EnableDisplayAlternativeArticles = 'enableDisplayAlternativeArticles',
  EnableDisplayProductImagesForSkus = 'enableDisplayProductImagesForSkus',
  EnableDisplayPrePrintingSymbolOnArticle = 'enableDisplayPrePrintingSymbolOnArticle',
  EnableDisplayReamingSymbolOnArticle = 'enableDisplayReamingSymbolOnArticle',
  EnableDisplayEshopTooltip = 'enableDisplayEshopTooltip',
  EnableDisplayAreaOfUse = 'enableDisplayAreaOfUse',
  EnableCustomerEditingOfAreaOfUse = 'enableCustomerEditingOfAreaOfUse',
  EnableCustomerEditingOfAreaOfUseAdmin = 'enableCustomerEditingOfAreaOfUseAdmin',
  FeedIntervalApprovalOrders = 'feedIntervalApprovalOrders',
  FeedIntervalDiscontinuedArticlesCheck = 'feedIntervalDiscontinuedArticlesCheck',
  FeedIntervalAreaOfUse = 'feedIntervalAreaOfUse',
  FeedIntervalUsermanagement = 'feedIntervalUsermanagement',
  FeedIntervalOperationalMessage = 'feedIntervalOperationalMessage',
  FeedIntervalConfigurationUpdates = 'feedIntervalConfigurationUpdates',
  EnableDisplayMyUsers = 'enableDisplayMyUsers',
  EnablePromotionalContent = 'enablePromotionalContent',
  EnableDisplayOperationalMessage = 'enableDisplayOperationalMessage',
  EnableDisplayCarryBeyondLoadingBayFeePerOrderLine = 'enableDisplayCarryBeyondLoadingBayFeePerOrderLine',
  EnableDisplayComparativePrices = 'enableDisplayComparativePrices',
  EnableDisplayOfPrices = 'enableDisplayOfPrices',
  EnableDisplayIfYouBuyMoreYouSaveCurrency = 'enableDisplayIfYouBuyMoreYouSaveCurrency',
  EnableDisplayIfYouBuyMoreYouSavePercent = 'enableDisplayIfYouBuyMoreYouSavePercent',
  EnableDisplayIncludeYsfeFeeInPriceFile = 'enableDisplayIncludeYsfeFeeInPriceFile',
  EnableDisplayIncludeYfesFeeInPriceFile = 'enableDisplayIncludeYfesFeeInPriceFile',
  EnableDisplayIndentArticles = 'enableDisplayIndentArticles',
  EnableDisplayOrderInformation = 'enableDisplayOrderInformation',
  EnableCustomerUniqueArticlesInPriceFile = 'enableCustomerUniqueArticlesInPriceFile',
  EnableDisplayDeliveryInformation = 'enableDisplayDeliveryInformation',
  EnableDisplaySalesOrgAlertMessage = 'enableDisplaySalesOrgAlertMessage',
  EnableDisplayOrganizationNumber = 'enableDisplayOrganizationNumber',
  EnableDisplayExtraEmails = 'enableDisplayExtraEmails',
  EnableDisplayOutgoingArea = 'enableDisplayOutgoingArea',
  EnableDisplayOutgoingAssortmentWhenNotAllowed = 'enableDisplayOutgoingAssortmentWhenNotAllowed',
  EnableDisplayNewArticlesAndProductsOverlay = 'enableDisplayNewArticlesAndProductsOverlay',
  EnableDisplayUseStandardPricesInPriceFile = 'enableDisplayUseStandardPricesInPriceFile',
  EnableDisplayEnvironmentalIconsOnProductTile = 'enableDisplayEnvironmentalIconsOnProductTile',
  EnableDisplayTranslationKeyDebug = 'enableDisplayTranslationKeyDebug',
  EnableDisplayInvoiceEmail = 'enableDisplayInvoiceEmail',
  EnableThumbsUp = 'enableThumbsUp',
  EnableDisplayGrainDirection = 'enableDisplayGrainDirection',
  EnableDisplayEnvelopeData = 'enableDisplayEnvelopeData',
  EnableDisplayCustomerArticleNumbers = 'enableDisplayCustomerArticleNumbers',
  EnableDisplayBrandAndIdentifier = 'enableDisplayBrandAndIdentifier',
  EnableDisplayEshopArticleText = 'enableDisplayEshopArticleText',
  EnableDisplayExtendedDeliveryTimeIcon = 'enableDisplayExtendedDeliveryTimeIcon',
  EnableDisplayOldArticleNumber = 'enableDisplayOldArticleNumber',
  EnableDisplaySmallOrderFeeHeadsUpMessage = 'enableDisplaySmallOrderFeeHeadsUpMessage',
  EnableDisplaySpecialArticleMessage = 'enableDisplaySpecialArticleMessage',
  EnableForceShipToSelection = 'enableForceShipToSelection',
  EnableDisplayNewsletterSubscription = 'enableDisplayNewsletterSubscription',
  EnableDefaultShipToPreselection = 'enableDefaultShipToPreselection',
  EnableProductRecommendations = 'enableProductRecommendations',
  DisplayCuttingAndReamingMessages = 'displayCuttingAndReamingMessages',
  DefaultPdpArticleSortingValue = 'defaultPdpArticleSortingValue',
  EnableWarehouseSalesBlock = 'enableWarehouseSalesBlock',
  AccountActivationTokenValidTime = 'accountActivationTokenValidTime',
  UnattendedNewUserTimeLimit = 'unattendedNewUserTimeLimit',
  AlternateSalesOrg = 'alternateSalesOrg',
  CuttingMinimumMargin = 'cuttingMinimumMargin',
  NoComparativePriceArticleCodes = 'noComparativePriceArticleCodes',
  CuttingSortOrder = 'cuttingSortOrder',
  CuttingDisabledTemplates = 'cuttingDisabledTemplates',
  CuttingPredefinedFormats = 'cuttingPredefinedFormats',
  CuttingPackagings = 'cuttingPackagings',
  CuttingDefaultPackaging = 'cuttingDefaultPackaging',
  CuttingSmallPackagingMaxSize = 'cuttingSmallPackagingMaxSize',
  CuttingSmallPackagingOptions = 'cuttingSmallPackagingOptions',
  CuttingLargePackagingOptions = 'cuttingLargePackagingOptions',
  CuttingDustFreeDefaultValue = 'cuttingDustFreeDefaultValue',
  CuttingPerpendicularDefaultValue = 'cuttingPerpendicularDefaultValue',
  FullPalletUpgradeRemainingPercent = 'fullPalletUpgradeRemainingPercent',
  LocalArticleStatusFilterValues = 'localArticleStatusFilterValues',
  MaxMostOrderedArticles = 'maxMostOrderedArticles',
  MaxLastOrderedArticles = 'maxLastOrderedArticles',
  EveningExpressShippingCondition = 'eveningExpressShippingCondition',
  EveningExpressStartTime = 'eveningExpressStartTime',
  EveningExpressStopTime = 'eveningExpressStopTime',
  OnlineChatTopicId = 'onlineChatTopicId',
  DeliveryNoteSapTextFieldId = 'deliveryNoteSapTextFieldId',
  RegNumberFieldRegularExpressionRule = 'regNumberFieldRegularExpressionRule',
  PhoneNumberFieldRegularExpressionRule = 'phoneNumberFieldRegularExpressionRule',
  ReelCuttingMaxNumberOfTargetReels = 'reelCuttingMaxNumberOfTargetReels',
  ReelCuttingMinimumMargin = 'reelCuttingMinimumMargin',
  ReelCuttingCutWidth = 'reelCuttingCutWidth',
  ReelCuttingLastTargetReelMinimumWidth = 'reelCuttingLastTargetReelMinimumWidth',
  SmallOrderFeeLimit = 'smallOrderFeeLimit',
  SmallOrderFeeLowerLimit = 'smallOrderFeeLowerLimit',
  SmallOrderFee = 'smallOrderFee',
  OneTimeCustomerContactPersonId = 'oneTimeCustomerContactPersonId',
  CustomContactPersonId = 'customContactPersonId',
  SolrQueryStatsQueryCharMinimumLength = 'solrQueryStatsQueryCharMinimumLength',
  DefaultAddonToggleState = 'defaultAddonToggleState',
  DefaultCustomerAssortmentToggleState = 'defaultCustomerAssortmentToggleState',
  DefaultSiteUid = 'defaultSiteUid',
  DefaultOrderType = 'defaultOrderType',
  DefaultDistributionChannel = 'defaultDistributionChannel',
  DeliveryDaysDefault = 'deliveryDaysDefault',
  FreeStockDistributionChannel = 'freeStockDistributionChannel',
  FreeStockOrderDocType = 'freeStockOrderDocType',
  FreeStockFillupDistributionChannel = 'freeStockFillupDistributionChannel',
  FreeStockFillupOrderDocType = 'freeStockFillupOrderDocType',
  InvoicedStockDistributionChannel = 'invoicedStockDistributionChannel',
  InvoicedStockOrderDocType = 'invoicedStockOrderDocType',
  InvoicedStockFillupDistributionChannel = 'invoicedStockFillupDistributionChannel',
  InvoicedStockFillupOrderDocType = 'invoicedStockFillupOrderDocType',
  ConsignmentStockDistributionChannel = 'consignmentStockDistributionChannel',
  ConsignmentStockOrderDocType = 'consignmentStockOrderDocType',
  ConsignmentStockShippingCondition = 'consignmentStockShippingCondition',
  ConsignmentStockFillupDistributionChannel = 'consignmentStockFillupDistributionChannel',
  ConsignmentStockFillupOrderDocType = 'consignmentStockFillupOrderDocType',
  ConsignmentStockFillupShippingCondition = 'consignmentStockFillupShippingCondition',
  DaysArticlesAndProductsAreConsideredNew = 'daysArticlesAndProductsAreConsideredNew',
  OciValueProviderNameAvailableValues = 'ociValueProviderNameAvailableValues',
  OciValueProviderName = 'ociValueProviderName',
  AvailablePunchOutUnitProviders = 'availablePunchOutUnitProviders',
  PunchOutUnitProvider = 'punchOutUnitProvider',
  OrderNoteLabel = 'orderNoteLabel',
  OrderNoteRegularExpressionRule = 'orderNoteRegularExpressionRule',
  OrderLineNoteLabel = 'orderLineNoteLabel',
  OrderLineNoteRegularExpressionRule = 'orderLineNoteRegularExpressionRule',
  OrderReportConfigCode = 'orderReportConfigCode',
  InvoiceReportConfigCode = 'invoiceReportConfigCode',
  UserReportConfigCode = 'userReportConfigCode',
  DataImportReportConfigCode = 'dataImportReportConfigCode',
  ShoppingListPdfExportConfig = 'shoppingListPdfExportConfig',
  AreaOfUseSectionReportConfigCode = 'areaOfUseSectionReportConfigCode',
  EnableUsingProductCodeColumnDuringAoUSectionImport = 'enableUsingProductCodeColumnDuringAoUSectionImport',
  PriceFileSupplierName = 'priceFileSupplierName',
  PriceFileSupplierId = 'priceFileSupplierId',
  WarehousesWithPickupUnavailable = 'warehousesWithPickupUnavailable',
  NextDeliveryDayAdjustment = 'nextDeliveryDayAdjustment',
  LastAndMostOrderedArticlesMode = 'lastAndMostOrderedArticlesMode',
  OrderHistoryMode = 'orderHistoryMode',
  OrderHistoryFetchMode = 'orderHistoryFetchMode',
  TrackAndTraceHistoryMode = 'trackAndTraceHistoryMode',
  QuickAddDefaultCollapsed = 'quickAddDefaultCollapsed',
  BooleanOption = 'booleanOption',
  NumberOption = 'numberOption',
  StringOption = 'stringOption',
  EnableDisplayApprovalUsers = 'enableDisplayApprovalUsers',
  EnableDisplayApprovalOrders = 'enableDisplayApprovalOrders',
  EnableDisplayTitleInProfilePage = 'enableDisplayTitleInProfilePage',
  ToggleOrderApproverForRole = 'toggleOrderApproverForRole',
  AlwaysApproveOrdersForUser = 'alwaysApproveOrdersForUser',
  EnableMasterApproval = 'enableMasterApproval',
  EnableOrderApprovalMessageByApprover = 'enableOrderApprovalMessageByApprover',
  IsMandatoryOrderApprovalMessageByApprover = 'isMandatoryOrderApprovalMessageByApprover',
  EnableOrderCommentsFromApprover = 'enableOrderCommentsFromApprover',
  EnableOrderCommentsFromBuyer = 'enableOrderCommentsFromBuyer',
  EnableMandatoryApproverComments = 'enableMandatoryApproverComments',
  EnableMandatoryBuyerComments = 'enableMandatoryBuyerComments',
  EnablePurchaseLimits = 'enablePurchaseLimits',
  PurchaseLimitTypes = 'purchaseLimitTypes',
  PurchaseLimitResolutionType = 'purchaseLimitResolutionType',
  PurchaseLimitResolutionAvailableTypes = 'purchaseLimitResolutionAvailableTypes',
  PurchaseWithoutActiveLimitResolution = 'purchaseWithoutActiveLimitResolution',
  PurchaseWithoutActiveLimitResolutionTypes = 'purchaseWithoutActiveLimitResolutionTypes',
  RequirePurchaseLimitForCheckout = 'requirePurchaseLimitForCheckout',
  EnablePurchaseLimitsAdmin = 'enablePurchaseLimitsAdmin',
  EnablePurchaseLimitsUser = 'enablePurchaseLimitsUser',
  EvaluatePurchaseLimitUsageInCheckoutUsingPermissionResults = 'evaluatePurchaseLimitUsageInCheckoutUsingPermissionResults',
  CheckoutHiddenMessageCodes = 'checkoutHiddenMessageCodes',
  EnableShowFullSearchResults = 'enableShowFullSearchResults',
  EnableUnconfiguredSharedCustomerUniqueArticles = 'enableUnconfiguredSharedCustomerUniqueArticles',
  EnableEshopTooltipWildcardSearch = 'enableEshopTooltipWildcardSearch',
  DisablePrincipalConfigChangeOnUsersInOrgWithRBU = 'disablePrincipalConfigChangeOnUsersInOrgWithRBU',
  EnableSearchScore = 'enableSearchScore',
  EnableDisplayDefaultShipToAsSoldToAddress = 'enableDisplayDefaultShipToAsSoldToAddress',
  EnableDisplayCurrentSoldToInTopMenu = 'enableDisplayCurrentSoldToInTopMenu',
  InvoiceNoteEditable = 'invoiceNoteEditable',
  InvoiceNoteMandatory = 'invoiceNoteMandatory',
  AvailableDiscontinuedArticleSubstitutionType = 'availableDiscontinuedArticleSubstitutionType',
  DiscontinuedArticleSubstitutionTypes = 'discontinuedArticleSubstitutionTypes',
  GdprCompliantEmailRecepients = 'gdprCompliantEmailRecepients',
  ArticleSearchQueryContext = 'articleSearchQueryContext',
  ArticleSearchQueryContextValues = 'articleSearchQueryContextValues',
  ProductSearchQueryContext = 'productSearchQueryContext',
  ProductSearchQueryContextValues = 'productSearchQueryContextValues',
  SearchTermFacetMappingConfigurationBucketId = 'searchTermFacetMappingConfigurationBucketId',
  AvailableOrderHistoryColumns = 'availableOrderHistoryColumns',
  EnableOrderHistoryColumns = 'enableOrderHistoryColumns',
  CheckoutConfirmationSecondaryStyling = 'checkoutConfirmationSecondaryStyling',
  EnableWarehouseOnOrderLine = 'enableWarehouseOnOrderLine',
  HideColumnAttributesForLocalSalesTextArticles = 'hideColumnAttributesForLocalSalesTextArticles',
  AvailableOnboardingCustomFeatures = 'availableOnboardingCustomFeatures',
  EnableOnboardingCustomFeatures = 'enableOnboardingCustomFeatures',
  AvailableNonSellableInquiryFormTimeSlots = 'availableNonSellableInquiryFormTimeSlots',
  NonSellableInquiryFormTimeSlots = 'nonSellableInquiryFormTimeSlots',
  ProductDetailPageType = 'productDetailPageType',
  EnableSecondaryArticleRowVariant = 'enableSecondaryArticleRowVariant',
  UseArticleUnitAsDefault = 'useArticleUnitAsDefault',
  EnableDisplayMessagesTabOnOrderHistory = 'enableDisplayMessagesTabOnOrderHistory',
  EnableOrderNotesSavedModeOnCheckout = 'enableOrderNotesSavedModeOnCheckout',
  SearchBoxDisplayArticlesThreshold = 'searchBoxDisplayArticlesThreshold',
  SearchResultsSecondary = 'searchResultsSecondary',
  CatalogProductItemSecondary = 'catalogProductItemSecondary',
  EnableDisplaySeparateNameFields = 'enableDisplaySeparateNameFields',
  RedirectAfterLogin = 'redirectAfterLogin',
  RedirectAfterLoginValues = 'redirectAfterLoginValues',
  EnableAdditionalSearchBoxDisplayArticlesLogic = 'enableAdditionalSearchBoxDisplayArticlesLogic',
  EnableDisplayDeliveryAddressIdOnCheckout = 'enableDisplayDeliveryAddressIdOnCheckout',
  EnableAutoResolveMovedOrderlineToDateMessage = 'enableAutoResolveMovedOrderlineToDateMessage',
  EnablePickupDeliveryWithOnlinePayment = 'enablePickupDeliveryWithOnlinePayment',
  MinimumOrderIndication = 'minimumOrderIndication',
  ArticleFetchRowsForSearch = 'articleFetchRowsForSearch',
  ArticleFetchRowsForPdp = 'articleFetchRowsForPdp',
  EnableDisplayOutletBadgeForLoggedOut = 'enableDisplayOutletBadgeForLoggedOut',
  EnableProductOutletBadgeOnlyIfAllArticlesAreOutlet = 'enableProductOutletBadgeOnlyIfAllArticlesAreOutlet',
  MaxHistoryIntervalMonths = 'maxHistoryIntervalMonths',
  EnableCustomerCostCenter = 'enableCustomerCostCenter',
  EnableDeliveryAddressPrefillForOneTimeCustomer = 'enableDeliveryAddressPrefillForOneTimeCustomer',
  DisplayInquiryButton = 'displayInquiryButton',
  EnabledExperimentalFeatures = 'enabledExperimentalFeatures',
  EnableSearchScoreExplanation = 'enableSearchScoreExplanation',
  OrderHistoryExportLimit = 'orderHistoryExportLimit',
  QuickAddOnCustomerMaterialNumber = 'quickAddOnCustomerMaterialNumber',
  EnableCarryBeyondLoadingBay = 'enableCarryBeyondLoadingBay',
  CarryBeyondLoadingBayOption = 'carryBeyondLoadingBayOption',
  EnablePriceFilesPriceDateField = 'enablePriceFilesPriceDateField',
}

/** Response configuration. This is the list of fields that should be returned in the response body. Examples: BASIC, DEFAULT, FULL. */
export enum GetComponentsByIdsAndUserParamsFieldsEnum {
  BASIC = 'BASIC',
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
}

/** Response configuration. This is the list of fields that should be returned in the response body. Examples: BASIC, DEFAULT, FULL. */
export enum GetComponentByIdAndUserParamsFieldsEnum {
  BASIC = 'BASIC',
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
}

/** Response configuration. This is the list of fields that should be returned in the response body. Examples: BASIC, DEFAULT, FULL. */
export enum GetPageWithUserParamsFieldsEnum {
  BASIC = 'BASIC',
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
}

/** Page type */
export enum GetPageWithUserParamsPageTypeEnum {
  ContentPage = 'ContentPage',
  ProductPage = 'ProductPage',
  CategoryPage = 'CategoryPage',
  CatalogPage = 'CatalogPage',
}

/** Response configuration. This is the list of fields that should be returned in the response body. Examples: BASIC, DEFAULT, FULL. */
export enum GetPageByIdAndUserParamsFieldsEnum {
  BASIC = 'BASIC',
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
}

/** Response configuration. This is the list of fields that should be returned in the response body. Examples: BASIC, DEFAULT, FULL. */
export enum GetAllPagesWithUserParamsFieldsEnum {
  BASIC = 'BASIC',
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
}

/** Page type. */
export enum GetAllPagesWithUserParamsPageTypeEnum {
  ContentPage = 'ContentPage',
  ProductPage = 'ProductPage',
  CategoryPage = 'CategoryPage',
  CatalogPage = 'CatalogPage',
}
