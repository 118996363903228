import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SearchTermFacetMappingHint } from '../../../../../core/model';

@Component({
  selector: 'py-search-hints',
  templateUrl: './search-hints.component.html',
  styleUrls: ['./search-hints.component.scss'],
})
export class SearchHintsComponent {
  @Input() form: UntypedFormGroup;
  @Input() hints: SearchTermFacetMappingHint[];

  onHintClick(hint: SearchTermFacetMappingHint): void {
    this.form.get('search').setValue(hint);
  }
}
