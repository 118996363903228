import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { PipesModule } from '../../../pipes';
import { ReactiveFormTextareaComponent } from './reactive-form-textarea.component';

@NgModule({
  imports: [CommonModule, NzIconModule, I18nModule, ReactiveFormsModule, PipesModule],
  declarations: [ReactiveFormTextareaComponent],
  exports: [ReactiveFormTextareaComponent],
})
export class ReactiveFormTextareaModule {}
