import { Occ } from '../occ';
import { ShoppingListAccessType } from './cart.model';
import { InvoicePaymentStatus, OrderApprovalStatus } from './order.model';
import { CustomerStatusEnum } from './org-unit.model';

export enum DiscontinuedArticleViewType {
  Product = 'Product',
  Article = 'Article',
}

export interface SearchParams {
  key: string;
  page?: number;
  count?: number;
  sort?: string;
}

export interface InvoiceHistorySearchParams extends SearchParams {
  dateFrom?: string;
  dateTo?: string;
  paymentStatus?: InvoicePaymentStatus;
}

export interface OrderHistorySearchParams extends SearchParams {
  orderNumber?: string;
  dateFrom?: string;
  dateTo?: string;
  shipToId?: string;
  userUid?: string;
  sort?: string;
  onlyReinvoicingOrders?: boolean;
}

export interface OrderHistoryFiltersSearchParams extends SearchParams {
  dateFrom?: string;
  dateTo?: string;
  searchText?: string;
}

export interface MiniShoppingListsSearchParams extends SearchParams {
  name?: string;
  accessType?: ShoppingListAccessType;
}

export interface WritableShoppingListsSearchParams extends SearchParams {
  name?: string;
}

export interface B2BUserSearchParams extends SearchParams {
  searchText?: string;
  roles?: string[];
  statuses?: CustomerStatusEnum[];
  soldTos?: string[];
  orderCard?: string;
  b2bPermission?: string;
  shoppingListCode?: string;
  configurationRoles?: string[];
  excludedCustomerIds?: string[];
}

export interface OrderCardAdminSearchParams extends SearchParams {
  searchTerm?: string;
  assignablePrincipals?: string[];
  status?: string;
}

export interface EcConformityProductDocumentsSearchParams extends SearchParams {
  q?: string;
  brands?: string[];
}

export import Pagination = Occ.Pagination;

export import Sort = Occ.Sort;

// Hybris standard

export interface SearchConfig {
  pageSize?: number;
  currentPage?: number;
  sort?: string;
}

export interface PermissionSearchParams extends SearchConfig {
  dateFrom?: string;
  dateTo?: string;
  status?: string;
  search?: string;
  shipTo?: string;
  soldTo?: string;
  user?: string;
}

export interface PermissionOrdersSearchParams extends SearchConfig {
  shipTo?: string;
  soldTo?: string;
  user?: string;
}

export interface ShipToFilterParams extends SearchConfig {
  search?: string;
  permissionCode?: string;
  onlyConnected?: boolean;
  soldTos?: string[];
  connectedToPermissionCode?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SoldToFilterParams extends ShipToFilterParams {}

export interface OrderApprovalSearchParams extends SearchParams {
  searchText?: string;
  dateFrom?: string;
  dateTo?: string;
  buyer?: string;
  status?: OrderApprovalStatus;
  roles?: string[];
  soldTo?: string;
  shipTo?: string;
  contactPerson?: string;
  approver?: string;
  managedByCurrentUser?: boolean;
  onlyReinvoicingOrders?: boolean;
}
