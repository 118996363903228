import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export abstract class AbstractReactiveFormInput {
  abstract getGroup(): UntypedFormGroup;

  abstract getField(): AbstractControl;

  abstract isIncludeGroupErrors(): boolean;

  /**
   * Includes errors from the group if includeGroupErrors is true
   */
  errors(): ValidationErrors {
    const fieldErrors = this.getField().errors;
    if (this.isIncludeGroupErrors()) {
      return { ...this.getGroup().errors, ...fieldErrors };
    }
    return fieldErrors;
  }

  /**
   * Checks validity of field and group if group check enabled
   */
  isInvalid(): boolean {
    const field = this.getField();
    return (
      field.dirty && ((this.isIncludeGroupErrors() && Object.keys(this.getGroup().errors || {}).length > 0) || field.invalid)
    );
  }

  isDisabled(): boolean {
    const field = this.getField();
    return field?.disabled;
  }
}
