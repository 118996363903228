import { OccConfig } from '@spartacus/core';

const base = 'py/users/${userId}';
const orgCustomers = base + '/orgCustomers';
const orgCustomer = orgCustomers + '/${orgCustomerId}';

export const defaultOccOrganizationConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        b2bUsers: orgCustomers,
        b2bUser: orgCustomer,
        b2bUserRole: orgCustomer + '/role',
        b2bUserSoldTos: orgCustomer + '/sold-tos',
        b2bUserProfile: orgCustomer + '/profile',
        b2bUserStatuses: '/py/customer-statuses',
        userRoles: base + '/user-roles',
        status: orgCustomers + '/status',
        b2bUserShipTosAssignment: orgCustomer + '/ship-tos',
        usersExportXls: orgCustomers + '/export/xls',
        usersInsertImportXls: orgCustomers + '/import/xls',
        usersUpdateImportXls: orgCustomers + '/update/xls',
        usersImportFileTemplateXls: orgCustomers + '/import-template/xls',
      },
    },
  },
};
