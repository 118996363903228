import { HttpErrorModel as CxHttpErrorModel, PaginationModel, SortModel } from '@spartacus/core';
import { Subject } from 'rxjs';
import { AsyncReturnToken } from '../../shared';
import { Occ } from '../occ';
import { DataImportStatusEnum } from '../occ/occ-models/occ-generated.models';
import { Address } from './address.model';
import { Article } from './catalog.model';
import { CheckoutKey, OrderEntry } from './order.model';
import { B2BUnit } from './org-unit.model';
import { Pagination, Sort } from './search.model';
import { User } from './user.model';

export { BaseSite, BaseStore, Currency, Language } from '@spartacus/core';
export { UserSignUp } from '@spartacus/user/profile/root';

export interface B2BComment extends Occ.Comment {
  author?: User;
}

export interface SiteConfig {
  orderReturnEnabled?: boolean;
}

export import Time = Occ.Time;

export import Price = Occ.Price;

export interface ValueWithUnit {
  formattedValue?: string;
  value?: number;
  unit?: Unit;
}

export interface Unit extends Omit<Occ.Unit, 'isoCode'> {
  isocode?: string;
}

export import Warehouse = Occ.Warehouse;

export interface ShippingCondition {
  code?: string;
  description?: string;
}

export interface GeoPoint {
  latitude?: number;
  longitude?: number;
}

/**
 * deprecated, TODO: Awaiting BE, see ESV-5938
 */
export { PaginationModel };

/**
 * deprecated TODO: Awaiting BE, see ESV-5938
 */
export { SortModel };

export type LoaderError = HttpErrorModel | true;

export interface ErrorModel {
  message?: string;
  reason?: string;
  subject?: string;
  subjectType?: string;
  type?: string;
  translationKey?: string;
}

export class HttpErrorModel extends CxHttpErrorModel {
  error?: any | null;
}

export interface UserInputCheck {
  valid: boolean;
  message?: string;
}

export interface RegistrationNumberInputCheckResult {
  valid: boolean;
  message?: string;
}
export interface NameInputCheckResult {
  valid: boolean;
  message?: string;
}

export interface PhoneNumberCheckResult {
  valid: boolean;
  message?: string;
}

export interface ResetPasswordEmailUserInputCheck extends UserInputCheck {
  multipleUsers: boolean;
}

export interface PrincipalConfiguration extends Occ.PrincipalConfiguration {
  value: string | number | boolean | null;
}

/**
 * PrincipalConfigurationGroup
 */
export interface PrincipalConfigurationGroup extends Occ.PrincipalConfigurationGroup {
  configurations?: PrincipalConfiguration[];
}

/**
 * PrincipalConfigurationUpdateValue
 */
export import PrincipalConfigurationUpdateValue = Occ.PrincipalConfigurationValueWsDTO;

export import SpecialOpeningDay = Occ.SpecialOpeningDay;

export import WeekdayOpeningDay = Occ.WeekdayOpeningDay;

export import OpeningSchedule = Occ.OpeningSchedule;

export interface DeliveryDateInfo extends Occ.DeliveryDateInfo {
  sameDayDeliveryInfo?: SameDayDeliveryInfo;
}

export interface SameDayDeliveryInfo {
  available?: boolean;
  fee?: Price;
}

export interface CompanySignUpPage {
  accountBeingProcessed?: boolean;
  hasUniqueIdentifiedCustomer?: boolean;
  isCustomer?: boolean;
  hasUniqueIdentifiedRoot?: boolean;
  contactPersonOnly?: boolean;
  /**
   * Pagination info
   */
  pagination?: Pagination;
  /**
   * Result list
   */
  results?: CompanySignUp[];
  sorts?: Sort[];
}

export interface CompanySignUp {
  address?: Address;
  companyId?: string;
  companySearchServiceType?: CompanySearchServiceType;
  candidateSoldToExists?: boolean;
  designatedRootUnit?: B2BUnit;
  candidateSoldTos?: string[];
  companyName?: string;
  organizationNumber?: string;
  soldToId?: string;
  taxCode?: string;
}

export interface CompanySignUpRequest {
  count?: number;
  fulltext?: string;
  searchTerm?: string;
  isEmail?: boolean;
  page?: number;
  countryCode?: string;
}

export enum AlertType {
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
  Success = 'success',
  Processing = 'processing',
  Notification = 'notification',
}

export enum SpinnerType {
  Default = 'default',
  Dark = 'dark',
  Light = 'light',
}

export import OperationalMessage = Occ.OperationalMessage;

export interface PanelLookup {
  // analog to StockLookup? TODO check w/ team
  panelName?: string;
  productCode?: string;
  articleCode?: string;
  otherParameters?: any;
}

export interface PunchOutData {
  identifier?: string;
  type?: string;
}

export interface OciPunchOutData extends PunchOutData {
  cancel?: string;
  buyLabel?: string;
  cancelLabel?: string;
  hookUrl?: string;
  newItemVendor?: string;
  returnTarget?: string;
}

export interface CxmlPunchOutData extends PunchOutData {
  buyerCookie?: string;
  browserFormPost?: string;
  firstName?: string;
  lastName?: string;
}

export interface ActionResponse {
  message?: string;
  success?: boolean;
}

export interface FailedAddToCartArticle {
  articleNumber?: string;
  errorMessage?: string;
  quantity?: number; // int64
}

export interface MultipleArticlesAddedResponse extends ActionResponse {
  itemsAdded?: number;
  failedArticlesData?: FailedAddToCartArticle[];
}

export interface NextOrderProcessStep {
  step: OrderProcessStep;
  key?: CheckoutKey;
  orderCode?: string;
  sapOrderCode?: string;
}

export enum OrderProcessStep {
  CART = 'CART',
  APPROVAL_ORDER = 'APPROVAL_ORDER',
  ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
  PAYMENT = 'PAYMENT',
  ERROR = 'ERROR',
}

export interface ArticlePriceLookup {
  articleNumber?: string;
  quantity?: number;
  unit?: string;
  userId?: string;
}

export interface StockLookup {
  articleNumber?: string;
  quantity?: number;
  unit?: string;
  userId?: string;
}

export interface ArticlePrice extends Occ.PriceResponse {
  attempts?: number;
}

export import StockInfoDetails = Occ.ArticlePriceStock;

export enum StockStatus {
  InStock = 1,
  LowInStock,
  OutOfStock,
  SalesBlocked,
  OutOfStockOnWebshop,
}

export import StockInfoComparativePrice = Occ.ComparativePriceEntry;

export import WarehouseStatus = Occ.WarehouseArticle;

export import LocalArticleWarehouseStatus = Occ.LocalArticleWarehouseStatus;

export import WarehouseStock = Occ.WarehouseStock;

export import ArticleStockInfoList = Occ.ArticleStockInfoList;

export import ArticleStockInfo = Occ.ArticleStockInfo;

export import WarehouseStockInfo = Occ.StockInfo;

export interface KeyedResultArray<T> {
  key: string;
  result: Array<T>;
}

export enum PalletFlagType {
  CUSTOMER = 'CUSTOMER',
  MACHINE_NO = 'MACHINE_NO',
  OBJECT = 'OBJECT',
}

export interface GrainDirection {
  type: GrainDirectionType;
  translationKey: string;
}

export enum GrainDirectionType {
  LONG_GRAIN = 'S',
  SHORT_GRAIN = 'B',
  ANY = 'EMPTY',
}

export import SiteFamilyConfig = Occ.SiteFamilyConfig;

export import OnlineChatConfig = Occ.OnlineChatConfig;

export interface ListModel {
  ids: string[];
  pagination?: PaginationModel;
  sorts?: SortModel[];
}

export interface EntitiesModel<T> {
  values: T[];
  pagination?: Pagination;
  sorts?: Sort[];
}

export import SimpleItem = Occ.SimpleItem;

export import DataImportStatus = DataImportStatusEnum;

export interface DataImport extends Occ.DataImport {
  status?: DataImportStatus;
}

export enum CompanySearchServiceType {
  BISNODE = 'BISNODE',
  DNB = 'DNB',
}

export enum HorizontalAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface ArticleCarouselItem {
  article: Article;
  entry?: OrderEntry;
}

export enum ArticleCarouselActionMode {
  ENTRY_INTERACTION_MODE = 'ENTRY_INTERACTION_MODE',
  ADD_TO_CART_MODE = 'ADD_TO_CART_MODE',
}

export interface CancelOrderReturnDraftModalData {
  modalConfirmed$: Subject<void>;
  modalDismissed$: Subject<void>;
  wasCancelOrderReturnDraftButtonClicked?: boolean;
}

export interface ActionTokenPayload<T> {
  actionToken?: AsyncReturnToken<T>;
}
