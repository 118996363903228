import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { AbstractReactiveFormInput } from '../abstract-reactive-form-input';

@Component({
  selector: 'py-reactive-form-textarea',
  templateUrl: './reactive-form-textarea.component.html',
  styleUrls: ['./reactive-form-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactiveFormTextareaComponent extends AbstractReactiveFormInput {
  @Input()
  label: string;

  @Input()
  placeholder = '';

  @Input()
  optional?: boolean;

  @Input()
  fieldName: string;

  @Input()
  group: UntypedFormGroup;

  @Input()
  description?: string;

  @Input()
  showOptionalHint? = true;

  @Input()
  includeGroupErrors? = false;

  @Input()
  hideErrorMessages? = false;

  @Input()
  showSuccessState? = false;

  @Input()
  maxLength?: number;

  constructor() {
    super();
  }

  getField(): AbstractControl {
    return this.getGroup().get(this.fieldName);
  }

  getGroup(): UntypedFormGroup {
    return this.group;
  }

  isIncludeGroupErrors(): boolean {
    return this.includeGroupErrors;
  }

  get showDescription(): boolean {
    return !!this.getField().value;
  }

  get fieldValueLength(): number {
    return this.getField().value?.length;
  }
}
