import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigInitializerService, deepMerge, OccEndpointsService } from '@spartacus/core';
import { i18n, InitOptions } from 'i18next';
import HttpApi, { HttpBackendOptions, RequestCallback } from 'i18next-http-backend';
import { Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { BaseSiteService, LanguageService } from '../../site-context/facade';
import { I18nStateTransferService } from '../services';

export function i18nextInit(
  i18next: i18n,
  configInit: ConfigInitializerService,
  languageService: LanguageService,
  baseSiteService: BaseSiteService,
  occEndpointsService: OccEndpointsService,
  httpClient: HttpClient,
  siteContextI18nextSynchronizer: SiteContextI18nextSynchronizer,
  i18nStateTransferService: I18nStateTransferService
): () => Promise<any> {
  return () =>
    configInit
      .getStable('i18n')
      .pipe(
        tap((config) => {
          let i18nextConfig: InitOptions = {
            ns: [],
            // ESVCX-6056: Setting fallbackLng field results in additional request and assigning an incorrect chunk to the language.
            // As a result, changing the language for chunks does not work properly. Fallbacks for current chunk are fully supported on the backend side.
            // fallbackLng: config.i18n.fallbackLang,
            fallbackLng: false,
            debug: config.i18n.debug,
            interpolation: {
              escapeValue: false,
              skipOnVariables: false,
            },
            resources: deepMerge({}, config.i18n.resources ?? {}, i18nStateTransferService.getTranslationResources()),
            partialBundledLanguages: true,
          };
          i18next.use(HttpApi);
          return baseSiteService.getActive().subscribe(() => {
            const loadPath = () => {
              return languageService
                .getActive()
                .pipe(
                  take(1),
                  map((l) => `${occEndpointsService.buildUrl('translation')}/${config.i18n.backend.loadPath}?lang=${l}`)
                )
                .toPromise();
            };
            const backend: HttpBackendOptions = {
              loadPath,
              request: i18nextGetHttpClient(httpClient, i18nStateTransferService),

              // Disable the periodical reloading. Otherwise SSR would not finish due to the pending task `setInterval()`
              // See source code of `i18next-http-backend` : https://github.com/i18next/i18next-http-backend/blob/00b7e8f67abf8372af17529b51190a7e8b17e3d8/lib/index.js#L40-L41
              reloadInterval: false,
            };
            i18nextConfig = { ...i18nextConfig, backend };
            return i18next.init(i18nextConfig, () => {
              siteContextI18nextSynchronizer.init(i18next, languageService);
            });
          });
        })
      )
      .toPromise();
}

@Injectable({ providedIn: 'root' })
export class SiteContextI18nextSynchronizer implements OnDestroy {
  sub: Subscription;

  init(i18next: i18n, language: LanguageService) {
    // always update language of i18next on site context (language) change
    this.sub = this.sub ?? language.getActive().subscribe((lang) => i18next.changeLanguage(lang));
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}

/**
 * Returns a function appropriate for i18next to make http calls for JSON files.
 * See docs for `i18next-http-backend`: https://github.com/i18next/i18next-http-backend#backend-options
 *
 * It uses Angular HttpClient under the hood, so it works in SSR.
 * @param httpClient Angular http client
 */
export function i18nextGetHttpClient(
  httpClient: HttpClient,
  i18nStateTransferService: I18nStateTransferService
): (options: HttpBackendOptions, url: string, payload: object | string, callback: RequestCallback) => void {
  return (_options: HttpBackendOptions, url: string, _payload: object | string, callback: RequestCallback) => {
    httpClient.get(url, { responseType: 'text' }).subscribe(
      (data) => {
        // Transfer translations from SSR context to browser
        i18nStateTransferService.updateTranslationResourceData(url, data);
        callback(null, { status: 200, data });
      },
      (error) =>
        callback(error, {
          // a workaround for https://github.com/i18next/i18next-http-backend/issues/82
          data: null as any,
          status: error.status,
        })
    );
  };
}
