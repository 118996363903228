import { RouteLoadStrategy, RoutesConfig, RoutingConfig } from '@spartacus/core';

export const defaultRoutesConfig: RoutesConfig = {
  home: {
    paths: [''],
  },
  notFound: {
    paths: ['not-found'],
  },
  loading: {
    paths: ['loading'],
  },
  login: {
    paths: ['login'],
    protected: false,
    authFlow: true,
  },
  register: {
    paths: ['register'],
    protected: false,
    authFlow: true,
  },
  logout: {
    paths: ['logout'],
    protected: false,
    authFlow: true,
  },
  resetPassword: {
    paths: ['reset-password'],
    protected: false,
    authFlow: true,
  },
  cart: {
    paths: ['cart'],
  },
  addon: {
    translationKey: 'common.url.addonKeyword_hint',
    paths: ['catalog/addons/:title'],
  },
  catalog: {
    translationKey: 'common.url.catalogKeyword_hint',
    paths: [
      'catalog',
      'catalog/:categoryName1',
      'catalog/:categoryName1/:categoryName2',
      'catalog/:categoryName1/:categoryName2/:categoryName3',
      'catalog/:categoryName1/:categoryName2/:categoryName3/:categoryName4',
    ],
  },
  product: {
    translationKey: 'common.url.catalogKeyword_hint',
    paths: [
      'catalog/:articleCode',
      'catalog/:productName/print',
      'catalog/:categoryName1/:productName',
      'catalog/:categoryName1/:categoryName2/:productName',
      'catalog/:categoryName1/:categoryName2/:categoryName3/:productName',
      'catalog/:categoryName1/:categoryName2/:categoryName3/:categoryName4/:productName',
    ],
  },
  checkout: {
    paths: ['checkout'],
  },
  educations: {
    paths: ['educations'],
  },
  knowHow: {
    paths: ['know-how'],
  },
  userGuides: {
    paths: ['user-guides'],
  },
  orderConfirmation: {
    paths: ['order-confirmation'],
  },
  paymentProcessing: {
    paths: ['payment-processing'],
  },
  orderHistory: {
    paths: ['my-page/orders/order-history'],
  },
  orderHistoryDetails: {
    paths: ['my-page/orders/order-history/:orderCode'],
  },
  orderHistoryApprovalDetails: {
    paths: ['my-page/orders/order-history/approval/:orderCode'],
  },
  invoiceHistory: {
    paths: ['my-page/orders/invoice-history'],
  },
  accountingCodes: {
    paths: ['my-page/administration/accounting-codes'],
  },
  trackAndTrace: {
    paths: ['my-page/orders/track-and-trace'],
  },
  dashboard: {
    paths: ['my-page/dashboard'],
  },
  priceFileOrder: {
    paths: ['my-page/price-files/order'],
  },
  priceFiles: {
    paths: ['my-page/price-files'],
  },
  deliveryAddresses: {
    paths: ['my-page/delivery-addresses'],
  },
  shoppingLists: {
    paths: ['my-page/shopping-lists'],
  },
  shoppingList: {
    paths: ['my-page/shopping-lists/:code'],
  },
  manageShoppingListConnectUsers: {
    paths: ['my-page/shopping-lists/:code/connect-users'],
  },
  manageShoppingListConnectSoldTos: {
    paths: ['my-page/shopping-lists/:code/connect-sold-tos'],
  },
  statistics: {
    paths: ['my-page/statistics'],
  },
  myAssortment: {
    paths: ['my-page/my-assortment'],
  },
  freeStock: {
    paths: ['my-page/my-assortment/free-stock'],
  },
  newFreeStockArticles: {
    paths: ['my-page/my-assortment/free-stock/new'],
  },
  consignmentStock: {
    paths: ['my-page/my-assortment/consignment-stock'],
  },
  orderApproval: {
    paths: ['my-page/approvals/orders'],
  },
  orderApprovalDetails: {
    paths: ['my-page/approvals/orders/:orderCode'],
  },
  settingsProfile: {
    paths: ['my-page/settings/profile'],
  },
  settingsAccount: {
    paths: ['my-page/settings/account'],
  },
  settingsNotifications: {
    paths: ['my-page/settings/notifications'],
  },
  settingsConsents: {
    paths: ['my-page/settings/consents'],
  },
  services: {
    paths: ['services'],
  },
  areaOfUse: {
    paths: ['area-of-use'],
  },
  areaOfUseDetails: {
    paths: ['area-of-use/details'],
  },
  userOrderCard: {
    paths: ['my-page/order-cards/:code'],
  },
  userOrderCards: {
    paths: ['my-page/order-cards'],
  },
  manageOrderCard: {
    paths: ['my-page/administration/order-cards/manage/:code'],
  },
  manageOrderCards: {
    paths: ['my-page/administration/order-cards/manage'],
  },
  manageOrderCardConnectUsers: {
    paths: ['my-page/administration/order-cards/manage/:code/connect'],
  },
  purchaseLimitsAdmin: {
    paths: ['my-page/administration/purchase-limits'],
  },
  purchaseLimitsConnectUsers: {
    paths: ['my-page/administration/purchase-limits/:code/connect-users'],
  },
  purchaseLimitsUser: {
    paths: ['my-page/purchase-limits'],
  },
  users: {
    paths: ['my-page/users'],
  },
  usersCreate: {
    paths: ['my-page/users/create'],
  },
  userDetails: {
    paths: ['my-page/users/:customerId'],
  },
  manageAreasOfUse: {
    paths: ['my-page/administration/areas-of-use'],
  },
  areaOfUseCategoryDetailsAdmin: {
    paths: ['my-page/administration/areas-of-use/:code'],
  },
};

export const defaultRoutingConfig: RoutingConfig = {
  routing: {
    routes: defaultRoutesConfig,
    loadStrategy: RouteLoadStrategy.ONCE,
  },
};
