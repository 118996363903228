import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { I18nModule } from '../../../../core/i18n';
import { DebugKey } from '../../../../core/model';
import { DebugFacade, ExperimentalFeaturesFacade, PrincipalConfigurationService } from '../../../../core/user';
import { DebugMenuItemComponent } from '../debug-menu-item/debug-menu-item.component';

@Component({
  selector: 'py-debug-menu',
  templateUrl: './debug-menu.component.html',
  styleUrls: ['./debug-menu.component.scss'],
  standalone: true,
  imports: [CommonModule, I18nModule, NzIconModule, DebugMenuItemComponent],
  animations: [
    trigger('openAndCloseIconAnimation', [
      state('open', style({ transform: 'rotate(80deg)' })),
      state('closed', style({ transform: 'rotate(-80deg)' })),
      transition('closed => open', animate('200ms ease-in')),
      transition('open => closed', animate('200ms ease-out')),
    ]),
  ],
})
export class DebugMenuComponent implements OnInit, OnDestroy {
  readonly debugKey = DebugKey;

  private subscriptions = new Subscription();

  menuOpen$ = new BehaviorSubject<boolean>(false);

  hasDebugMenu$: Observable<boolean>;
  enabledFeaturesCount$: Observable<number>;

  constructor(
    private principalConfigurationFacade: PrincipalConfigurationService,
    private experimentalFeaturesService: ExperimentalFeaturesFacade,
    private debugService: DebugFacade
  ) {}

  ngOnInit(): void {
    this.hasDebugMenu$ = this.experimentalFeaturesService.hasDebugMenu();
    this.enabledFeaturesCount$ = this.debugService.enabledFeaturesCount();

    this.subscriptions.add(
      this.principalConfigurationFacade.isEnabled('enableDisplayTranslationKeyDebug').subscribe((value) => {
        this.debugService.setDebugFeatureValue(DebugKey.TRANSLATION_KEYS, value);
      })
    );
    this.subscriptions.add(
      this.principalConfigurationFacade.isEnabled('enableSearchScoreExplanation').subscribe((value) => {
        this.debugService.setDebugFeatureValue(DebugKey.SEARCH_SCORE_EXPLANATION, value);
      })
    );
    this.subscriptions.add(
      this.experimentalFeaturesService.hasSearchScore().subscribe((value) => {
        this.debugService.setDebugFeatureValue(DebugKey.SEARCH_SCORE, value);
      })
    );
  }

  ngOnDestroy(): void {
    this.debugService.resetDebugFeatureValues();
    this.subscriptions.unsubscribe();
  }

  toggleMenu(): void {
    this.menuOpen$.next(!this.menuOpen$.value);
  }
}
