import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { EventService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { ArticleService } from '../../../../core/catalog';
import {
  ArticleInfoAttributesFacade,
  DebugFacade,
  OrderCardsFacade,
  PriceFacade,
  PrincipalConfigurationService,
} from '../../../../core/user';
import { WindowRef } from '../../../../core/window';
import { ActiveCartFacade } from '../../../../features/cart/base';
import { ArticleListItemsVisibilityService } from '../../../services/article-list-items-visibility/article-list-items-visibility.service';
import { CatalogArticleDirective } from '../catalog-article.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tbody[py-catalog-article-row]',
  templateUrl: './catalog-article-row.component.html',
  styleUrls: ['./catalog-article-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogArticleRowComponent extends CatalogArticleDirective {
  constructor(
    activeCartService: ActiveCartFacade,
    priceService: PriceFacade,
    elementRef: ElementRef,
    cd: ChangeDetectorRef,
    orderCardsService: OrderCardsFacade,
    articleService: ArticleService,
    windowRef: WindowRef,
    articleListItemsVisibilityService: ArticleListItemsVisibilityService,
    articleInfoAttributesService: ArticleInfoAttributesFacade,
    principalConfigurationService: PrincipalConfigurationService,
    eventService: EventService,
    debugService: DebugFacade,
    launchDialogService: LaunchDialogService
  ) {
    super(
      activeCartService,
      priceService,
      elementRef,
      cd,
      orderCardsService,
      articleService,
      windowRef,
      articleListItemsVisibilityService,
      articleInfoAttributesService,
      principalConfigurationService,
      eventService,
      debugService,
      launchDialogService
    );
  }

  onOverlayExpanded(isExpanded: boolean): void {
    this.isSimilarArticlesExpanded = isExpanded;
  }
}
