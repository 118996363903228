import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MenuService {
  private activeLinkUid$ = new BehaviorSubject<string | null>(null);
  private isMyPageActive$ = new BehaviorSubject<boolean>(this.isMyPageActive());
  private isServicesActive$ = new BehaviorSubject<boolean>(this.isServicesActive());

  constructor(private router: Router) {
    router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.activeLinkUid$.next(null);
        }
        if (event instanceof NavigationEnd) {
          this.isMyPageActive$.next(this.isMyPageActive());
          this.isServicesActive$.next(this.isServicesActive());
        }
      },
    });
  }

  isActive(uid: string): Observable<boolean> {
    return this.activeLinkUid$.pipe(map((activeLinkUid) => uid === activeLinkUid));
  }

  getActiveLinkUid(): Observable<string> {
    return this.activeLinkUid$.asObservable();
  }

  hasActiveLinkUid(): Observable<boolean> {
    return this.activeLinkUid$.pipe(map(Boolean));
  }

  setActiveLinkUid(uid: string | null) {
    this.activeLinkUid$.next(uid);
  }

  getIsMyPageActive(): Observable<boolean> {
    return this.isMyPageActive$.asObservable();
  }

  getIsServicesActive(): Observable<boolean> {
    return this.isServicesActive$.asObservable();
  }

  private isMyPageActive(): boolean {
    return this.router.isActive('/my-page', false);
  }

  private isServicesActive(): boolean {
    return this.router.isActive('/services', false);
  }
}
