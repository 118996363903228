import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { TmsConfig } from '@spartacus/tracking/tms/core';
import { ConsentEvent } from '../../../core/events';
import {
  TrackingEventAddEntriesToCart,
  TrackingEventAddPaymentInfo,
  TrackingEventAddShippingInfo,
  TrackingEventAddToWishlist,
  TrackingEventBeginCheckout,
  TrackingEventGenerateLead,
  TrackingEventLogin,
  TrackingEventPurchase,
  TrackingEventRemoveEntriesFromCart,
  TrackingEventRemoveFromWishlist,
  TrackingEventSearch,
  TrackingEventSelectContent,
  TrackingEventSelectItem,
  TrackingEventSignUp,
  TrackingEventTutorialBegin,
  TrackingEventTutorialComplete,
  TrackingEventViewCart,
  TrackingEventViewItem,
  TrackingEventViewItemList,
} from '../events';
import { GtmCollectorService } from './gtm-collector.service';

@NgModule({
  declarations: [],
  providers: [
    provideConfig(<TmsConfig>{
      tagManager: {
        gtm: {
          collector: GtmCollectorService,
          events: [
            ConsentEvent,
            TrackingEventAddEntriesToCart,
            TrackingEventRemoveEntriesFromCart,
            TrackingEventBeginCheckout,
            TrackingEventPurchase,
            TrackingEventAddPaymentInfo,
            TrackingEventAddShippingInfo,
            TrackingEventAddToWishlist,
            TrackingEventRemoveFromWishlist,
            TrackingEventGenerateLead,
            TrackingEventLogin,
            TrackingEventSearch,
            TrackingEventSelectItem,
            TrackingEventViewItem,
            TrackingEventSignUp,
            TrackingEventViewCart,
            TrackingEventViewItemList,
            TrackingEventSelectContent,
            TrackingEventTutorialBegin,
            TrackingEventTutorialComplete,
          ],
        },
      },
    }),
  ],
})
export class GtmModule {
  constructor() {}
}
