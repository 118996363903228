<span class="search-hint-info mr-2">
  {{ 'catalog.searchHints_heading' | cxTranslate }}
</span>

<div class="d-flex flex-wrap search-hint-holder">
  <button *ngFor="let hint of hints" (click)="onHintClick(hint)" class="d-flex align-items-center search-hint">
    <i nz-icon nzType="search" class="mr-1"></i>
    <span [innerHTML]="hint"></span>
  </button>
</div>
