import { NgModule } from '@angular/core';
import { CartPageEventModule } from '@spartacus/cart/base/core';
import {
  ConfigInitializerModule,
  ConfigModule,
  ConfigValidatorModule,
  StateModule,
  provideConfigFromMetaTags,
} from '@spartacus/core';
import { HomePageEventModule, NavigationEventModule, OutletModule } from '@spartacus/storefront';
import { RoutingModule } from './cms-structure';
import { CmsStructureGuardsModule } from './cms-structure/guards/cms-structure-guards.module';
import { CmsRouteModule } from './cms-structure/routing/cms-route';
import { AnonymousConsentsModule } from './core/anonymous-consents';
import { AsmHeaderModule } from './core/asm-header/asm-header.module';
import { AuthModule } from './core/auth';
import { CatalogModule } from './core/catalog';
import { CmsModule } from './core/cms';
import { EventsModule } from './core/events';
import { FeaturesModule as PyFeaturesModule } from './core/features/features.module';
import { GlobalMessageModule } from './core/global-message';
import { I18nRootModule } from './core/i18n/i18n-root.module';
import { ModalModule } from './core/modal';
import { PermissionModule } from './core/permission';
import { ProcessModule } from './core/process';
import { RemoveAuthHeaderModule } from './core/remove-auth-header/remove-auth-header.module';
import { SolrSearchModule } from './core/search';
import { FeaturesModule } from './features/features.module';
import { LayoutModule } from './layout/layout.module';
import { MediaModule } from './shared/components/media';

@NgModule({
  imports: [
    StateModule.forRoot(),
    AsmHeaderModule, // should be declared before ClientTokenInterceptor from AuthModule
    AuthModule.forRoot(),
    AnonymousConsentsModule.forRoot(),
    PermissionModule.forRoot(),
    ConfigModule.forRoot(),
    ConfigInitializerModule.forRoot(),
    ConfigValidatorModule.forRoot(),
    RoutingModule.forRoot(),
    I18nRootModule.forRoot(),
    CmsModule.forRoot(),
    CmsRouteModule, // Because we use the Core routing module without default routes
    GlobalMessageModule.forRoot(),
    ProcessModule.forRoot(),
    PyFeaturesModule.forRoot(),
    LayoutModule,
    MediaModule.forRoot(),
    ModalModule.forRoot(),
    HomePageEventModule,
    CartPageEventModule,
    NavigationEventModule,
    EventsModule,
    CmsStructureGuardsModule,
    OutletModule.forRoot(),
    CatalogModule,
    SolrSearchModule.forRoot(),
    FeaturesModule,
    RemoveAuthHeaderModule, // should be declared last
  ],
  exports: [LayoutModule],
  providers: [...provideConfigFromMetaTags()],
})
export class StorefrontFoundationModule {}
