/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/naming-convention */
import { Occ as CxOcc } from '@spartacus/core';
import { Image as PyImage } from '../../model';
import * as Generated from './occ-generated.models';

declare module '@spartacus/core' {
  namespace Occ {
    interface Component {
      uuid?: string;
    }

    interface ContentSlot {
      slotUuid?: string;
    }

    interface CMSPage {
      uuid?: string;
      catalogVersionUuid?: string;
      publishedDate?: Date;
      featuredImage?: PyImage;
    }
  }
}

export namespace Occ {
  export interface A4SampleColor extends Generated.A4SampleColor {}

  export interface A4SampleColorList extends Generated.A4SampleColorList {}

  export interface AccountingCode extends Generated.AccountingCode {}

  export interface AccountingCodeEntryMapping extends Generated.AccountingCodeEntryMapping {}

  export interface AccountingCodeList extends Generated.AccountingCodeList {}

  export interface AccountingCodePage extends Generated.AccountingCodePage {}

  export interface AccountingCodeUserInfo extends Generated.AccountingCodeUserInfo {}

  export interface Address extends Generated.Address {}

  export interface AddressList extends Generated.AddressList {}

  export interface AddressValidation extends Generated.AddressValidation {}

  export interface AlternativeArticles extends Generated.AlternativeArticles {}

  export interface AnalyticsArticle extends Generated.AnalyticsArticle {}

  export interface AnalyticsMetadata extends Generated.AnalyticsMetadata {}

  export interface AnalyticsTracker extends Generated.AnalyticsTracker {}

  export interface ApptusFrontendConfiguration extends Generated.ApptusFrontendConfiguration {}

  export interface AreaOfUse extends Generated.AreaOfUse {}

  export interface AreaOfUseList extends Generated.AreaOfUseList {}

  export interface AltHrefLang extends Generated.AltHrefLang {}

  export interface Article extends Omit<Generated.Article, 'classificationAttributes' | 'inlineAttributes'> {
    classificationAttributes?: ClassificationAttribute[];
    inlineAttributes?: PresentationAttribute[];
  }

  export interface ArticleEnvelope extends Generated.ArticleEnvelope {}

  export interface ArticleEnvironment extends Generated.ArticleEnvironment {}

  export interface ArticleExtraInfo extends Generated.ArticleExtraInfo {}

  export interface ArticleJunk extends Generated.ArticleJunk {}

  export interface ArticleLine extends Generated.ArticleLine {}

  export interface ArticleList extends Generated.ArticleList {}

  export interface ArticleListAttribute extends Generated.ArticleListAttribute {}

  export import ArticlePackagingEnum = Generated.ArticlePackagingEnum;

  export interface ArticlePriceStock extends Generated.ArticlePriceStock {}

  export interface ArticleRef extends Generated.ArticleRef {}

  export interface ArticleRefsWsDTO extends Generated.ArticleRefs {}

  export interface ArticlesSearchPage extends Generated.ArticlesSearchPage {}

  export interface RefsSearchPage extends Generated.RefsSearchPage {}

  export interface ArticleStockInfo extends Generated.ArticleStockInfo {}

  export interface ArticleStockInfoList extends Generated.ArticleStockInfoList {}

  export interface AssignedShipTo extends Generated.AssignedShipTo {}

  export interface AssignedShipToPage extends Generated.AssignedShipToPage {}

  export interface Assortment extends Omit<Generated.Assortment, 'values'> {
    values?: Array<{ key: string; value: string[] }>;
  }

  export interface AtgScheduleItem extends Generated.AtgScheduleItem {}

  export interface Attribute extends Generated.Attribute {}

  export interface AttributeRow extends Generated.AttributeRow {}

  export interface AttributeTable extends Generated.AttributeTable {}

  export interface AutoResolveChange extends Generated.AutoResolveChange {}

  export import AutoResolveChangeChangeTypeEnum = Generated.AutoResolveChangeChangeTypeEnum;

  export interface B2BPermissionUsage extends Generated.B2BPermissionUsage {}

  export interface B2BUnit extends Omit<Generated.B2BUnit, 'administrators' | 'approvers' | 'customers' | 'managers'> {
    administrators?: B2BUser[];
    approvers?: B2BUser[];
    customers?: B2BUser[];
    managers?: B2BUser[];
  }

  export interface B2BUnitNode extends Generated.B2BUnitNode {}

  export interface B2BUnitNodeList extends Generated.B2BUnitNodeList {}

  export interface B2BUser extends Omit<Occ.Customer, 'deactivationDate'> {
    deactivationDate?: Date;
  }

  export interface B2BUserGroup extends Generated.B2BUserGroup {}

  export interface B2BPermissionOrdersPage extends Generated.B2BPermissionOrdersPage {}

  export interface B2BPermissionOrderListItem extends Generated.B2BPermissionOrderListItem {}

  export interface B2BPermissionsPage extends Omit<Generated.B2BPermissionsPage, 'results'> {
    results?: Permission[];
  }

  export interface Badge extends Generated.Badge {}

  export import BadgeTypeEnum = Generated.BadgeTypeEnum;

  export interface BaseOption extends Generated.BaseOption {}

  export interface BaseSite extends Generated.BaseSite {}

  export interface BaseSiteList extends Generated.BaseSiteList {}

  export interface BaseStore extends Generated.BaseStore {}

  export interface Breadcrumb extends Generated.Breadcrumb {}

  export interface BuyerData extends Generated.BuyerData {}

  export interface SearchTermFacetMappingHint extends Generated.SearchTermFacetMappingHint {}

  export interface CancellationRequestEntryInput extends Generated.CancellationRequestEntryInput {}

  export interface CancellationRequestEntryInputList extends Generated.CancellationRequestEntryInputList {}

  export interface CardType extends Generated.CardType {}

  export interface CardTypeList extends Generated.CardTypeList {}

  export interface Cart extends Omit<Generated.Cart, 'permissionResults'> {
    permissionResults?: PermissionResult[];
  }

  export interface CartList extends Generated.CartList {}

  export interface CartModification extends Generated.CartModification {}

  export interface CartModificationList extends Generated.CartModificationList {}

  export interface CartOwnerDetails extends Generated.CartOwnerDetails {}

  export interface CartSimulation extends Generated.Checkout {}

  export interface CartTemplate extends Generated.CartTemplate {}

  export import CartTemplateAccessTypeEnum = Generated.CartTemplateAccessTypeEnum;

  export interface CartTemplateSummary extends Generated.CartTemplateSummary {}

  export interface CartTemplateSummaryPage extends Generated.CartTemplateSummaryPage {}

  export interface Catalog extends Generated.Catalog {}

  export interface CatalogAddonGroup extends Omit<Generated.CatalogAddonGroup, 'children' | 'nodes'> {
    children?: CatalogAddonGroup[];
    nodes?: CatalogAddonNode[];
    sort?: string;
  }

  export interface CatalogAddonNode extends Generated.CatalogAddonNode {
    sort?: string;
  }

  export interface CatalogAddonsGroupList extends Generated.CatalogAddonsGroupList {}

  export interface CatalogList extends Generated.CatalogList {}

  export interface CatalogVersion extends Generated.CatalogVersion {}

  export interface Category extends Generated.Category {}

  export interface CategoryHierarchy extends Generated.CategoryHierarchy {}

  export interface CategoryList extends Generated.CategoryList {}

  export interface Certification extends Generated.Certification {}

  export interface Checkout extends Generated.Checkout {}

  export interface Classification extends Generated.Classification {}

  export interface ClassificationAttributeData extends Generated.ClassificationAttributeData {}

  export interface CollectionValueString_ extends Generated.CollectionValue {}

  export interface Comment extends Generated.Comment {}

  export interface CommentList extends Generated.CommentList {}

  export interface CompanySignUp extends Generated.CompanySignUp {}

  export interface ComparativePriceEntry extends Generated.ComparativePriceEntry {}

  export interface ComponentIDList extends Generated.ComponentIDList {}

  export interface ConfigurationInfo extends Generated.ConfigurationInfo {}

  export interface Consent extends Omit<Generated.Consent, 'consentGivenDate' | 'consentWithdrawnDate'> {
    consentGivenDate?: Date;
    consentWithdrawnDate?: Date;
  }

  export interface ConsentTemplate extends Omit<Generated.ConsentTemplate, 'currentConsent'> {
    currentConsent?: Consent;
  }

  export interface ConsentTemplateList extends Omit<Generated.ConsentTemplateList, 'consentTemplates'> {
    consentTemplates?: ConsentTemplate[];
  }

  export interface Consignment extends Generated.Consignment {}

  export interface ConsignmentArticleStock extends Generated.ConsignmentArticleStock {}

  export interface ConsignmentEntry extends Generated.ConsignmentEntry {}

  export interface ContentTag extends Generated.ContentTag {}

  export interface ContentTagList extends Generated.ContentTagList {}

  export interface Country extends Generated.Country {}

  export interface CountryList extends Generated.CountryList {}

  export interface CredentialsSettings extends Generated.CredentialsSettings {}
  export interface Customer extends Omit<Generated.Customer, 'deactivationDate'> {
    deactivationDate?: Date;
  }

  export interface CustomerPage extends Generated.CustomerPage {}

  export interface CustomerUploadedMedia extends Generated.CustomerUploadedMedia {}

  export interface UserRole extends Generated.UserRole {}

  export interface UserRoleList extends Generated.UserRoleList {}

  export interface CustomerStatus extends Generated.CustomerStatus {}

  export interface CustomerStatusList extends Generated.CustomerStatusList {}

  export interface Cutting extends Generated.Cutting {}

  export interface CuttingRequest extends Generated.CuttingRequest {}

  export interface CuttingTemplate extends Generated.CuttingTemplate {}

  export interface DataImport extends Generated.DataImport {}

  export interface DeliveryDateInfo extends Generated.DeliveryDateInfo {}

  export interface DeliveryOrderEntryGroup extends Generated.DeliveryOrderEntryGroup {}

  export interface DeliveryMode extends Generated.DeliveryMode {}

  export interface DeliveryModeList extends Generated.DeliveryModeList {}

  export interface DeprecatedPagination extends Generated.DeprecatedPagination {}

  export interface EntryFlag extends Generated.EntryFlag {}

  export interface Error extends Generated.Error {}

  export interface ErrorList extends Generated.ErrorList {}

  export interface ExplodedOrderLine extends Generated.ExplodedOrderLine {}

  export interface Facet extends Generated.Facet {}

  export interface FacetValue extends Generated.FacetValue {}

  export interface Feature extends Generated.Feature {}

  export interface FeatureUnit extends Generated.FeatureUnit {}

  export interface FeatureValue extends Generated.FeatureValue {}

  export interface FullPalletUpgrade extends Generated.FullPalletUpgrade {}

  export interface FutureStock extends Generated.FutureStock {}

  export interface Feed extends Generated.Feed {}

  export interface FeedRequestListDTO extends Generated.FeedRequestListDTO {}

  export interface GeoPoint extends Generated.GeoPoint {}

  export import GetPageableCustomersParamsModeEnum = Generated.GetPageableCustomersParamsModeEnum;

  export interface GoogleTagManager extends Generated.GoogleTagManager {}

  export interface Image extends Generated.Image {}

  export interface ImpersonationCustomer extends Generated.ImpersonationCustomer {}

  export interface ImpersonationCustomerPage extends Generated.ImpersonationCustomerPage {
    sorts?: Sort[];
  }

  export interface InvoiceHistoryPage extends Generated.InvoiceHistoryPage {
    sorts?: Sort[];
  }

  export interface InvoiceHistoryRow extends Generated.InvoiceHistoryRow {}

  export interface KitMasterArticle extends Generated.KitMasterArticle {}

  export interface KitMemberArticle extends Generated.KitMemberArticle {}

  export interface LocalArticleWarehouseStatus extends Generated.LocalArticleWarehouseStatus {}

  export interface Log extends Generated.Log {}

  export interface MemberList extends Generated.MemberList {}

  export interface Message extends Generated.Message {}

  export interface MessageList extends Generated.MessageList {}

  export interface MultipleArticlesAddedResponse extends Generated.MultipleArticlesAddedResponse {}

  export interface NewFreeStock extends Generated.NewFreeStock {}

  export interface News extends Generated.News {}

  export interface NewsList extends Generated.NewsList {}

  export interface OciCheckout extends Generated.OciCheckout {}

  export interface OmniSearchPage extends Omit<Generated.OmniSearchPage, 'sorts'> {
    sorts?: DeprecatedSort;
  }

  export interface OnlineChatConfig extends Generated.OnlineChatConfig {}

  export interface OpeningSchedule extends Generated.OpeningSchedule {}

  export interface OperationalMessage extends Generated.OperationalMessage {}

  export interface OperationalMessageWrapper extends Generated.OperationalMessageWrapper {}

  export import OperationalMessageWrapperStatusEnum = Generated.OperationalMessageWrapperStatusEnum;

  export interface Order extends Omit<Generated.Order, 'pyOrderParameters'> {
    pyOrderParameters?: PyOrderParameters;
  }

  export import OrderApprovalApprovalStatusEnum = Generated.OrderApprovalApprovalStatusEnum;

  export interface OrderApprovalDecision extends Omit<Generated.OrderApprovalDecision, 'decision'> {
    decision: OrderApprovalDecisionValue;
  }

  export interface OrderDetailsStatusHeader extends Generated.OrderDetailsStatusHeader {}

  export interface OrderDetailsOrder extends Generated.OrderDetailsOrder {}

  export interface OrderApproval extends Generated.OrderApproval {}

  export interface OrderApprovalPage extends Generated.OrderApprovalPage {
    sorts?: Sort[];
  }

  export interface OrderDetailsOrderLine extends Generated.OrderDetailsOrderLine {}

  export import OrderDetailsOrderOrderTypeEnum = Generated.OrderDetailsOrderOrderTypeEnum;

  export interface OrderEntry extends Generated.OrderEntry {}

  export interface OrderEntryComparativePrice extends Generated.OrderEntryComparativePrice {}

  export interface OrderEntryList extends Generated.OrderEntryList {}

  export import OrderEntryTypeEnum = Generated.OrderEntryTypeEnum;

  export interface OrderEntryUnits extends Generated.OrderEntryUnits {}

  export interface OrderHistory extends Generated.OrderHistory {}

  export interface OrderHistoryItem extends Generated.OrderHistoryItem {}

  export interface OrderHistoryList extends Generated.OrderHistoryList {}

  export interface OrderHistoryPage extends Generated.OrderHistoryPage {
    sorts?: Sort[];
  }

  export interface OrderMergeProposal extends Generated.OrderMergeProposal {}

  export interface OrderNote extends Generated.OrderNote {}

  export import OrderOrderTypeEnum = Generated.OrderOrderTypeEnum;

  export interface OrderProcessStatus extends Generated.OrderProcessStatus {}

  export interface OrderReturn extends Omit<Generated.OrderReturn, 'entries'> {
    entries?: OrderReturnEntry[];
  }

  export interface OrderReturnEntry extends Omit<Generated.OrderReturnEntry, 'reasonCode'> {
    reasonCode?: ReturnReasonReasonCodeEnum | OrderReturnEntryReasonCodeEnum;
  }

  export import OrderReturnEntryReasonCodeEnum = Generated.OrderReturnEntryReasonCodeEnum;

  export interface OrderStatusUpdateElement extends Generated.OrderStatusUpdateElement {}

  export interface OrderStatusUpdateElementList extends Generated.OrderStatusUpdateElementList {}

  export interface OrgCustomerCreation extends Generated.OrgCustomerCreation {}

  export interface OrgCustomerModification extends Generated.OrgCustomerModification {}

  export interface OrgUnitUserList extends Omit<Generated.OrgUnitUserList, 'users' | 'sorts'> {
    users: B2BUser[];
    sorts?: DeprecatedSort[];
  }

  export interface OrgUnitUserGroupList extends Omit<Generated.OrgUnitUserGroupList, 'sorts'> {
    sorts?: DeprecatedSort[];
  }

  export interface OrgUnitUserPage extends Omit<Generated.OrgUnitUserPage, 'results'> {
    results?: Customer[];
  }

  export interface OutgoingInfo extends Generated.OutgoingInfo {}

  export interface Pagination extends Generated.Pagination {}

  export interface PalletFlag extends Generated.PalletFlag {}

  export interface PalletFlags extends Generated.PalletFlags {}

  export interface PalletFlagsRequest extends Generated.PalletFlagsRequest {}

  export interface Panel extends Generated.Panel {}

  export interface PanelResponse extends Generated.PanelResponse {}

  export interface PaymentDetails extends Generated.PaymentDetails {}

  export interface PaymentDetailsList extends Generated.PaymentDetailsList {}

  export interface PaymentMode extends Generated.PaymentMode {}

  export interface PaymentModeList extends Generated.PaymentModeList {}

  export interface PaymentProvider extends Generated.PaymentProvider {}

  export interface PaymentOptions extends Generated.PaymentOptions {}

  export interface PaymentOption extends Generated.PaymentOption {}

  export interface PaymentRequest extends Omit<Generated.PaymentRequest, 'params'> {
    params?: {
      entry: { key: string; value: string }[];
    };
  }

  export interface Permission extends Omit<Generated.Permission, 'orgUnit'> {
    orgUnit?: B2BUnit;
  }

  export interface PermissionAssortmentScope extends Generated.PermissionAssortmentScope {}

  export import PermissionConnectionTypeEnum = Generated.PermissionConnectionTypeEnum;

  export interface PermissionList extends Omit<Generated.PermissionList, 'sorts'> {
    sorts?: DeprecatedSort[];
  }

  export import PermissionPeriodRangeEnum = Generated.PermissionPeriodRangeEnum;

  export import PermissionResolutionTypeEnum = Generated.PermissionResolutionTypeEnum;

  export interface PermissionResult extends Omit<Generated.PermissionResult, 'permissionDetails'> {
    permissionDetails?: Permission;
  }

  export import PermissionStatusEnum = Generated.PermissionStatusEnum;

  export interface PermissionType extends Generated.PermissionType {}

  export interface PermissionTypeList extends Generated.PermissionTypeList {}

  export interface PickupOrderEntryGroup extends Generated.PickupOrderEntryGroup {}

  export interface PointOfService extends Generated.PointOfService {}

  export interface PointOfServiceList extends Generated.PointOfServiceList {}

  export interface PointOfServiceStock extends Generated.PointOfServiceStock {}

  export interface PrePrintedLabel extends Generated.PrePrintedLabel {}

  export interface PresentationAttribute extends Generated.PresentationAttribute {
    unit: string;
  }

  export interface Price extends Generated.Price {}

  export interface PriceFile extends Generated.PriceFile {}

  export interface PriceFileListPage extends Generated.PriceFileListPage {
    sorts?: Sort[];
  }

  export interface PriceFileRequest extends Generated.PriceFileRequest {}

  export interface PriceFileRequestTemplate extends Generated.PriceFileRequestTemplate {}

  export interface PriceFileRequestTemplateList extends Generated.PriceFileRequestTemplateList {}

  export import PriceFileStatusEnum = Generated.PriceFileStatusEnum;

  export interface PriceFileTemplate extends Generated.PriceFileTemplate {}

  export interface PriceFileTemplatePage extends Generated.PriceFileTemplatePage {}

  export interface PriceRange extends Generated.PriceRange {}

  export interface PriceRequest extends Generated.PriceRequest {}

  export interface PriceRequestList extends Generated.PriceRequestList {}

  export interface PriceResponseList extends Generated.PriceResponseList {}

  export interface PriceResponse extends Generated.PriceResponse {}

  export interface Principal extends Generated.Principal {}

  export interface PrincipalConfiguration extends Generated.PrincipalConfiguration {}

  export interface PrincipalConfigurationGroup extends Generated.PrincipalConfigurationGroup {}

  export interface PrincipalConfigurationList extends Generated.PrincipalConfigurationList {}

  export interface PrincipalConfigurationValueWsDTO extends Generated.PrincipalConfigurationValue {}

  export interface PrincipalSharedCartTemplateFlags extends Generated.PrincipalSharedCartTemplateFlags {}

  export interface PrincipalSharedCartTemplateFlagsPage extends Generated.PrincipalSharedCartTemplateFlagsPage {}

  export interface ProcuratorEnvironmentalChoice extends Generated.ProcuratorEnvironmentalChoice {}

  export interface Product extends Omit<Generated.Product, 'extraInfo'> {
    extraInfo?: ProductExtraInfo;
  }

  export interface ProductInquiryForm extends Generated.ProductInquiryForm {}

  export interface AlternativeProductRef extends Generated.AlternativeProductRef {}

  export interface AlternativeProducts extends Generated.AlternativeProducts {}

  export interface ProductBrand extends Generated.ProductBrand {}

  export interface ProductBrandsWrapper extends Generated.ProductBrandsWrapper {}

  export interface ProductCertificateGroup extends Generated.ProductCertificateGroup {}

  export interface ProductCertification extends Generated.ProductCertification {}

  export interface ProductDocument extends Generated.ProductDocument {}

  export interface ProductDocumentsWrapper extends Generated.ProductDocumentsWrapper {}

  export interface EcConformityProductDocument extends Generated.EcConformityProductDocument {}

  export interface EcConformityProductDocumentsPage extends Generated.EcConformityProductDocumentsPage {}

  export interface ProductDetails {
    assortmentPackagingTable?: Assortment;
    technicalDataTable?: AttributeTable;
  }

  export enum ProductDetailsTopic {
    AssortmentPackagingTable = 'assortment-packaging-table',
    TechnicalDataTable = 'technical-data-table',
  }

  export interface ProductDownloadable extends Generated.ProductDownloadable {}

  export interface ProductExpressUpdateElement extends Generated.ProductExpressUpdateElement {}

  export interface ProductExpressUpdateElementList extends Generated.ProductExpressUpdateElementList {}

  export interface ProductExtraInfo extends Omit<Generated.ProductExtraInfo, 'assortment' | 'topics'> {
    assortment?: Assortment;
    topics?: ProductDetailsTopic[];
  }

  export interface ProductList extends Generated.ProductList {}

  export interface ProductReference extends Generated.ProductReference {}

  export interface ProductReferenceList extends Generated.ProductReferenceList {}

  export interface ProductRelation extends Generated.ProductRelation {}

  export interface ProductSearchPage extends Generated.ProductSearchPage {}

  export interface Promotion extends Generated.Promotion {}

  export interface PromotionList extends Generated.PromotionList {}

  export interface PromotionOrderEntryConsumed extends Generated.PromotionOrderEntryConsumed {}

  export interface PromotionRestriction extends Generated.PromotionRestriction {}

  export interface PromotionResult extends Generated.PromotionResult {}

  export interface PromotionResultList extends Generated.PromotionResultList {}

  export interface PunchOutData extends Omit<Generated.PunchOutData, 'properties'> {
    properties: {
      entry: Array<Entry<string, string>>;
    };
  }

  export interface PunchOutPayload extends Generated.PunchOutPayload {}

  export interface PyAddress extends Generated.PyAddress {}

  export interface PyOrderLineParameters extends Generated.PyOrderLineParameters {}

  export interface PyOrderParameters extends Omit<Generated.PyOrderParameters, 'shippingOptions'> {
    shippingOptions?: ShippingOption[];
  }

  export interface PyProductReference extends Generated.PyProductReference {}

  export interface Reaming extends Generated.Reaming {}

  export interface Recommendation extends Generated.Recommendation {}

  export interface ReelCutting extends Generated.ReelCutting {}

  export interface ReelCuttingRequest extends Generated.ReelCuttingRequest {}

  export interface Region extends Generated.Region {}

  export interface RegionList extends Generated.RegionList {}

  export interface Reinvoicing extends Generated.Reinvoicing {}

  export interface ResetPassword extends Generated.ResetPassword {}

  export interface ResolveOption extends Generated.ResolveOption {}

  export import ResolveOptionActionEnum = Generated.ResolveOptionActionEnum;

  export interface ReturnReason extends Omit<Generated.ReturnReason, 'reasonCode'> {
    reasonCode?: ReturnReasonReasonCodeEnum | OrderReturnEntryReasonCodeEnum;
  }

  export import ReturnReasonReasonCodeEnum = Generated.ReturnReasonReasonCodeEnum;

  export interface ReturnReasonList extends Omit<Generated.ReturnReasonList, 'returnReasons'> {
    returnReasons?: ReturnReason[];
  }

  export interface ReturnRequest extends Generated.ReturnRequest {}

  export interface ReturnRequestEntry extends Generated.ReturnRequestEntry {}

  export interface ReturnRequestEntryInput extends Generated.ReturnRequestEntryInput {}

  export interface ReturnRequestEntryInputList extends Generated.ReturnRequestEntryInputList {}

  export interface ReturnRequestList extends Generated.ReturnRequestList {}

  export interface ReturnRequestModification extends Generated.ReturnRequestModification {}

  export interface Review extends Generated.Review {}

  export interface ReviewList extends Generated.ReviewList {}

  export interface SalesOrg extends Generated.SalesOrg {}

  export interface SampleInfo extends Generated.SampleInfo {}

  export interface SapClient extends Generated.SapClient {}

  export interface SapOrderCondition extends Generated.SapOrderCondition {}

  export interface SapPriceCampaign extends Generated.SapPriceCampaign {}

  export interface SapPriceCampaignDetails extends Generated.SapPriceCampaignDetails {}

  export interface SapPriceCampaignBannerEntry extends Generated.SapPriceCampaignBannerEntry {}

  export interface SapPriceCampaignList extends Generated.SapPriceCampaignList {}

  export interface SapPriceCampaign extends Generated.SapPriceCampaign {}

  export interface SaveCartResult extends Generated.SaveCartResult {}

  export interface SearchQuery extends Generated.SearchQuery {}

  export interface SearchScoreExplanation extends Generated.SearchScoreExplanation {}

  export interface SearchScoreFactor extends Generated.SearchScoreFactor {}

  export interface SearchState extends Generated.SearchState {}

  export interface SeoMeta extends Generated.SeoMeta {}

  export interface ServiceOption extends Generated.ServiceOption {}

  export import ServiceOptionTypeEnum = Generated.ServiceOptionTypeEnum;

  export interface SimpleCustomer extends Generated.SimpleCustomer {}

  export interface SimpleItem extends Generated.SimpleItem {}

  export interface ShipTo extends Generated.ShipTo {}

  export interface ShipToList extends Generated.ShipToList {}

  export interface SimpleCustomerPage extends Generated.SimpleCustomerPage {
    sorts?: Sort[];
  }

  export interface SiteConfig extends Generated.SiteConfig {}

  export interface ShipToPage extends Generated.ShipToPage {}

  export interface Shipping extends Generated.Shipping {}

  export interface ShippingOption extends Omit<Generated.ShippingOption, 'properties'> {
    properties?: ShippingOptionPropertyMap[];
  }

  export import ShippingOptionTypeEnum = Generated.ShippingOptionTypeEnum;

  export interface SiteFamilyConfig extends Generated.SiteFamilyConfig {}

  export interface SoldTo extends Generated.SoldTo {}

  export interface SoldToPage extends Generated.SoldToPage {}

  export interface SolrArticleSearchPage extends Generated.SolrArticleSearchPage {}

  export interface SolrProductSearchPage extends Generated.SolrProductSearchPage {}

  export interface SolrResultEntityRef extends Generated.SolrResultEntityRef {}

  export interface Sort extends Generated.Sort {
    label?: string;
  }

  export interface SpecialMessage extends Generated.SpecialMessage {}

  export interface SpecialOpeningDay extends Generated.SpecialOpeningDay {}

  export interface SpellingSuggestion extends Generated.SpellingSuggestion {}

  export interface Statistics extends Generated.Statistics {}

  export interface StatisticsList extends Generated.StatisticsList {}

  export interface StatusSummary extends Generated.StatusSummary {}

  export interface Stock extends Generated.Stock {}

  export interface StoreCount extends Generated.StoreCount {}

  export interface StoreCountList extends Generated.StoreCountList {}

  export interface StoreFinderSearchPage extends Generated.StoreFinderSearchPage {}

  export interface StoreFinderStockSearchPage extends Generated.StoreFinderStockSearchPage {}

  export interface StockInfo extends Generated.StockInfo {}

  export interface StringMap extends Generated.StringMap {}

  export interface SubstituteRef extends Generated.SubstituteRef {}

  export interface Substitutes extends Generated.Substitutes {}

  export interface Suggestion extends Generated.Suggestion {}

  export interface SuggestionList extends Generated.SuggestionList {}

  export interface TechnicalAttribute extends Generated.TechnicalAttribute {}

  export interface Time extends Generated.Time {}

  export interface TimeWindowDeliveryOption extends Generated.TimeWindowDeliveryOption {}

  export interface TimeWindowDeliveryOptions extends Generated.TimeWindowDeliveryOptions {}

  export interface TinyCart extends Generated.TinyCart {}

  export interface TinyCartList extends Generated.TinyCartList {}

  export interface Title extends Generated.Title {}

  export interface TitleList extends Generated.TitleList {}

  export interface TrackOrderBillOfLading extends Generated.TrackOrderBillOfLading {}

  export interface TrackOrderDelivery extends Generated.TrackOrderDelivery {}

  export interface TrackOrderHandlingUnit extends Generated.TrackOrderHandlingUnit {}

  export interface TrackOrderItem extends Generated.TrackOrderItem {}

  export interface TrackOrderShipPoint extends Generated.TrackOrderShipPoint {}

  export interface TrackOrderShipTo extends Generated.TrackOrderShipTo {}

  export interface UmamiConfiguration extends Generated.UmamiConfiguration {}

  export interface UniqueSellingPoint extends Generated.UniqueSellingPoint {}

  export interface UniqueSellingPoints extends Generated.UniqueSellingPoints {}

  export interface Unit extends Generated.Unit {}

  export interface UnloadingPoint extends Generated.UnloadingPoint {}

  export interface User extends Omit<Occ.Customer, 'customerType' | 'deactivationDate'> {
    customerType?: CustomerType;
    deactivationDate?: Date;
  }

  export interface UserGroup extends Generated.UserGroup {}

  export interface UserGroupList extends Generated.UserGroupList {}

  export interface UserSignUp extends Generated.UserSignUp {}

  export interface VariantCategory extends Generated.VariantCategory {}

  export interface VariantMatrixElement extends Generated.VariantMatrixElement {}

  export interface VariantOption extends Generated.VariantOption {}

  export interface VariantOptionQualifier extends Generated.VariantOptionQualifier {}

  export interface VariantValueCategory extends Generated.VariantValueCategory {}

  export interface Voucher extends Generated.Voucher {}

  export interface VoucherList extends Generated.VoucherList {}

  export interface WarehouseStock extends Generated.WarehouseStock {}

  export interface Warehouse extends Generated.Warehouse {}

  export interface WarehouseArticle extends Generated.WarehouseArticle {}

  export interface WeekdayOpeningDay extends Generated.WeekdayOpeningDay {}

  export interface OrderCard extends Generated.OrderCard {}
  export interface OrderCardConfiguration extends Generated.OrderCardConfiguration {}
  export interface OrderCardConfigurationList extends Generated.OrderCardConfigurationList {}
  export interface OrderCardContactPerson extends Generated.OrderCardContactPerson {}
  export interface CreateOrUpdateOrderCardConfiguration extends Generated.CreateOrUpdateOrderCardConfiguration {}
  export interface OrderCardPage extends Generated.OrderCardPage {
    sorts?: Sort[];
  }

  /**
   * Any interfaces related to cmsocc are not included when generating OCC models so they have to be manually included
   */
  export import ContentSlotList = CxOcc.ContentSlotList;

  export import CMSPage = CxOcc.CMSPage;

  export import ComponentList = CxOcc.ComponentList;

  export import ContentSlot = CxOcc.ContentSlot;

  export import Component = CxOcc.Component;

  export import PageRobots = CxOcc.PageRobots;

  export import Language = CxOcc.Language;

  export import LanguageList = CxOcc.LanguageList;

  export import Currency = CxOcc.Currency;

  export import CurrencyList = CxOcc.CurrencyList;

  // Things that are not part of the interface but added here to make life easier

  export enum OrderApprovalDecisionValue {
    APPROVE = 'approve_order',
    REJECT = 'reject_order',
    RETURN_TO_USER = 'return_order',
    HIJACK_ORDER = 'hijack_order',
  }

  export enum CustomerType {
    GUEST = 'GUEST',
    REGISTERED = 'REGISTERED',
    OCI = 'OCI',
    CXML = 'CXML',
    AUTOLOGIN = 'AUTOLOGIN',
    AZURE_AD_B2B = 'AZURE_AD_B2B',
    S2S = 'S2S',
  }

  /**
   * deprecated, TODO: Awaiting BE, see ESV-5938
   */
  export interface DeprecatedSort {
    /**
     * @member {string} [code]
     */
    code?: string;
    /**
     * @member {string} [name]
     */
    name?: string;
    /**
     * @member {boolean} [selected]
     */
    selected?: boolean;
  }

  /**
   * TODO: ESVCX-2186
   *
   * These are some unexpected discrepancies between generated occ models and what we were expecting in kasbah.
   * We should probably take a deeper look at these, because most likely any implementation of Occ have false assumption of what types are returned from the occ backend.
   */

  export interface Entry<K, V> {
    key: K;
    value: V;
  }

  export interface CollectionValue<T> {
    value: {
      type: string;
      value: T;
    }[];
  }

  export interface Map<T> {
    value: {
      entry: {
        key: string;
        value: T;
      }[];
    };
  }

  export interface ShippingOptionPropertyMap {
    key?: string;
    value?: ShippingOptionProperty;
  }

  export interface ShippingOptionProperty {
    type?: string;
    value?: any;
  }

  export interface ClassificationAttribute {
    key?: string;
    value?: Generated.ClassificationAttribute;
  }
}
