import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CxNumericPipe, RoutingService } from '@spartacus/core';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { ArticleService, CategoryService } from '../../../../core/catalog';
import { GlobalMessageService } from '../../../../core/global-message';
import { TranslationService } from '../../../../core/i18n';
import { RoutingHelperService } from '../../../../core/routing';
import { ArticleInfoAttributesFacade, PriceFacade, PrincipalConfigurationService } from '../../../../core/user';
import { ActiveCartFacade } from '../../../../features/cart/base';
import { OrderReturnFacade } from '../../../../features/order-base';
import { UnitPipe } from '../../../pipes/unit.pipe';
import { OrderEntryComponent } from '../order-entry.component';
import { ORDER_ENTRY_CONFIG, OrderEntryConfig } from '../order-entry.config';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'li[py-order-entry-list-item]',
  templateUrl: './order-entry-list-item.component.html',
  styleUrls: ['./order-entry-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderEntryListItemComponent extends OrderEntryComponent implements OnInit {
  @ViewChild('extrasDataWrapper') extrasDataWrapperRef: ElementRef;

  isDesktop$: Observable<boolean>;

  get showAdditionalInfoButton(): boolean {
    if (!this.extrasDataWrapperRef) {
      return false;
    }

    // Here extras section is checked if its content has any text.
    // Only pure content should be checked (without HTML tags and component selectors) that why temporary div element has to be created.
    const temporaryElement = document.createElement('div');
    temporaryElement.innerHTML = this.extrasDataWrapperRef.nativeElement.innerHTML;

    const textContent = temporaryElement.textContent || temporaryElement.innerText || '';

    return textContent.trim().length > 0;
  }

  constructor(
    private breakpointService: BreakpointService,
    @Inject(ORDER_ENTRY_CONFIG) config: OrderEntryConfig,
    unitPipe: UnitPipe,
    decimalPipe: CxNumericPipe,
    translationService: TranslationService,
    principalConfigurationService: PrincipalConfigurationService,
    articleService: ArticleService,
    activeCartService: ActiveCartFacade,
    priceService: PriceFacade,
    categoryService: CategoryService,
    routingService: RoutingService,
    routingHelperService: RoutingHelperService,
    globalMessageService: GlobalMessageService,
    articleInfoAttributesService: ArticleInfoAttributesFacade,
    orderReturnService: OrderReturnFacade,
    route: ActivatedRoute
  ) {
    super(
      config,
      unitPipe,
      decimalPipe,
      translationService,
      principalConfigurationService,
      articleService,
      activeCartService,
      priceService,
      categoryService,
      routingService,
      routingHelperService,
      globalMessageService,
      articleInfoAttributesService,
      orderReturnService,
      route
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.isDesktop$ = this.breakpointService.isUp(BREAKPOINT.sm);
  }
}
