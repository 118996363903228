import { Occ } from '../occ';
import { ArticleRef } from './catalog.model';
import { SortModel } from './misc.model';

export const SOLR_SEACH_SORTING_ASC_PREFIX = '_asc';
export const SOLR_SEACH_SORTING_DESC_PREFIX = '_desc';

export const SORTING_ASC_PREFIX = ':asc';
export const SORTING_DESC_PREFIX = ':desc';

export const CUSTOMER_UNIQUE_CATEGORY_CODE = '9000';
export const AREA_OF_USE_ALL_CATEGORIES_CODE = '*';

export enum SolrSearchSortingDefaults {
  PDP_TYPE_SKU_PICKER = 'score',
  PDP_TYPE_ARTICLES_LIST = 'a_name_asc',
}

export enum SolrSearchType {
  DEFAULT,
  SUGGEST,
  ARTICLE,
  PRODUCT,
  PDP,
}

export interface SolrSearchConfig {
  pageSize?: number;
  currentPage?: number;
  sort?: string;
  searchType: SolrSearchType;
  mya?: boolean;
}

export import SearchQuery = Occ.SearchQuery;

export import SearchState = Occ.SearchState;

export import FacetValue = Occ.FacetValue;

export import Breadcrumb = Occ.Breadcrumb;

export import Facet = Occ.Facet;

export import SpellingSuggestion = Occ.SpellingSuggestion;

export import Suggestion = Occ.Suggestion;

export interface SolrPagination {
  currentPage?: number;
  pageSize?: number;
  totalPages?: number;
  totalResults?: number;
  sort?: string;
}

export import SearchScoreFactor = Occ.SearchScoreFactor;

export interface SearchScoreExplanation extends Occ.SearchScoreExplanation {
  lineBoosts?: SearchScoreFactor[];
}

export interface SolrResultEntityRef extends Occ.SolrResultEntityRef {
  topScore?: boolean;
  explanation?: SearchScoreExplanation;
}

export interface SolrSearchResult {
  freeTextSearch?: string;
  categoryCode?: string;
  productRefs?: Array<string>;
  articleRefs?: Array<ArticleRef>;
  categoryRefs?: Array<string>;
  areaOfUseRefs?: Array<string>;
  sorts?: Array<SortModel>;
  pagination?: SolrPagination;
  spellingSuggestion?: SpellingSuggestion;
  currentQuery?: any;
  keywordRedirectUrl?: string;
  breadcrumbs?: Array<Breadcrumb>;
  facets?: Array<Facet>;
  searchConfig?: SolrSearchConfig;
  searchQuery?: string;
  totalArticleResults?: number;
  lastUpdateTime?: string;
  type?: string;
  articleResultRefs?: SolrResultEntityRef[];
  productResultRefs?: SolrResultEntityRef[];
}

export interface SearchTermFacetMappingHint {
  tooltipExample?: string;
  exampleValue?: string;
  hideIfMatchingRegEx?: string;
  hintFormat?: string;
  indexedProperty?: string;
  matchingRegEx?: string;
}
