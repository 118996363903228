import { ChangeDetectorRef, Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EventService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { ArticleService } from '../../../core/catalog';
import {
  Article,
  ArticleCarouselActionMode,
  ArticlePrice,
  CartType,
  ColumnAttribute,
  DebugKey,
  HorizontalAlignment,
  PrePrintedSpecial,
  SolrResultEntityRef,
  SubstituteType,
  Unit,
} from '../../../core/model';
import {
  ArticleInfoAttributesFacade,
  DebugFacade,
  OrderCardsFacade,
  PriceFacade,
  PrincipalConfigurationService,
} from '../../../core/user';
import { WindowRef } from '../../../core/window';
import { ActiveCartFacade, AddCartEntryFailEvent, AddCartEntrySuccessEvent } from '../../../features/cart/base';
import { ArticleListItemsVisibilityService } from '../../services/article-list-items-visibility/article-list-items-visibility.service';
import { resolveDefaultArticleQuantity } from '../../utils/resolve-default-article-quantity';
import { resolveDefaultArticleUnit } from '../../utils/resolve-default-article-unit';

@Directive()
export abstract class CatalogArticleDirective implements OnInit, OnDestroy {
  readonly stockCartType: CartType = CartType.stock;
  readonly carouselActionModes: typeof ArticleCarouselActionMode = ArticleCarouselActionMode;

  quantityAndUnitValue$ = new BehaviorSubject<{ quantity: number; unitCode: string }>({
    quantity: undefined,
    unitCode: undefined,
  });
  isAddToCartInProgress$ = new BehaviorSubject<boolean>(false);
  emitLoadPrice$ = new Subject<void>();
  articleOutOfStock: boolean;
  isSimilarArticlesExpanded: boolean = false;
  belowMinimum$ = new BehaviorSubject<boolean>(false);
  outOfStockOnWebshop$ = new BehaviorSubject<boolean>(false);

  articleNumber: string;

  article$: Observable<Article>;
  articleLoading$: Observable<boolean>;
  articleFailure$: Observable<boolean>;
  showArticleError$: Observable<boolean>;
  articlePrice$: Observable<ArticlePrice>;
  articlePriceLoading$: Observable<boolean>;
  disableActionButtons$: Observable<boolean>;
  renderFully$: Observable<boolean>;
  columnAttributes$: Observable<ColumnAttribute[]>;
  useArticleUnitAsDefault$: Observable<boolean>;
  hasSearchScore$: Observable<boolean>;
  showPrePrintedLabelDropdown$: Observable<boolean>;

  @Input() articleResultRef: SolrResultEntityRef;
  @Input() substituteBadgeType: SubstituteType;
  @Input() partialRenderingEnabled = false;
  @Input() queryParams: {
    [k: string]: any;
  };
  @Input() additionalArticleActions?: TemplateRef<any>;
  @Input() hideAddToShoppingListButton = false;
  @Input() showConsignmentStockLevel = false;
  @Input() cartType?: CartType;
  @Input()
  @HostBinding('class.secondary-article-row-variant')
  enableSecondaryArticleRowVariant = false;
  @Input() showAttributesInSeparateColumn = false;
  @Input() useArticleCartTypeInsteadOfGivenToAddToCart = false;
  @Input() expandedByDefault? = false;
  @Input() freeTextSearch?: string;

  protected subscription = new Subscription();

  horizontalAlignments = HorizontalAlignment;
  isMaxExceeded: boolean = false;

  prePrintedCountryForm: FormGroup = new FormGroup({
    configuration: new FormControl(''),
  });

  get prePrintedConfigurationControl(): FormControl {
    return this.prePrintedCountryForm.get('configuration') as FormControl;
  }

  get shoppingListArticleNumber(): string {
    if (this.articleNumber === PrePrintedSpecial.VOLVO) {
      return this.prePrintedConfigurationControl?.value || this.articleNumber;
    }
    return this.articleNumber;
  }

  constructor(
    private activeCartService: ActiveCartFacade,
    private priceService: PriceFacade,
    public elementRef: ElementRef,
    private cd: ChangeDetectorRef,
    private orderCardsService: OrderCardsFacade,
    private articleService: ArticleService,
    private windowRef: WindowRef,
    private articleListItemsVisibilityService: ArticleListItemsVisibilityService,
    private articleInfoAttributesService: ArticleInfoAttributesFacade,
    private principalConfigurationService: PrincipalConfigurationService,
    private eventService: EventService,
    private debugService: DebugFacade,
    private launchDialogService: LaunchDialogService
  ) {}

  updateQuantityAndUnit(values: { quantity: number; unitCode: string }): void {
    this.quantityAndUnitValue$.next(values);
  }

  ngOnInit(): void {
    this.hasSearchScore$ = this.debugService.isDebugFeatureEnabled(DebugKey.SEARCH_SCORE);

    this.renderFully$ =
      this.partialRenderingEnabled && this.windowRef.isBrowser()
        ? this.articleListItemsVisibilityService.fullyRenderableArticleListItemsMap$.pipe(
            map((fullyRenderableArticleListItemsMap) => fullyRenderableArticleListItemsMap.get(this.articleResultRef?.ref)),
            takeWhile((renderFully) => renderFully !== true, true),
            distinctUntilChanged()
          )
        : of(true);

    this.article$ = this.articleService.getArticle(this.articleResultRef?.ref).pipe(
      tap((article) => {
        if (article?.articlePrePrintedConfiguration?.length) {
          this.articleNumber =
            article?.articlePrePrintedConfiguration?.find((config) => config === this.freeTextSearch) || article?.articleNumber;
        } else {
          this.articleNumber = article?.articleNumber;
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
    this.articleLoading$ = this.articleService.getArticleLoading(this.articleResultRef?.ref);
    this.articleFailure$ = this.articleService.getArticleFailure(this.articleResultRef?.ref).pipe(
      map((failure) => !!failure),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.showPrePrintedLabelDropdown$ = this.article$.pipe(
      map((article) => article?.articlePrePrintedConfigurationWithCountry && this.articleNumber === PrePrintedSpecial.VOLVO),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    const substitutes$ = this.article$.pipe(
      filter((article) => this.articleService.isArticleDiscontinued(article)),
      switchMap((article) => this.articleService.getSubstitutesArticles(article.code)),
      startWith([])
    );

    this.showArticleError$ = combineLatest([this.article$, this.articleFailure$, substitutes$]).pipe(
      tap(([article, articleFailure, substitutes]) => {
        if ((!article && Boolean(!!articleFailure)) || substitutes?.length) {
          this.isSimilarArticlesExpanded = true;
        }
      }),
      map(([article, articleFailure]) => Boolean(!!articleFailure || article?.localDeletion || article?.invisible)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.useArticleUnitAsDefault$ = this.principalConfigurationService
      .isEnabled('useArticleUnitAsDefault')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.subscription.add(
      combineLatest([this.article$, this.showArticleError$, this.useArticleUnitAsDefault$])
        .pipe(
          filter(([article, showArticleError]) => !!article && !showArticleError),
          take(1)
        )
        .subscribe(([article, _showArticleError, useArticleUnitAsDefault]) => {
          const unit = resolveDefaultArticleUnit(article, useArticleUnitAsDefault);
          const quantity = resolveDefaultArticleQuantity(article, unit, useArticleUnitAsDefault);
          this.updateQuantityAndUnit({ quantity, unitCode: unit?.code });
        })
    );

    this.articlePriceLoading$ = combineLatest([this.article$, this.showArticleError$, this.quantityAndUnitValue$]).pipe(
      filter(([article, showArticleError, _emitLoadPrice]) => !!article && !showArticleError),
      switchMap(([article, _showArticleError, quantityAndUnitValue]) => {
        return this.priceService.loadingPrice(
          article?.code,
          quantityAndUnitValue.quantity,
          this.resolveActiveUnit(article, quantityAndUnitValue.unitCode)
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.articlePrice$ = combineLatest([
      this.article$,
      this.showArticleError$,
      this.useArticleUnitAsDefault$,
      merge(this.emitLoadPrice$.pipe(mapTo(true)), this.quantityAndUnitValue$.pipe(mapTo(false))),
    ]).pipe(
      filter(([article, showArticleError, _useArticleUnitAsDefault, _emitLoadPrice]) => !!article && !showArticleError),
      switchMap(([article, _showArticleError, useArticleUnitAsDefault, emitLoadPrice]) => {
        const value = this.quantityAndUnitValue$.value;

        let async = false;
        if (!emitLoadPrice) {
          const defaultUnit = resolveDefaultArticleUnit(article, useArticleUnitAsDefault);
          async = resolveDefaultArticleQuantity(article, defaultUnit, useArticleUnitAsDefault) === value.quantity;
        }

        return this.priceService.getArticlePrice(
          article?.code,
          value.quantity,
          this.resolveActiveUnit(article, value.unitCode),
          async,
          emitLoadPrice
        );
      })
    );

    this.disableActionButtons$ = combineLatest([
      this.article$,
      this.orderCardsService.isOrderCardsUserEnabled(),
      this.quantityAndUnitValue$,
      this.showArticleError$,
      this.belowMinimum$,
      this.outOfStockOnWebshop$,
    ]).pipe(
      map(
        ([article, isOrderCardsUserEnabled, quantityAndUnitValue, showArticleError, belowMinimum, outOfStockOnWebshop]) =>
          this.articleService.isArticleDiscontinued(article) ||
          article?.salesBlocked ||
          isOrderCardsUserEnabled ||
          !quantityAndUnitValue ||
          quantityAndUnitValue?.quantity === 0 ||
          showArticleError ||
          belowMinimum ||
          outOfStockOnWebshop
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.columnAttributes$ = this.article$.pipe(
      filter(Boolean),
      switchMap((article) =>
        this.articleInfoAttributesService.getColumnAttributes(article, this.enableSecondaryArticleRowVariant)
      )
    );

    this.subscription.add(
      merge(
        this.eventService.get(AddCartEntrySuccessEvent).pipe(map((_) => false)),
        this.eventService.get(AddCartEntryFailEvent).pipe(map((_) => false))
      )
        .pipe(shareReplay({ refCount: true, bufferSize: 1 }))
        .subscribe((_) => {
          this.isAddToCartInProgress$.next(false);
        })
    );

    this.subscription.add(
      this.article$
        .pipe(
          filter((article) => !!article),
          take(1),
          switchMap((article: Article) => this.activeCartService.isMaxOrderLinesExceeded(this.evaluateCartType(article.cartType)))
        )
        .subscribe((isMaxExceeded) => {
          if (isMaxExceeded) {
            this.isAddToCartInProgress$.next(false);
          }
          this.isMaxExceeded = isMaxExceeded;
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onBelowMinimum(isBelowMinimum: boolean): void {
    this.belowMinimum$.next(isBelowMinimum);
  }

  addToCart() {
    this.subscription.add(
      this.article$
        .pipe(
          filter((article) => !!article),
          take(1)
        )
        .subscribe((article) => {
          const quantityAndUnitValue = this.quantityAndUnitValue$.value;
          if (!this.isMaxExceeded) {
            this.isAddToCartInProgress$.next(true);
          }

          let customerMaterialNumber = this.articleNumber;
          if (this.articleNumber === PrePrintedSpecial.VOLVO) {
            customerMaterialNumber = this.prePrintedConfigurationControl?.value;
          }

          this.activeCartService.addEntry(
            article.code,
            quantityAndUnitValue?.quantity,
            this.resolveActiveUnit(article, quantityAndUnitValue?.unitCode),
            this.evaluateCartType(article.cartType),
            undefined,
            customerMaterialNumber
          );
        })
    );
  }

  evaluateCartType(articleCartType: CartType): CartType {
    return this.useArticleCartTypeInsteadOfGivenToAddToCart ? articleCartType : this.cartType || articleCartType;
  }

  loadPrice() {
    this.emitLoadPrice$.next();
  }

  resolveActiveUnit(article: Article, unitCode: string): Unit {
    return article.units?.find((u) => u.code === unitCode);
  }

  setArticleOutOfStock(event: { status: boolean; outOfStockOnWebshop: boolean }): void {
    this.articleOutOfStock = event.status;
    this.isSimilarArticlesExpanded = event.outOfStockOnWebshop;
    this.outOfStockOnWebshop$.next(event.outOfStockOnWebshop);
    this.cd.detectChanges();
  }

  openSearchScoreModal(): void {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.SEARCH_SCORE, undefined, this.articleResultRef);
  }
}
