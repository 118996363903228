<div class="d-flex justify-content-center py-2" *ngIf="articleResultRefs?.length || products$?.length">
  <div class="row col-12 col-xl-8 results-wrapper">
    <div class="col-12 px-0 px-md-3 pb-3">
      <ng-container *pyPrincipalConfiguration="'enableDisplaySearchHint'">
        <ng-container *ngIf="searchFormValue | searchHints : searchHints as hints">
          <div class="d-flex justify-content-start w-100 pt-4 pb-2" *ngIf="hints.length">
            <div class="col-12 col-xl-8 px-0 w-100 d-flex align-items-center results-wrapper">
              <py-search-hints [form]="form" [hints]="hints"></py-search-hints>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="d-flex flex-column flex-md-row border-bottom">
        <button (click)="onSubmit()" class="btn-link py-3 align-self-start">
          {{ 'catalog.viewAllResults_action' | cxTranslate }}
          <i nz-icon nzType="arrow-right" class="ml-2"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="(articleResultRefs || []).length">
  <div class="d-flex justify-content-center w-100">
    <div class="col-12 col-xl-8 px-3 px-md-6 mb-12 mb-md-0 results-wrapper">
      <py-catalog-article-list
        [showHeader]="false"
        [articleResultRefs]="articleResultRefs || []"
        [queryParams]="catalogItemQueryParam"
        [freeTextSearch]="searchFormValue"
      ></py-catalog-article-list>
    </div>
  </div>
</ng-container>

<div class="d-flex justify-content-center">
  <div *ngIf="(products$ || []).length" class="row col-12 col-xl-8 results-wrapper">
    <div class="col-12 col-md-4 categories px-0 px-md-3 pb-1 pb-xl-0">
      <ul class="mb-4">
        <li *ngFor="let category of categories" class="py-3 pl-3 mb-1">
          <cx-generic-link [url]="getUrl(category, catalogItemQueryParam)">
            {{ category.name }}
          </cx-generic-link>
        </li>
      </ul>
    </div>

    <div class="catalog-list-item-wrapper col-12 col-md-8 px-0 px-md-3 pt-4 pt-md-0 mb-16 mb-md-0">
      <py-product-list-item-loader
        [products]="products$"
        [productResultRefs]="searchResults?.productResultRefs || []"
        [queryParams]="catalogItemQueryParam"
      ></py-product-list-item-loader>
    </div>
  </div>
</div>
