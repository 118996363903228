import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Placement } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '@spartacus/core';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Pagination } from '../../../core/model';
import { UserDropdownEvent } from '../../../core/user';

export interface Option {
  value: string;
  //The label is shown in the dropdown
  label: string;
  //The label is shown as the active item, IF NOT the labelActive key is set
  labelActive?: string;
  description?: string;
  disabled?: boolean;
  additionalLabels?: string[];
  email?: string;
}

@Component({
  selector: 'py-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  @Input() options: Option[];
  @Input() title?: string;
  @Input() titleIcon?: string;
  @Input() id?: string;
  @Input() heading?: string;
  @Input() placeholder?: string;
  @Input() icon?: string;
  @Input() showActiveItem = true;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() resetable = false;
  @Input() resetLabel?: string;
  @Input() hasConstantVisibleLabel = false;
  @Input() multi = false;
  @Input() badge?: string;
  @Input() badgeTooltip?: string;
  @Input() unselectableOption: { key: string; value: string };
  @Input() showCountBadge = false;
  @Input() placement?: Array<Placement> | Placement | string = ['bottom-left', 'top-left', 'bottom-right', 'top-right'];
  @Input() loading?: boolean;
  @Input() additionalColumns = 0;
  @Input() searchable = false;
  @Input() loadMore: { pagination: Pagination; page: number; loading?: boolean; totalItemCountName?: boolean };
  @Input() checkForInvisibleActiveItems = false;
  @Input() allOption = false;
  // When a title is provided like "All", showTitleForMobile should be set to false.
  // In these cases it does not make sense to show the title if there is a active item set in mobile view.
  @Input() showTitleForMobile = true;
  @Input() showLastActiveItemAsSelected = false;
  @Input() dropdownContainer: string = null;
  @Input() debounceTime = 800;

  @Output() resetValue = new EventEmitter<any>();
  @Output() search = new EventEmitter<string>();
  @Output() scrolled = new EventEmitter<string>();
  @Output() nextPage = new EventEmitter<void>();
  @Output() dropdownClose = new EventEmitter<void>();
  @Output() dropdownOpen = new EventEmitter<void>();

  isCollapsed = true;
  selectedOptionsMapInitialized = false;
  hasInvisibleActiveItems = false;
  container: string;

  form = new UntypedFormGroup({
    searchInput: new UntypedFormControl(''),
  });

  private value?: string | string[];
  private onChange?: (value: string | string[]) => void;
  private subscriptions = new Subscription();
  private selectedOptionsMap: Map<string, Option> = new Map();

  private get searchInputFormControl(): UntypedFormControl {
    return this.form.get('searchInput') as UntypedFormControl;
  }

  constructor(private breakpointService: BreakpointService, private cd: ChangeDetectorRef, private eventService: EventService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !this.selectedOptionsMapInitialized &&
      this.multi &&
      changes.options?.currentValue?.length &&
      this.value &&
      Array.isArray(this.value)
    ) {
      // Initializes the selectedOptionsMap if the dropdown is multi-select and there are some values preselected initially
      this.initializeSelectedOptionsMap();
    }
  }

  /**
   * Updates map that stores previously selected options.
   * When dropdown is searchable using the text field, some options can be filtered out and not displayed.
   * If because of that the already selected option is not displayed, we cannot retrieve its labels from the options list.
   * We however always need to display the selected option label in dropdown button. This map is used to retrieve this label.
   * @param option
   */
  private updateSelectedOptionsMap(option: Option) {
    this.selectedOptionsMap.set(option.value, option);
  }

  private initializeSelectedOptionsMap(): void {
    const selectedOptions = this.options.filter((option) => this.value.includes(option.value));
    selectedOptions.forEach((option) => {
      this.updateSelectedOptionsMap(option);
    });

    this.selectedOptionsMapInitialized = true;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.breakpointService
        .isDown(BREAKPOINT.md)
        .pipe(map((isMobile) => (isMobile ? null : this.dropdownContainer)))
        .subscribe((container) => (this.container = container))
    );

    this.subscriptions.add(
      this.searchInputFormControl.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe((value: string) => {
        this.search.emit(value);
      })
    );
  }

  writeValue(value: string | string[]): void {
    this.value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(_fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get activeItem(): Option | undefined {
    const options = this.showLastActiveItemAsSelected ? [...this.options].reverse() : this.options;

    const option = options?.find((i) => this.isActive(i.value));

    const activeVisibleItem =
      !option && this.searchable ? Array.from(this.selectedOptionsMap?.values()).find((i) => this.isActive(i.value)) : option;

    if (this.searchable) {
      if (activeVisibleItem && !this.selectedOptionsMap?.size) {
        this.updateSelectedOptionsMap(activeVisibleItem);
      }
    }

    // Invisible active items are the dropdown items that are selected, but options for such items are not visible in the dropdown,
    // e.g. if these options are located in pagination pages that are not yet loaded
    this.hasInvisibleActiveItems =
      this.checkForInvisibleActiveItems && this.value && !activeVisibleItem ? Boolean(this.valueLength) : false;

    return activeVisibleItem;
  }

  get valueLength(): number {
    return Array.isArray(this.value) ? this.value.length : Number(!!this.value);
  }

  isActive(value): boolean {
    if (this.multi) {
      return this.value?.includes(value);
    }
    return this.value === value;
  }

  updateValue(selectedOption: Option) {
    this.value = selectedOption.value;

    if (this.onChange) {
      this.onChange(selectedOption.value);
    }

    if (this.searchable) {
      this.updateSelectedOptionsMap(selectedOption);
    }

    this.eventService.dispatch(new UserDropdownEvent([selectedOption.value]));
  }

  updateMultiValue(selectedOption: Option) {
    if (this.isActive(selectedOption.value)) {
      this.value = [...this.value].filter((v) => v !== selectedOption.value);
    } else {
      this.value = [...(this.value || []), selectedOption.value];
    }

    if (this.searchable) {
      this.updateSelectedOptionsMap(selectedOption);
    }

    if (this.onChange) {
      this.onChange(this.value);
    }

    this.eventService.dispatch(new UserDropdownEvent(this.value));
  }

  toggleAll() {
    if (this.value.length === this.options.length) {
      this.value = [];
    } else {
      this.value = this.options.map((o) => o.value);
    }

    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  onOpenChange(isOpen: boolean): void {
    this.isCollapsed = !this.isCollapsed;

    if (isOpen === false) {
      this.dropdownClose.emit();
    } else {
      this.dropdownOpen.emit();
    }
  }

  onScrolled(): void {
    this.scrolled.emit(this.searchInputFormControl.value);
  }

  onNextPage(): void {
    this.nextPage.emit();
  }

  onResetSearchInput(): void {
    this.searchInputFormControl.reset();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
