<div
  class="d-flex flex-column"
  [ngClass]="{
    'align-items-start': horizontalAlignment === horizontalAlignments.LEFT,
    'align-items-end': horizontalAlignment === horizontalAlignments.RIGHT
  }"
>
  <ng-container *pyPrincipalConfiguration="'enableDisplayOutgoingArea,enableDisplayOutletBadgeForLoggedOut'">
    <py-badge *ngIf="showOutgoingBadge" class="ml-1" [type]="badgeType.Outlet" [tag]="true">
      {{ 'article.outlet_badge' | cxTranslate }}
    </py-badge>
  </ng-container>

  <ng-container *ngIf="enableDisplayOfPrices$ | async; else noDisplayOfPrices">
    <div class="d-flex flex-row align-items-end mt-1">
      <py-article-price
        [articlePrice]="articlePrice"
        [showArticlePriceInsteadOfEntryPrice]="true"
        [horizontalAlignment]="horizontalAlignment"
        [priceCampaign]="article?.priceCampaign"
        [showOutgoingBadge]="false"
        [textInsteadOfPrice]="textInsteadOfPrice"
        [loading]="loadingPrice"
        (loadPrice)="loadPrice.emit()"
      ></py-article-price>
      <div *ngIf="showLoginToSeeYourPricesTooltip$ | async; else priceNotAvailableTemplate" class="catalog-article-price">
        <i
          nz-icon
          nzType="exclamation-circle"
          nzTheme="outline"
          class="ml-2 d-flex login-tooltip"
          container="body"
          [ngbTooltip]="'catalog.anonymousLoginToSeeYourPrice_tooltip' | cxTranslate"
        ></i>
      </div>
    </div>
  </ng-container>

  <span class="catalog-article-list-item-quantity">
    {{ 'catalog.quantity_heading' | cxTranslate : { quantity: inEcommerceQuantity | cxNumeric : '1.0-0' } }}
    {{ article?.unit | unit : inEcommerceQuantity }}
  </span>
</div>

<ng-template #noDisplayOfPrices>
  <py-badge *ngIf="!(isUserLoggedIn$ | async) && article?.priceCampaign" class="ml-1" [tag]="true" [type]="badgeType.Campaign">
    {{ 'common.campaign_badge' | cxTranslate }}
  </py-badge>
  <ng-container *ngTemplateOutlet="priceNotAvailableTemplate"></ng-container>
</ng-template>

<ng-template #priceNotAvailableTemplate>
  <span class="catalog-article-price">
    <span *ngIf="!(enableDisplayOfPrices$ | async)">{{ 'catalog.priceNotAvailable_hint' | cxTranslate }}</span>
    <i
      *ngIf="!(isUserLoggedIn$ | async)"
      nz-icon
      nzType="question-circle"
      nzTheme="outline"
      class="ml-1"
      container="body"
      [ngbTooltip]="'catalog.priceNotAvailable_tooltip' | cxTranslate"
    ></i>
  </span>
</ng-template>
