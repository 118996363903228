import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  B2B_USER_NORMALIZER,
  B2B_USER_SEARCH_NORMALIZER,
  B2BUserAdapter,
  ORG_CUSTOMER_CREATION_SERIALIZER,
  ORG_CUSTOMER_MODIFICATION_SERIALIZER,
  ORG_CUSTOMER_ROLE_SERIALIZER,
  ORG_CUSTOMER_SHIP_TO_ASSIGNMENT_SERIALIZER,
  ORG_CUSTOMER_SOLDTOS_SERIALIZER,
} from '../../../../features/organization-base/connectors';
import {
  B2BUser,
  B2BUserSearchParams,
  CustomerStatus,
  CustomerStatusList,
  OrgCustomerCreation,
  OrgCustomerModification,
  OrgCustomerShipToAssignment,
  OrgUnitUserPage,
  UpdateStatusValue,
  UserRoleList,
} from '../../../model';
import { ConverterService } from '../../../util';

@Injectable()
export class OccB2BUserAdapter implements B2BUserAdapter {
  constructor(protected http: HttpClient, protected occEndpoints: OccEndpointsService, protected converter: ConverterService) {}

  load(userId: string, orgUnitCustomerId: string): Observable<B2BUser> {
    return this.http
      .get<any>(this.getB2BUserEndpoint(userId, orgUnitCustomerId))
      .pipe(this.converter.pipeable(B2B_USER_NORMALIZER));
  }

  getB2BUsers(userId: string, params?: B2BUserSearchParams): Observable<OrgUnitUserPage> {
    return this.http.get<any>(this.getB2BUsersEndpoint(userId, params)).pipe(this.converter.pipeable(B2B_USER_SEARCH_NORMALIZER));
  }

  create(userId: string, orgCustomerCreation: OrgCustomerCreation): Observable<B2BUser> {
    orgCustomerCreation = this.converter.convert(orgCustomerCreation, ORG_CUSTOMER_CREATION_SERIALIZER);
    return this.http
      .post<any>(this.getB2BUsersEndpoint(userId), orgCustomerCreation)
      .pipe(this.converter.pipeable(B2B_USER_NORMALIZER));
  }

  update(userId: string, orgCustomerId: string, orgCustomerModification: OrgCustomerModification): Observable<B2BUser> {
    orgCustomerModification = this.converter.convert(orgCustomerModification, ORG_CUSTOMER_MODIFICATION_SERIALIZER);

    return this.http
      .patch(this.getB2BUserProfileEndpoint(userId, orgCustomerId), orgCustomerModification)
      .pipe(this.converter.pipeable(B2B_USER_NORMALIZER));
  }

  updateRole(userId: string, orgCustomerId: string, roleId: string): Observable<B2BUser> {
    roleId = this.converter.convert(roleId, ORG_CUSTOMER_ROLE_SERIALIZER);

    return this.http
      .patch(this.getB2BUserRoleEndpoint(userId, orgCustomerId, roleId), null)
      .pipe(this.converter.pipeable(B2B_USER_NORMALIZER));
  }

  updateSoldTos(userId: string, orgCustomerId: string, soldToIds: string[]): Observable<B2BUser> {
    soldToIds = this.converter.convert(soldToIds, ORG_CUSTOMER_SOLDTOS_SERIALIZER);

    return this.http
      .patch(this.getB2BUserSoldTosEndpoint(userId, orgCustomerId), soldToIds)
      .pipe(this.converter.pipeable(B2B_USER_NORMALIZER));
  }

  updateShipToAssignment(
    userId: string,
    orgCustomerId: string,
    orgCustomerShipToAssignment: OrgCustomerShipToAssignment
  ): Observable<B2BUser> {
    orgCustomerShipToAssignment = this.converter.convert(orgCustomerShipToAssignment, ORG_CUSTOMER_SHIP_TO_ASSIGNMENT_SERIALIZER);

    return this.http
      .patch(this.getB2BUserShipTosAssignmentEndpoint(userId, orgCustomerId), orgCustomerShipToAssignment)
      .pipe(this.converter.pipeable(B2B_USER_NORMALIZER));
  }

  loadB2BUserStatuses(): Observable<CustomerStatusList> {
    return this.http.get<any>(this.occEndpoints.buildUrl('b2bUserStatuses'));
  }

  loadUserRoles(userId: string, configurations?: string, configurationsConjunction?: boolean): Observable<UserRoleList> {
    return this.http.get<any>(
      this.occEndpoints.buildUrl('userRoles', {
        urlParams: { userId },
        queryParams: {
          configurations: configurations || undefined,
          configurationsConjunction: configurationsConjunction || undefined,
        },
      })
    );
  }

  updateStatus(userId: string, orgCustomerIds: string[], status: UpdateStatusValue): Observable<CustomerStatus> {
    return this.http.patch<any>(
      this.occEndpoints.buildUrl('status', {
        urlParams: { userId },
        queryParams: { status },
      }),
      orgCustomerIds
    );
  }

  deleteB2BUsers(userId: string, orgCustomerIds: string[]): Observable<any> {
    return this.http.request(
      'delete',
      this.occEndpoints.buildUrl('b2bUsers', {
        urlParams: { userId },
      }),
      { body: orgCustomerIds }
    );
  }

  protected getB2BUserShipTosAssignmentEndpoint(userId: string, orgCustomerId: string): string {
    return this.occEndpoints.buildUrl('b2bUserShipTosAssignment', {
      urlParams: {
        userId,
        orgCustomerId,
      },
    });
  }

  protected getB2BUserEndpoint(userId: string, orgCustomerId: string): string {
    return this.occEndpoints.buildUrl('b2bUser', {
      urlParams: {
        userId,
        orgCustomerId,
      },
    });
  }

  protected getB2BUserProfileEndpoint(userId: string, orgCustomerId: string): string {
    return this.occEndpoints.buildUrl('b2bUserProfile', {
      urlParams: {
        userId,
        orgCustomerId,
      },
    });
  }

  protected getB2BUserRoleEndpoint(userId: string, orgCustomerId: string, roleId: string): string {
    return this.occEndpoints.buildUrl('b2bUserRole', {
      urlParams: {
        userId,
        orgCustomerId,
      },
      queryParams: {
        role: roleId,
      },
    });
  }

  protected getB2BUserSoldTosEndpoint(userId: string, orgCustomerId: string): string {
    return this.occEndpoints.buildUrl('b2bUserSoldTos', {
      urlParams: {
        userId,
        orgCustomerId,
      },
    });
  }

  protected getB2BUsersEndpoint(userId: string, params?: B2BUserSearchParams): string {
    return this.occEndpoints.buildUrl('b2bUsers', {
      urlParams: { userId },
      queryParams: {
        page: params?.page,
        count: params?.count,
        sort: params?.sort,
        search: params?.searchText,
        roles: params?.configurationRoles?.length
          ? params?.configurationRoles
          : params?.roles?.length
          ? params?.roles
          : undefined,
        statuses: params?.statuses?.length ? params.statuses : undefined,
        soldTos: params?.soldTos?.length ? params.soldTos : undefined,
        orderCard: params?.orderCard || undefined,
        b2bPermission: params?.b2bPermission || undefined,
        shoppingListCode: params?.shoppingListCode || undefined,
        excludedCustomerIds: params?.excludedCustomerIds || undefined,
        configurationRoles: undefined,
      },
    });
  }
}
