import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideDefaultConfig } from '@spartacus/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { I18nModule } from '../../../core/i18n';
import { ActionButtonModule } from '../action-button';
import { ModalModule } from '../modal/modal.module';
import { reloadModalBodyLayoutConfig } from './reload-modal-body-layout.config';
import { ReloadModalBodyComponent } from './reload-modal-body.component';
import { ReloadModalComponent } from './reload-modal.component';

@NgModule({
  declarations: [ReloadModalComponent, ReloadModalBodyComponent],
  imports: [CommonModule, ModalModule, ActionButtonModule, I18nModule, KeyboardFocusModule],
  providers: [provideDefaultConfig(reloadModalBodyLayoutConfig)],
  exports: [ReloadModalComponent],
})
export class ReloadModalModule {}
