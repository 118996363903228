<!-- Customer Assortment Desktop -->
<div
  *ngIf="enableTogglingOfCustomerAssortment"
  class="pl-3 pt-3 py-md-2 d-flex align-items-start justify-content-start justify-content-md-end flex-grow-1 contracted-articles-toggle"
>
  <label [htmlFor]="'showOnlyStarred'">
    {{ 'article.onlyContractedArticles_heading' | cxTranslate }}
    <i nz-icon nzType="contracted-article" class="ml-1"></i>
  </label>

  <py-reactive-form-checkbox-slider [group]="form" [fieldName]="'showOnlyStarred'"></py-reactive-form-checkbox-slider>
</div>

<!-- Discontinued Article Header -->
<ng-container *ngIf="showDiscontinuedArticleSection">
  <div *ngIf="showDiscontinuedArticleSection" class="d-flex justify-content-center w-100">
    <div class="col-12 col-xl-10 px-3 px-md-6">
      <py-discontinued-article-header
        [discontinuedArticle]="discontinuedArticle"
        [discontinuedArticleViewType]="discontinuedArticleViewType"
        [discontinuedArticleSubstituteRefs]="substituteRefs"
        [enableTogglingOfCustomerAssortment]="enableTogglingOfCustomerAssortment"
        [form]="form"
        (submitEvent)="onDiscontinuedArticleSubmit()"
      >
      </py-discontinued-article-header>
    </div>
  </div>

  <!-- Dicontinued Article - Substitutes (Articles or Products) -->
  <div class="d-flex justify-content-center">
    <div class="col-12 col-xl-10 px-3 px-md-6 py-2 mb-12 mb-md-0">
      <py-catalog-article-list
        *ngIf="discontinuedArticleViewType === discontinuedArticleViewTypes.Article"
        [showHeader]="false"
        [showDiscontinuedArticleHeader]="true"
        [articleRefs]="getArticlesCodesFromSubstitutes(substituteRefs)"
        [substituteRefs]="substituteRefs"
        [queryParams]="catalogItemQueryParam"
        [freeTextSearch]="searchFormValue"
      ></py-catalog-article-list>

      <py-product-list-item-loader
        *ngIf="discontinuedArticleViewType === discontinuedArticleViewTypes.Product"
        [products]="getProductsFromSubstitutes(substituteRefs)"
        [substituteRefs]="substituteRefs"
        [queryParams]="catalogItemQueryParam"
        [isSecondaryVersion]="searchResultsSecondaryVariant"
        class="search-results"
      ></py-product-list-item-loader>
    </div>
  </div>
</ng-container>

<!-- No results -->
<py-no-results
  *ngIf="showNoResultsSection"
  class="py-11 px-3"
  [description]="'catalog.search.noResultsFound_text' | cxTranslate"
  [iconType]="'search'"
></py-no-results>

<!-- Active and empty overlay -->
<py-no-results
  *ngIf="searchFormValue?.length < minSearchLength"
  class="py-11 px-3"
  [title]="'catalog.search.startSearching_hint'"
  [description]="'catalog.search.startSearching_text' | cxTranslate"
  [iconType]="'search'"
></py-no-results>

<py-search-results-content-primary
  *ngIf="showSearchResultsContentPrimarySection"
  [articleResultRefs]="articleResultRefs"
  [products$]="products$"
  [enableTogglingOfCustomerAssortment]="enableTogglingOfCustomerAssortment"
  [form]="form"
  [catalogItemQueryParam]="catalogItemQueryParam"
  [categories]="categories"
  [searchFormValue]="searchFormValue"
  [searchHints]="searchHints"
  (submitSearch)="onSubmit()"
></py-search-results-content-primary>

<!-- Loading for primary search results -->
<div *ngIf="showLoadingSectionForPrimarySearchResults" class="d-flex justify-content-center py-4">
  <div class="row justify-content-center col-12 col-xl-10 px-0 px-md-3 spinner-holder">
    <cx-spinner></cx-spinner>
  </div>
</div>

<py-search-results-content-secondary
  *ngIf="searchResultsSecondaryVariant"
  [isLoading]="isSearchResultsContentSecondaryLoading"
  [showResults]="showResultsForSearchResultsContentSecondary"
  [searchResults]="searchResults"
  [articleResultRefs]="articleResultRefs"
  [products$]="products$"
  [enableTogglingOfCustomerAssortment]="enableTogglingOfCustomerAssortment"
  [form]="form"
  [catalogItemQueryParam]="catalogItemQueryParam"
  [categories]="categories"
  [searchFormValue]="searchFormValue"
  [activeTab]="activeTab"
  [initialActiveTab]="initialActiveTab"
  [searchHints]="searchHints"
  (selectTab)="selectTab.emit($event)"
  (submitSearch)="onSubmit()"
></py-search-results-content-secondary>
